import { startTransition, useState } from 'react';

import Router from 'next/router';

import { LOGIN_BEFORE_PATHS } from '@constants/index'
import { getLoginPath } from '@utils/index'

import jwtToken, { readonlyToken } from '../../utils/jwtToken';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

export const getIsLoginState = (currentJwtToken) => {
  const userIsLogined = currentJwtToken ? currentJwtToken !== readonlyToken : false;
  return userIsLogined
}

export default function useAuthSession ({
  redirectTo = LOGIN_BEFORE_PATHS.LOGIN
} = {}) {
  const [userIsLogined, setUserIsLogined] = useState(null)
  const currentJwtToken = jwtToken.getToken();
  useIsomorphicLayoutEffect(() => {
    const userIsLogined = getIsLoginState(currentJwtToken)
    console.log('userIsLogined: ' + userIsLogined)
    startTransition(() => setUserIsLogined(userIsLogined))
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo) return;
    console.timeEnd('testForAuth');

    if ((redirectTo && !userIsLogined)) {
      const path = redirectTo === LOGIN_BEFORE_PATHS.LOGIN ? getLoginPath() : redirectTo
      Router.push(path);
    }
  }, [redirectTo, currentJwtToken]);
  return userIsLogined
}
