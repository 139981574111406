import PouchDB from 'pouchdb';

const db = new PouchDB('kiki_bulletin');

export const addData = async ({ id, title, body, createTime, isRead }) => {
  const res = await db.put({
    _id: id,
    title,
    body,
    createTime,
    isRead
  })
  return res
}

export const queryData = async () => {
  const resAll = await db.allDocs({ include_docs: true })
  return resAll;
}

const docsSortByTime = (rows) => {
  return rows.sort((a, b) => {
    if (Number(a.doc.createTime) > Number(b.doc.createTime)) {
      return 1
    }
    if (Number(a.doc.createTime) < Number(b.doc.createTime)) {
      return -1
    }
    return 0
  })
}

export const queryLastBulletin = async () => {
  const resAll = await db.allDocs({ include_docs: true });
  const rows = resAll.rows;
  if (!rows.length) {
    return
  }
  // return rows[rows.length - 1].doc
  if (rows.length === 1) {
    return rows[0].doc
  }
  const sortRows = docsSortByTime(rows);
  return sortRows[sortRows.length - 1].doc
}

export const updateBulletin = async (params) => {
  if (!params._rev) {
    return console.log('error', '_rev not found')
  }
  const res = await db.put({
    ...params
  })
  return res
}
