import React from 'react';
import { Collapse, Layout } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { NAVDATA } from './NavData';
import { RelaxUs } from '../RelaxUs'
import I18n from '@utils/i18n';
import Istyles from './index.module.css'
import themeStyle from '../../theme.module.css'
import cn from 'classnames'
const { Footer } = Layout

export const FooterH5 = ({ newFooterData, theme }) => {
  return (
      <div className={cn(Istyles.navLink, Istyles.h5NavLink, themeStyle[`${theme}-navLink`])} data-name='kiki-h5-footer'>
        <Collapse
          bordered={false}
          ghost={true}
          defaultActiveKey={['0']}
          // expandIconPosition='right'
          expandIcon={({ isActive }) => {
            return isActive ? <MinusOutlined /> : <PlusOutlined />
          }}
        >
          {NAVDATA(newFooterData)}
          {/* <NavData value={newFooterData} /> */}
        </Collapse>
        <RelaxUs theme={theme} />
        <div className={cn(Istyles.footerData, themeStyle[`${theme}-footerData`])}>
            <Footer className='DINPro'>{I18n.t('layout_footer')}</Footer>
        </div>
      </div>
  )
}
