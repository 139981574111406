import { useState } from 'react'
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect'
import { offlineSymbols } from '@api/index';

export const useOfflineSymbols = () => {
  const [offlineSymbolsGroupByCoinCode, setOfflineSymbolsGroupByCoinCode] = useState('')

  const fetchOfflineSymbols = async () => {
    try {
      const response = await offlineSymbols();
      if (response && response.success && response.obj) {
        const offlineSymbolsGroupByCoinCode = {}
        const offlineSymbols = response.obj;
        offlineSymbols.forEach(item => (
          offlineSymbolsGroupByCoinCode[item.coinCode] = item
        ));
        setOfflineSymbolsGroupByCoinCode(offlineSymbolsGroupByCoinCode)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const isOfflineSymbols = (coinCode) => {
    return !!offlineSymbolsGroupByCoinCode[coinCode]
  }

  useIsomorphicLayoutEffect(() => {
    fetchOfflineSymbols()
  }, [])

  return {
    offlineSymbolsGroupByCoinCode,
    isOfflineSymbols
  }
}
