const env = process.env.NEXT_PUBLIC_API_ENV

let GTMId = ''

switch (env) {
  case 'local':
  case 'dev':
  case 'beta1':
  case 'beta':
  case 'preview':
    GTMId = 'GTM-P8VRP55';
    break;
  default:
    GTMId = 'GTM-KTDMC2S';
    break;
}

export {
  GTMId
};
