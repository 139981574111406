import config from '../utils/config';
import helper from '../utils/helper';
import request from '../utils/request';
import { MarketDiscoverConfig, MarketTrends, PopularCurrencyType } from './market.type'

// class definition

class MarketApi {
  coinList (params?: { sort: string }) {
    const URL =
      config.api.host_api_v1 +
      config.api.market.coinList +
      '?' +
      helper.mapToQueryString(params ?? {});

    return request.get.call({}, URL);
  }

  marketSymbols () {
    // params.saas_id = 'kiki';
    const URL = config.api.host_api_v1 + config.api.market.marketSymbols;
    return request.get.call({}, URL);
  }

  hotSearch () {
    const URL = config.api.host_api_v1 + config.api.favoriteCustom.hotSearch;
    return request.get.call({}, URL);
  }

  fetchRecommend () {
    const URL =
      config.api.host_api_v1 + config.api.favoriteCustom.favoriteRecommend;
    return request.get.call({}, URL);
  }

  getDiscoverConfig (): Promise<APIResponse<MarketDiscoverConfig>> {
    const URL = config.api.host_api_v1 + config.api.market.explore;
    return request.get.call({}, URL);
  }

  getTrends (): Promise<APIResponse<MarketTrends>> {
    const URL = config.api.host_api_v1 + config.api.market.trends;
    return request.get.call({}, URL);
  }

  getPopularCurrencies (): Promise<APIResponse<PopularCurrencyType>> {
    const URL = config.api.host_api_v1 + config.api.market.popularCurrencies;
    return request.get.call({}, URL);
  }
}

const MarketAPI = new MarketApi();
export { MarketAPI };
