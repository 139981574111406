import { initializeApp } from 'firebase/app';
const env = process.env.NEXT_PUBLIC_API_ENV
console.log(process.env.NEXT_PUBLIC_API_ENV, 'firbase')
let config
switch (env) {
  case 'local':
  case 'dev':
    config = {
      apiKey: 'AIzaSyB9ATSPEaf5An7LjaRYf7v1oYPdjngKB8o',
      authDomain: 'kiki-message-dev.firebaseapp.com',
      databaseURL: 'https://kiki-message-dev.firebaseio.com',
      projectId: 'kiki-message-dev',
      storageBucket: 'kiki-message-dev.appspot.com',
      messagingSenderId: '311695539840',
      appId: '1:311695539840:web:23eccaa54a2d288f363065',
      measurementId: 'G-4QLJ9HJTHV'
    };

    break;
  case 'beta':
  case 'beta1':
  case 'beta2':
  case 'preview':
    config = {
      apiKey: 'AIzaSyAkNt4CWe2RANTzjS9NkM8q5vrPZXcrlkQ',
      authDomain: 'kiki-beta.firebaseapp.com',
      databaseURL: 'https://kiki-beta.firebaseio.com',
      projectId: 'kiki-beta',
      storageBucket: 'kiki-beta.appspot.com',
      messagingSenderId: '983830855482',
      appId: '1:983830855482:web:dbf16766eb95ee48f89a03',
      measurementId: 'G-X286KB4WVQ'
    };
    break;
  case 'prodgreen':
  case 'pre1':
  case 'pro':
  case 'pre':
  case 'prod':
  default:
    config = {
      apiKey: 'AIzaSyDCjzMo4G31pzqcazwfgiV4OQx6J7BXq20',
      authDomain: 'kiki-message-prod.firebaseapp.com',
      databaseURL: 'https://kiki-message-prod.firebaseio.com',
      projectId: 'kiki-message-prod',
      storageBucket: 'kiki-message-prod.appspot.com',
      messagingSenderId: '389380007773',
      appId: '1:389380007773:web:d779671807612af620eab4',
      measurementId: 'G-8LRHTQ88BC'
    };
    break;
}

const firebaseConfig = config

// Initialize Firebase
if (typeof window !== 'undefined') {
  const app = initializeApp(firebaseConfig);
  window.firebaseApp = app
}
