import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';
import { enableStaticRendering } from 'mobx-react';

import { SpotTradeAPI } from '@api/spotTrade';
import { getRate } from '@utils/getRate';
import usdtHkdRate from '@utils/market/usdtHkdRate';
import { usdcToUsd } from '@utils/usdcToUsd';

import { MarketAPI } from '../api/market';

// eslint-disable-next-line react-hooks/rules-of-hooks
enableStaticRendering(typeof window === 'undefined');

let store;

const extractFiatCoinDetailFromMarket = async (rateType, fiatMarketData) => {
  let otherRateSymbol = 'USDT_HKD';
  let coinDetail;
  let otherRateDetail;
  const constantRate = ['USDT_HKD']; // 固定汇率盘口
  if (rateType) {
    const quoteCurrency = rateType.split('_')[1];
    if (quoteCurrency) {
      if (quoteCurrency === 'HKD') {
        otherRateSymbol = 'USDT_USDC';
      }
      const rateValue = await usdtHkdRate();
      const USDT_HKD = { priceLast: rateValue, coinCode: 'USDT_HKD' };
      const isUSDTHKDRate = constantRate.includes(rateType);
      const isUSDTHKDOtherRate = constantRate.includes(otherRateSymbol);
      fiatMarketData.forEach((item) => {
        if ((item.coinCode === rateType || item.coinCode === usdcToUsd(rateType)) && !isUSDTHKDRate) {
          coinDetail = item;
        }
        if ((item.coinCode === otherRateSymbol || item.coinCode === usdcToUsd(otherRateSymbol)) && !isUSDTHKDOtherRate) {
          otherRateDetail = item;
        }
        if (isUSDTHKDRate || isUSDTHKDOtherRate) {
          if (isUSDTHKDRate) {
            coinDetail = USDT_HKD;
          }
          if (isUSDTHKDOtherRate) {
            otherRateDetail = USDT_HKD;
          }
        }
      });
      return { coinDetail, otherRateDetail };
    }
  }
  return { coinDetail, otherRateDetail };
};
class Store {
  constructor (rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      timeString: computed,
      fetchRecommendList: action,
      fetchMarketSymbol: action,
      fetchMarketCoin: action,
      fetchBaseCurrencies: action,
      updateMarketSymbol: action,
      fullMarketSymbols: action,
      marketSymbols: observable,
      marketCoins: observable,
      marketCoinsObj: observable,
      baseCurrenciesList: observable,
      otherRateDetail: observable,
      hotSearchList: observable,
      recommendList: observable
    });
  }

  marketSymbols = [];
  marketCoins = [];
  marketCoinsObj = {};
  baseCurrenciesList = [];
  coinDetail = [];
  otherRateDetail = [];
  hotSearchList = [];
  // coinDetail = []
  // otherRateDetail = []
  recommendList = [];

  fetchRecommendList = async () => {
    try {
      const response = await MarketAPI.fetchRecommend();
      if (response.success && response.obj) {
        runInAction(() => {
          this.recommendList = response.obj;
        });
        return Promise.resolve(response.obj);
      }
    } catch (e) {}
    return Promise.resolve([]);
  };

  fetchMarketSymbol = async () => {
    try {
      const response = await MarketAPI.marketSymbols();
      if (response.success && response.obj) {
        const newResObj = response.obj.map(i => ({ ...i, precision: i.symbolPrecision, coinCode: i.coinCode }))
        runInAction(() => {
          this.marketSymbols = newResObj;
        });
        return Promise.resolve(newResObj);
      }
    } catch (e) {}
    return Promise.resolve([]);
  };

  fetchMarketCoin = async () => {
    try {
      const response = await MarketAPI.coinList();
      if (response.success && response.obj) {
        const newResObj = response.obj.map(i => ({ ...i, precision: i.symbolPrecision, coinCode: i.coinCode }))
        runInAction(() => {
          this.marketCoins = newResObj;
          this.marketCoinsObj = newResObj.reduce((prev, curr) => {
            prev[curr.coinCode] = curr;
            return prev;
          }, {});
        });
        return newResObj;
      }
    } catch (e) {}
    return Promise.resolve([]);
  };

  fetchBaseCurrencies = async () => {
    try {
      const response = await SpotTradeAPI.getBaseCurrencies();
      if (response.success && response.obj) {
        const newResObj = response.obj

        runInAction(() => {
          this.baseCurrenciesList = newResObj;
        });
        return newResObj;
      }
    } catch (e) {}
    return Promise.resolve([]);
  };

  updateMarketSymbol = (newMarketSymbols) => {
    this.marketSymbols = newMarketSymbols;
  };

  fullMarketSymbols = async () => {
    const { showAssetAmount } = this.rootStore.userStore;
    console.log(showAssetAmount, 'showAssetAmount');
    const marketSymbols = await this.fetchMarketSymbol();

    const rateType = getRate()
    // console.log(rateType, 'rateTyperateType')
    const _rateType = 'USDT_' + (rateType || 'USDC');
    // console.log(marketSymbols, 'marketSymbols');
    const { coinDetail, otherRateDetail } = await extractFiatCoinDetailFromMarket(
      _rateType,
      marketSymbols
    );
    runInAction(() => {
      this.coinDetail = coinDetail;
      this.otherRateDetail = otherRateDetail;
    });
  };

  hotSearch = async () => {
    const response = await MarketAPI.hotSearch();
    if (response && response.obj) {
      runInAction(() => {
        this.hotSearchList = response.obj;
      });
    }
  };

  get timeString () {
    const pad = (n) => (n < 10 ? `0${n}` : n);
    const format = (t) =>
      `${pad(t.getUTCHours())}:${pad(t.getUTCMinutes())}:${pad(
        t.getUTCSeconds()
      )}`;
    return format(new Date(this.lastUpdate));
  }
}

function initializeStore (rootStore, initialData = null) {
  const _store = store ?? new Store(rootStore);

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here, check `pages/ssg.js` and `pages/ssr.js` for more details
  if (initialData) {
    _store.hydrate(initialData);
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
}

export function getMarketStore (rootStore, initialState) {
  const store = initializeStore(rootStore, initialState);
  return store;
}
