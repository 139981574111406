import { message } from 'antd';
import { action, makeObservable, observable, runInAction } from 'mobx'
import { enableStaticRendering } from 'mobx-react'

import I18n from '@utils/i18n';

import { CustomerAPI } from '../api/customer';
import { GoogleAPI } from '../api/google';
// eslint-disable-next-line react-hooks/rules-of-hooks
enableStaticRendering(typeof window === 'undefined')

let store

class Store {
  constructor (rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      sendVerifyCode: action,
      getuserInfo: action,
      getgoogleCode: action,
      bindEmail: action,
      unBindPhone: action,
      bindPhone: action,
      unBindEmail: action,
      getfull: action,
      updatejwtKey: action,
      pwdReset: action,
      userInfo: observable,
      jwtKey: observable
    });
  }

  userInfo = {}
  googleCode = ''
  jwtKey = ''

  sendVerifyCode = async (params) => {
    try {
      const response = await CustomerAPI.sendVerifyCode(params);
      if (response.success) {
        message.success(I18n.t(response.msgKey))
        return true
      } else {
        message.error(I18n.t(response.msgKey))
        return false
      }
    } catch (e) {
    }
  }

  // 获取用户信息
  getuserInfo = async (params) => {
    try {
      const response = await CustomerAPI.getCustomer(params);
      if (response.success && response.obj) {
        runInAction(() => {
          this.userInfo = response.obj
        })
      }
      return response.obj
    } catch (e) {
    }
  }

  getgoogleCode = async () => {
    try {
      const response = await GoogleAPI.key();
      if (response.success && response.obj) {
        runInAction(() => {
          this.googleCode = response.obj
        })
      }
    } catch (e) {
    }
  }

  bindEmail = async (params) => {
    try {
      const response = await CustomerAPI.bindEmail(params);
      // if (response.success && response.obj) {
      if (response.success) {
        message.success(I18n.t('user_login_sotre_bind'))
        // this.getuserInfo()
        return true
      } else {
        message.error(I18n.t(response.msgKey))
        return false
      }
      // }
    } catch (e) {
    }
  }

  unBindPhone = async (params) => {
    try {
      const response = await CustomerAPI.unBindPhone(params);
      // if (response.success && response.obj) {
      if (response.success) {
        message.success(I18n.t('user_login_sotre_unbind'))
        // this.getuserInfo()
        return true
      } else {
        message.error(I18n.t(response.msgKey))
        return false
      }
      // }
    } catch (e) {
    }
  }

  bindPhone = async (params) => {
    try {
      const response = await CustomerAPI.bindPhone(params);
      if (response.success) {
        message.success(I18n.t('user_login_sotre_bind'))
        // this.getuserInfo()
        return true
      } else {
        message.error(I18n.t(response.msgKey))
        return false
      }
    } catch (e) {
    }
  }

  // 验证验证码是否正确
  getfull = async (params) => {
    try {
      const response = await CustomerAPI.getfull(params);
      if (response.success) {
        message.success(I18n.t('user_login_sotre_re'))
        // this.updatejwtKey(response.obj)
        return response.obj
      } else {
        message.error(I18n.t(response.msgKey))
        return false
      }
    } catch (e) {
    }
  }

  // 邮箱解除绑定
  unBindEmail = async (params) => {
    try {
      const response = await CustomerAPI.unBindEmail(params);
      if (response.success) {
        message.success(I18n.t('user_login_sotre_re'))
        return true
      } else {
        message.error(I18n.t(response.msgKey))
        return false
      }
    } catch (e) {
    }
  }

  // 密码重置
  pwdReset = async (params) => {
    try {
      const response = await CustomerAPI.pwdReset(params);
      if (response.success) {
        message.success(I18n.t('user_login_sotre_modify'))
        return true
      } else {
        message.error(I18n.t(response.msgKey))
        return false
      }
    } catch (e) {
    }
  }

  // 更新token
  updatejwtKey = async (jwtKey) => {
    runInAction(() => {
      this.jwtKey = jwtKey
    })
  }
}

function initializeStore (rootStore, initialData = null) {
  const _store = store ?? new Store(rootStore)

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here, check `pages/ssg.js` and `pages/ssr.js` for more details
  if (initialData) {
    _store.hydrate(initialData)
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export function getSafetyStore (rootStore, initialState) {
  const store = initializeStore(rootStore, initialState)
  return store
}
