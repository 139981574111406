import React, { useRef, useState } from 'react';

import { Button, Popover } from 'antd';
import cn from 'classnames';

import { THEME } from '@constants/index';

import type { SelectProps } from './select.type';
import { SelectContent } from './SelectContent';

export const Select: React.FC<SelectProps> = ({
  labelMode = 'common',
  popoverClass,
  popupContainer,
  className,
  renderValue,
  align = { offset: [0, 2] },
  label,
  mode = 'single',
  valueRender,
  selectBtnCalssName,
  options,
  value,
  onChange,
  theme = THEME.HIGHLIGHT,
  ...rest
}) => {
  const [isExpand, setIsExpand] = useState(false);
  const containerRef = useRef<HTMLDivElement>();
  const selectOnChange = (params) => {
    setIsExpand(false);
    onChange(params)
  }
  const getPopupContainer = (containerRef) => {
    return (triggerNode) => {
      return containerRef.current
    }
  }
  return (
    <div ref={containerRef} className={cn(
      'kiki-select__container',
      className,
      { 'responsive-container': labelMode === 'responsive' }
    )}>
      <Popover
        trigger='click'
        placement="bottom"
        content={<SelectContent
          value={value}
          options={options}
          onChange={selectOnChange}
          theme={theme}
          {...rest}
        />}
        overlayClassName={cn('kiki-select-Popover', popoverClass, {
          'dark_kiki-select-Popover': theme === THEME.DARK
        })}
        visible={isExpand}
        onVisibleChange={setIsExpand}
        destroyTooltipOnHide={true}
        align={align}
        getPopupContainer={
          popupContainer?.(containerRef) || getPopupContainer(containerRef)
        }
      >
        <Button className={cn('kiki-select__button', selectBtnCalssName, {
          'kiki-select__button--active': isExpand,
          'dark_kiki-select__button--active': isExpand && theme === THEME.DARK,
          'dark_kiki-select__button': theme === THEME.DARK
        })}>
          {label && <span className='label'>
            {label}
          </span>}
          <p className={cn('value', {
            'value-space-between': !label
          })}>
            <span className={cn('valueText', {
              dark_valueText: theme === THEME.DARK
            })}>
              {valueRender?.(value) ||
                renderValue?.(options?.find?.(item => item.value === value)) ||
                options?.find?.(item => item.value === value)?.label ||
                value
              }
            </span>
            <span className={cn('icon', 'select-arrow', 'icon-down', {
              'select-arrow-up': isExpand
            })} />
          </p>
        </Button>
      </Popover>
    </div>
  )
}
