const host = 'https://kikitrade-preview.vercel.app/'
const api = {
  // host: 'https://newapi.beta.dipbit.xyz/',
  // host_newapi: 'https://api.beta.dipbit.xyz/',
  // host_api_v1: 'https://api.beta.dipbit.xyz/',
  host: host + 'api/',
  host_newapi: host + 'newapi/',
  host_api_v1: host + 'newapi/',
  upload_api: 'https://upload.dipbit.xyz/',
  supportUrl: 'https://cschat.antcloud.com.cn/index.htm?tntInstId=1Ab_9Aku&scene=SCE00941738',
  share: 'https://share.dipbit.xyz/',
  kiki_website_url: host + 'website/',
  get_short_link_url: 'https://s.dipbit.xyz'
};

export default api;
