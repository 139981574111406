import { useReducer, useMemo } from 'react';

const reduce = (state, action) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}

// 交易页面 loading
export const useTradeLoading = (initialState) => {
  const [state, dispatch] = useReducer(reduce, initialState);
  const updateLoadingState = (payload) => {
    console.log(payload, 'payloadpayload', initialState)
    dispatch({
      type: 'update',
      payload
    })
  }
  const status = useMemo(() => {
    if (Object.keys(initialState).map(i => state[i]).every(status => status === 'success')) {
      return 'success'
    }
    return 'loading'
  }, [state])
  return {
    status,
    updateLoadingState
  };
}
