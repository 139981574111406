// 联系我们数据
export const DARK_RELAXDATA = [
  {
    id: '1',
    url: 'https://www.facebook.com/kikitrade/',
    image: require('@images/facebooks.webp')
  },
  {
    id: '2',
    url: 'https://www.instagram.com/kikitrade_official/',
    image: require('@images/ins.webp')
  },
  {
    id: '3',
    url: 'https://twitter.com/KikitradeHQ',
    image: require('@images/twitter.webp')
  },
  {
    id: '4',
    url: 'https://www.linkedin.com/company/kikitrade/people/',
    image: require('@images/youtube.webp')
  },
  {
    id: '5',
    url: 'https://t.me/kikitrade',
    enUrl: 'https://t.me/kikitradeEN',
    image: require('@images/telegram.webp')
  }
]
export const GREY_RELAXDATA = [
  {
    id: '1',
    url: 'https://www.facebook.com/kikitrade/',
    image: require('@images/facebook1.webp')
  },
  {
    id: '2',
    url: 'https://www.instagram.com/kikitrade_official/',
    image: require('@images/ins1.webp')
  },
  {
    id: '3',
    url: 'https://twitter.com/KikitradeHQ',
    image: require('@images/twitter1.webp')
  },
  {
    id: '4',
    url: 'https://www.linkedin.com/company/kikitrade/people/',
    image: require('@images/youtube1.webp')
  },
  {
    id: '5',
    url: 'https://t.me/kikitrade',
    enUrl: 'https://t.me/kikitradeEN',
    image: require('@images/telegram1.webp')
  }
]
// 语言更改数据
// export const languageData = [
//   { label: '中文（繁体）', value: 'zh-TC' },
//   { label: 'English', value: 'en' },
//   { label: '中文（简体）', value: 'zh-CN' }
// ]
