import React from 'react';

import cn from 'classnames';
import omit from 'lodash/omit';

import { THEME } from '@constants/index';

export const Item = (props) => {
  const {
    itemClassName,
    style,
    item,
    value,
    onChange,
    itemPrefix,
    itemSuffix,
    theme
  } = props;
  const isActive = value === item.value;
  return (
    <li style={style} className={cn('menu-item',
      itemClassName,
      item.className,
      {
        'menu-item--active': isActive,
        'dark_menu-item--active': value === item.value && theme === THEME.DARK,
        'dark_menu-item': theme === THEME.DARK
      })}
      onClick={
        () => onChange(omit(item, ['className', 'itemSuffix', 'itemPrefix']))
      }
      data-buired={item.dataBuried}
    >
      <p className='content'>
        {item?.itemPrefix || itemPrefix}
        <span className='label'>{item.label}</span>
      </p>
      {item?.itemSuffix || itemSuffix || (
        <span className={cn('icon', {
          'icon-check': isActive
        })} />
      )}
    </li>
  )
}
