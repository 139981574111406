/*
 * @Author: jikang.wu jikang.wu@kikitrade.com
 * @Date: 2024-05-15 16:38:12
 * @LastEditors: Please set LastEditors
 */
import config from '@utils/config';
import helper from '@utils/helper';
import request from '@utils/request';

export type RemainType = {
  currency: string,
  remain: number
}
export type RemainParamsItemTypeype = {
  walletAddress: string,
  connectEmail: string
}
export type RemainApplyItemType = {
  walletAddress: string,
  connectEmail: string
}

export const remain = (opts): Promise<APIResponse<RemainType>> => {
  // 查询用户可提币余额
  const URL = config.api.host_newapi + config.api.withdrawl.remain;
  return request.get.call({}, URL, opts);
};

export const remainApply = (params: RemainParamsItemTypeype, opts: {}): Promise<APIResponse<RemainApplyItemType>> => {
  // 用户申请提取余额
  const URL = config.api.host_newapi + config.api.withdrawl.remainApply +
  '?' +
  helper.mapToQueryString(params);
  return request.post.call({}, URL, { params, ...opts });
};

export const remainRecords = (opts): Promise<APIResponse<RemainApplyItemType[]>> => {
  // 查询用户提取纪录
  const URL = config.api.host_newapi + config.api.withdrawl.remainRecords;
  return request.get.call({}, URL, opts);
};
