import React, { useRef, useState } from 'react';

import { Empty } from 'antd';
import cn from 'classnames';
import omit from 'lodash/omit'

import { THEME } from '@constants/index';

import { Search } from './Search';
import { SearchProps } from './select.type';
import { SelectList } from './SelectList';

export const SelectContent = (props) => {
  const {
    optionWrapperClass, empty, search, options, theme,
    value, onChange, itemPrefix, itemSuffix, itemClassName, virtualListConfig, ...rest
  } = props
  const [optionsData, setOptionsData] = useState(options)
  const optionsRef = useRef(options);
  const defaultSearch = (value) => {
    const optionsData = optionsRef.current.filter(item => {
      return item.label.toLocaleLowerCase().includes(value.toLocaleLowerCase())
    })

    setOptionsData(optionsData);
  }
  const onSearch = (value) => {
    const optionsData = search?.onSearch?.(value)
    if (Array.isArray(optionsData)) {
      setOptionsData(optionsData)
      return
    }
    defaultSearch(value)
  }
  const onSearchChange: SearchProps['onSearch'] = (value) => {
    const optionsData = search?.onChange?.(value)
    if (Array.isArray(optionsData)) {
      setOptionsData(optionsData)
      return
    }
    defaultSearch(value)
  }
  const renderEmpty = () => {
    if (optionsRef.current?.length) {
      return (<Empty description={search?.empty} />)
    }
    return (<Empty description={empty} />)
  }
  return (
    <div className={cn('kiki-select-Popover__container', {
      'dark_kiki-select-Popover__container': theme === THEME.DARK
    })}>
      {search && <Search
        className="kiki-select-search"
        onChange={onSearchChange}
        onSearch={onSearch}
        theme={theme}
        search={omit(search, ['onSearch', 'onChange', 'empty'])}
      />}
      {!optionsData?.length && renderEmpty()}
      <SelectList
        virtualListConfig={virtualListConfig}
        optionsData={optionsData}
        itemClassName={itemClassName}
        value={value}
        onChange={onChange}
        theme={theme}
        optionWrapperClass={optionWrapperClass}
        itemPrefix={itemPrefix}
        itemSuffix={itemSuffix}
        {...rest}
      />
    </div>
  )
}
