import React from 'react';

import { useObserver } from 'mobx-react';

import { getMarketStore } from './market.store';
import { getSafetyStore } from './safety.store';
import { getTradeStore } from './trade.store';
import { getUserStore } from './user.store';
import { getWithdrawalStore } from './withdrawal.store';

class RootStore {
  constructor (initialState = null) {
    this.marketStore = getMarketStore(this, initialState)
    this.userStore = getUserStore(this, initialState)
    this.tradeStore = getTradeStore(this, initialState)
    this.getSafetyStore = getSafetyStore(this, initialState)
    this.withdrawalStore = getWithdrawalStore(this, initialState)
  }
}

const StoresContext = React.createContext(new RootStore());

// this will be the function available for the app to connect to the stores
export const useStore = () => React.useContext(StoresContext);

export const store = new RootStore()

export const useUserStore = () => {
  const { userStore } = useStore();
  return useObserver(() => userStore)
}
