import config from '../utils/config';
import { helper } from '@utils/index';
import request from '../utils/request';
import { IGetKycTokenRes, IGetKycTokenReq, Kyc2InfoParams, KycPayQuotaResult } from './kyc.type';

export const fetchKycResult = () => {
  const URL = `${config.api.host_api_v1}${config.api.kyc.kycResult}`;
  return request.get.call({}, URL);
};

export const getKycToken = (params: IGetKycTokenReq): Promise<APIResponse<IGetKycTokenRes>> => {
  const URL = `${config.api.host_newapi}${config.api.kyc.kycToken}?${helper.mapToQueryString(params)}`;
  return request.post.call({}, URL);
};

export const kyc1ToCheck = (): Promise<APIResponse> => {
  const URL = `${config.api.host_newapi}${config.api.kyc.kyc1ToCheck}`;
  return request.post.call({}, URL);
};

export const kyc2InfoUpload = (params: Kyc2InfoParams): Promise<APIResponse> => {
  const URL = `${config.api.host_newapi}${config.api.kyc.kyc2InfoUpload}?${helper.mapToQueryString(params)}`;
  return request.post.call({}, URL);
};

export const kycPayQuota = (): Promise<APIResponse<KycPayQuotaResult>> => {
  const URL = `${config.api.host_newapi}${config.api.kyc.kycPayQuota}`;
  return request.get.call({}, URL);
};
