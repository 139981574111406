import config from '../utils/config';
import request from '../utils/request';
import helper from '../utils/helper';

// class definition
export const getRepayList = (params) => {
  const URL = config.api.host_api_v1 + config.api.repay.repayList + '?' + helper.mapToQueryString(params);
  return request.post.call({}, URL);
}

export const getMarginAssets = () => {
  const URL = config.api.host_api_v1 + config.api.repay.assets
  return request.get.call({}, URL);
}

export const getRepayPreview = (params) => {
  const URL = config.api.host_api_v1 + config.api.repay.preview + '?' + helper.mapToQueryString(params);
  return request.post.call({}, URL);
}

export const repay = (params) => {
  const URL = config.api.host_api_v1 + config.api.repay.repay + '?' + helper.mapToQueryString(params);
  return request.post.call({}, URL);
}

export const getRepayStatus = (params) => {
  const URL = config.api.host_api_v1 + config.api.repay.repayments.replace(/{repayment_id}/, params.repayment_id);
  return request.get.call({}, URL);
}
