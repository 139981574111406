import config from '../utils/config';
import request from '../utils/request';
import helper from '../utils/helper';

// 查看商户信息 C2C账户
export const getMerchantInfo = (merchantId) => {
  const URL = `${config.api.host_api_v1}${config.api.merchant.info}?${helper.mapToQueryString({ merchantId })}`;
  return request.get.call({}, URL);
}

// kyc详情
export const kycResult = () => {
  const URL = `${config.api.host_api_v1}${config.api.merchant.kycDetail}`;
  return request.get.call({}, URL);
}
