export enum IPwdLevel {
  HIGHT = 'hight',
  MID = 'mid',
  LOW = 'low'
}

export enum ISecurityLevel {
  HIGHT = 3,
  MID = 2,
  LOW = 1
}

export enum IShowType {
  EMAIL = 'email',
  PHONE = 'phone',
  GOOGLE = 'google',
  PASSWORD = 'password',
  RELATION = 'relation'
}

export enum IKycStatus {
  /** 非白名单用户 */
  NOT_ALLOW= -2,
  /** 用户已注销 */
  DELETED = -1,
  /** 未认证 */
  UNVERIFED = 0,
  /** KYC1 审核中 */
  L1_AUDIIING = 1,
  /** KYC1 未通过 */
  L1_REJECT = 2,
  /** KYC1 已通过 */
  L1_SUCCESS = 3,
  /** KYC2 审核中 */
  L2_AUDIIING = 4,
  /** KYC2 未通过 */
  L2_REJECT = 5,
  /** KYC2 已通过 */
  L2_SUCCESS = 6,
}

export type IBodyContenttype = '1' | '2';
export type IRegisterType = 0 | 1;

type IwhiteListCounty= string

export type IwhiteListCountriesListtype=IwhiteListCounty[]
