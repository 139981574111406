import React, { useState } from 'react'
import cn from 'classnames';
// import { languageData } from '@utils/offlineData/homepageData'
import styles from './index.module.css'
import { Select } from '@element/Select';
import { useRouter } from 'next/router'
import helper from '@utils/helper'
import I18n, { languageOptions } from '@utils/i18n';
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect'
import themeStyle from '../../theme.module.css';

export const Language = ({ theme = 'dark' }) => {
  const [values, setvalues] = useState('')
  const router = useRouter()
  const valueLanguage = router.locale
  useIsomorphicLayoutEffect(() => {
    languageOptions.forEach(item => {
      if (valueLanguage === item.value) {
        setvalues(item.value)
      }
    })
  }, [])
  // 语言切换事件
  const onLanguage = (option) => {
    setvalues(option.value)
    helper.languageSwitch(option.value)
  }
  return <div className={styles.languageName}>
            <div className={styles.btnText}>{I18n.t('language')}</div>
            <Select
              value={values}
              options={languageOptions}
              onChange={(option) => onLanguage(option)}
              empty={I18n.t('noData')}
              className={cn(styles.selectData, themeStyle[`${theme}-selectData`])} // 修改select宽度
              // selectBtnCalssName={styles.selectBtn}
              data-name='kiki-web-language'
              />
        </div>
}
