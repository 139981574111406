import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

if (process.env.NODE_ENV !== 'development') {
  Bugsnag.start({
    apiKey: '60ec1082c578a89f18937dcd5bf2d918',
    plugins: [new BugsnagPluginReact()]
  })
}

module.exports = {
  notify: function (data) {
    Bugsnag.notify(data)
  },
  setUser: function (id, name, email) {
    Bugsnag.setUser(id, name, email)
  }

}
