import React from 'react'

import cn from 'classnames';
import Image from 'next/legacy/image';
import Link from 'next/link'

import { EN } from '@constants/index'
import I18n from '@utils/i18n';
import { DARK_RELAXDATA, GREY_RELAXDATA } from '@utils/offlineData/homepageData'

import { Language } from '../Language'

import themeStyle from '../../theme.module.css';
import Istyles from './index.module.css'

const RELAXDATA = {
  dark: DARK_RELAXDATA,
  grey: GREY_RELAXDATA
}

export const RelaxUs = ({ theme = 'dark' }) => {
  return <div className={Istyles.relaxData}>
            <div className={cn(Istyles.relaxUs, 'Bold', themeStyle[`${theme}-relaxUs`])}>{I18n.t('contact_us')}</div>
            <div className={Istyles.relaxDataImage}>
                {
                    RELAXDATA[theme].map(item => {
                      const url = I18n.locale === EN && item.enUrl ? item.enUrl : item.url
                      return (
                        <Link legacyBehavior href={url} key={item.id}>
                            <a data-buried="relaxUs" target={'_blank'}>
                                <div className={Istyles.relaxImage}>
                                    <Image
                                        src={item.image}
                                        width={30}
                                        height={30}
                                        alt={'kikitrade'}
                                    />
                                </div>
                            </a>
                        </Link>
                      )
                    })
                }
            </div>
            <Language theme={theme} />
        </div>
}
