import { useRef } from 'react';
import { useIsomorphicLayoutEffect } from '@lib/hooks'

export const useAsideHeight = () => {
  const ref = useRef();
  useIsomorphicLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(([entry]) => {
      ref.current?.style?.setProperty('--aside-height', `${ref.current.clientHeight}px`)
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.unobserve(ref.current)
    }
  }, [])
  return { ref }
}
