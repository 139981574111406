import { helper } from '@utils/index';

import config from '../utils/config';
import request from '../utils/request';
import { IAssetPasswordReq, IAssetPwdVerify, ISecurityVerify } from './customer.type';

// class definition
class CustomerApi {
  login (params, options) {
    const URL =
      config.api.host_api_v1 +
      config.api.customer.login +
      '?' +
      helper.mapToQueryString(params ?? {});

    return request.post
      .call({}, URL, options);
  };

  loginOauth (params) {
    const URL =
      config.api.host_api_v1 +
      config.api.customer.login_oauth +
      '?' +
      helper.mapToQueryString(params ?? {});

    return request.post
      .call({}, URL);
  }

  query (params) {
    const URL =
      config.api.host_newapi +
      config.api.customer.query +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  verifyCode (params) {
    const URL =
      config.api.host_newapi +
      config.api.customer.verify +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  sendEmailCode (params, options) {
    const URL = `${config.api.host_newapi}${config.api.customer.sendEmailCode}?${helper.mapToQueryString(params)}`
    return request.post.call({}, URL, options);
  }

  sendPhoneCode (params, options) {
    const URL = `${config.api.host_newapi}${config.api.customer.sendPhoneCode}?${helper.mapToQueryString(params)}`
    return request.post.call({}, URL, options);
  }

  sendVerifyCode (params, options) {
    if (params.email) {
      return this.sendEmailCode(params, options)
    } else {
      return this.sendPhoneCode(params, options);
    }
  };

  getCustomer (params) {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.customer.customer +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  verifyRebateCode (params) {
    const URL =
      config.api.host_newapi +
      config.api.customer.verifyRebateCode +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  register (params) {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.customer.register +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  forgotPwdNew (params) {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.customer.forgotPwdNew +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  forgotPwd (params, options) {
    const URL =
      config.api.host_newapi +
      config.api.customer.forgotPwd +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call(params, URL, options);
  };

  // 联系方式更新
  updateContact (params) {
    // console.log('params', params, helper.mapToQueryString(params))
    const URL = config.api.host_newapi + config.api.customer.update + '?' + helper.mapToQueryString(params);
    return request.post.call(params, URL);
  }

  bindEmail (params, options) {
    const URL =
      config.api.host_newapi +
      config.api.customer.bindEmail +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call(params, URL, options);
  }

  // bindGoodle (params, options) {
  //   const URL =
  //   config.api.host_newapi +
  //   config.api.google.bindEmail +
  //   '?' +
  //   helper.mapToQueryString(params);
  //   return request.post.call(params, URL, options);
  // }

  getfull (params, options) {
    const URL =
      config.api.host_newapi +
      config.api.customer.full +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call(params, URL, options);
  }

  unBindEmail (params, options) {
    const URL =
      config.api.host_newapi +
      config.api.customer.unBindEmail +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call(params, URL, options);
  }

  bindPhone (params, options) {
    const URL =
      config.api.host_newapi +
      config.api.customer.bindPhone +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call(params, URL, options);
  }

  unBindPhone (params, options) {
    const URL =
      config.api.host_newapi +
      config.api.customer.unBindPhone +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call(params, URL, options);
  }

  pwdReset (params, options) {
    const URL =
      config.api.host_newapi +
      config.api.customer.pwdReset +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call(params, URL, options);
  }

  fullVerity (params) {
    // params.saas_id = 'kiki';
    const URL =
      config.api.host_newapi +
      config.api.customer.fullVerify +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  logout () {
    // params.saas_id = 'kiki';
    const URL = `${config.api.host_newapi}${config.api.customer.logout}`;
    return request.post.call({}, URL);
  };

  security (params) {
    const URL =
      config.api.host_newapi +
      config.api.customer.security +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL);
  };

  /** 设置资金密码 */
  assetPassword (params: IAssetPasswordReq): Promise<APIResponse> {
    const URL = `${config.api.host_newapi}${config.api.customer.assetPassword}?${helper.mapToQueryString(params)}`;
    return request.post.call({}, URL);
  };

  /**
   * 二次验证
   * 用来判断是否可以发送空投的，忘记密码后24h内不能进行出入金操作，也就是不能发空投
   */
  securityVerify (params: ISecurityVerify): Promise<APIResponse> {
    const URL = `${config.api.host_newapi}${config.api.customer.securityVerify}?${helper.mapToQueryString(params)}`;
    return request.get.call({}, URL);
  };

  /** 验证资金密码是否正确 */
  assetPwdVerify (params: IAssetPwdVerify): Promise<APIResponse> {
    const URL = `${config.api.host_newapi}${config.api.customer.assetPwdVerify}?${helper.mapToQueryString(params)}`;
    return request.post.call({}, URL);
  };
}

const CustomerAPI = new CustomerApi();
export { CustomerAPI };
