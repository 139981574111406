import './app'
import { getRemoteConfigValue } from './remoteConfig'
export { getRemoteConfigValue } from './remoteConfig'

function isWindowSupported () {
  // firebase-js-sdk/issues/2393 reveals that idb#open in Safari iframe and Firefox private browsing
  // might be prohibited to run. In these contexts, an error would be thrown during the messaging
  // instantiating phase, informing the developers to import/call isSupported for special handling.
  return (typeof window !== 'undefined' &&
    'serviceWorker' in navigator &&
    'PushManager' in window &&
    'Notification' in window &&
    'fetch' in window)
}

if (isWindowSupported()) {
  require('./message')
}

const firebase = {
  getRemoteConfigValue: getRemoteConfigValue

};

module.exports = firebase
