
// @flow

import { IKycStatus } from '@type/user';
import { I18n } from '@utils/index';

export const withdrawKycMsg = (status) => {
  const kycStatus = {
    [IKycStatus.UNVERIFED]: I18n.t('withdraw_kyc_unverifed'),
    [IKycStatus.L1_AUDIIING]: I18n.t('withdraw_kyc_audiiing'),
    [IKycStatus.L1_REJECT]: I18n.t('withdraw_kyc_reject')
  };
  return kycStatus[status] || '';
};

export const withdrawKycButtonMsg = (status) => {
  const kycStatus = {
    [IKycStatus.UNVERIFED]: I18n.t('withdraw_unverifed_okText'),
    [IKycStatus.L1_AUDIIING]: I18n.t('withdraw_audiiing_okText'),
    [IKycStatus.L1_REJECT]: I18n.t('withdraw_reject_okText')
  };
  return kycStatus[status] || '';
};
