import React, { useState } from 'react'

import { useIsomorphicLayoutEffect } from '@lib/hooks'
import { getRemoteConfigValue } from '@utils/firebase'
import { helper } from '@utils/index'

import { FooterH5 } from './Component/FooterH5'
import { FooterPC } from './Component/FooterPC'
interface PageFooterProps {
  theme?: 'dark' | 'grey';
}

export const PageFooter: React.FC<PageFooterProps> = ({ theme }) => {
  const [newFooterData, setNewFooterData] = useState([])

  useIsomorphicLayoutEffect(() => {
    getRemoteConfigValue(`footer_nav_web_${helper.getLocaleKey()}`).then((res) => {
      try {
        setNewFooterData(JSON.parse(res.asString()))
      } catch (e) {
      }
    })
  }, [])
  return <>
    <FooterPC newFooterData={newFooterData} theme={theme} />
    <FooterH5 newFooterData={newFooterData} theme={theme} />
  </>
}

const FooterNav = () => {
  return (
    <>
      <PageFooter theme="dark" />
    </>
  )
}
export default FooterNav
