import { useAccountInfo,  } from '@particle-network/connectkit';
import { hexer }  from '../util/hexer'
import { useParticleConnect } from '@particle-network/connectkit';
import { chainids } from '../constants/chainids'

export function useEOAWalletConnectKit(){
  const { connect } = useParticleConnect()


  const connectEOAWallet = async({id}) => {
    if(id === 'metamask') {
      if(typeof window !== 'undefined' && window?.ethereum?.isMetaMask) {
        return await  connect({id})
      } else {
        return window.open("https://metamask.io/download/")
      }
    } else {
      return await connect({id})
    }


  }
  const getWalletSignture =  async (particleProvider, params, address)=> {
    let signature;
    const challenge = hexer(params)
    signature = await particleProvider.request({ method: 'personal_sign', params: [challenge, address] });
    return signature
  }


  const getWalletChainName = async (particleProvider) => {
    const hex = await particleProvider.request({ method: 'eth_chainId' });
    const decimal = parseInt(hex, 16)
    const name = chainids[decimal] || 'evm'
    return name
  }

  return {
    getWalletSignture,
    connectEOAWallet,
    getWalletChainName
  }
}
