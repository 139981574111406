import { DEPOSIT, WALLET_PATHS } from '@constants/paths';
import { BuyingMethodKey } from '@widget/trade/BuyAndSell/constants';

export const routeFormat = (item, baseCurrency) => {
  let params = {};
  const { key } = item;
  switch (key) {
    case BuyingMethodKey.Coins:
      params = {
        pathname: DEPOSIT
      }
      break;
    case BuyingMethodKey.Transfer:
      params = {
        pathname: WALLET_PATHS.TRANSFER.replace('[coin]', baseCurrency)
      }
      break;
    case BuyingMethodKey.Invest:
    default:
      params = {
        pathname: WALLET_PATHS.DEPOSIT.replace('[coin]', baseCurrency)
      }
      break;
  }

  return params;
}
