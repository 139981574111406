const api = {
  host: 'https://newapi.kikitrade.com/',
  host_newapi: 'https://api.kikitrade.com/',
  host_api_v1: 'https://api.kikitrade.com/',
  // host: 'https://webbeta.kikitrade.com/pro_api/',
  // host_newapi: 'https://webbeta.kikitrade.com/pro_newapi/',
  // host_api_v1: 'https://webbeta.kikitrade.com/pro_newapi/',
  supportUrl: 'https://cschat.antcloud.com.cn/index.htm?tntInstId=1Ab_9Aku&scene=SCE00529069',
  share: 'https://share.kikitrade.com/',
  kiki_website_url: 'https://m.kikitrade.com/',
  upload_api: 'https://upload.kikitrade.com/',
  get_short_link_url: 'https://kiki.trade'
};

export default api
