import zhKeys from './zh';
import hkKeys from './hk';
import enKeys from './en';
import msgKeyEn from './msgKey_en';
import msgKeyZh from './msgKey_zh';
import msgKeyHk from './msgKey_hk';

export const zh = {
  ...zhKeys,
  ...msgKeyZh
}
export const en = {
  ...enKeys,
  ...msgKeyEn
}
export const hk = {
  ...hkKeys,
  ...msgKeyHk
}
