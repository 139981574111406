import fp from '@fingerprintjs/fingerprintjs';

/**
 * 获取设备唯一 ID，此方法必须在浏览器执行
 * @returns string
 */
export const getDeviceId = async () => {
  let deviceId = 'kiki';
  if (typeof window === 'undefined') {
    return 'kiki'
  }
  deviceId = localStorage.getItem('deviceId');
  if (deviceId) {
    return deviceId
  }
  const agent = await fp.load();
  const res = await agent.get();
  deviceId = `kiki_${res.visitorId}`;
  localStorage.setItem('deviceId', deviceId);
  return deviceId;
}
