
export const getLanguageZone = () => {
  const language = navigator.language || 'zh-CN';
  /** 浏览器设置语言映射中英文 */
  if (['zh-TW', 'zh', 'zh-HK', 'zh-CN'].includes(language)) {
    return 'HK'
  } else {
    return 'EN'
  }
}
