export function formatNumber (number) {
  const [integers, decimals] = String(number).split('.');
  const _integers = String(integers)
    .split('')
    .reverse()
    .reduce((prev, next, index) => {
      return (index % 3 ? next : next + ',') + prev;
    });
  return decimals ? _integers + '.' + decimals : _integers;
}

interface setTextStyleProps {
  index?: number, searchClass: string, text: string, replaceTextClass: string
}

export const setTextStyle = ({ index = 0, searchClass, text, replaceTextClass }: setTextStyleProps) => {
  // <div className={searchClass}>好友交易，邀请人和被邀请人同时获得最高 30% 交易返佣</div> --->
  // <div>好友交易，邀请人和被邀请人同时获得最高<span className={replaceTextClass}> 30% <span>交易返佣</div>
  // text ---> 30%
  const tips = document.getElementsByClassName(searchClass)
  const htmlContent = tips[index].innerHTML
  const newHtml = htmlContent.replace(text,
    `<span class="${replaceTextClass}">${text}</span>`)
  tips[index].innerHTML = newHtml
}
