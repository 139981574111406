
import React from 'react';
import { Collapse } from 'antd';
const { Panel } = Collapse;

const RENDERCONT = (data) => {
  return (
    data?.map((item, index) => {
      return <li key={index}>
                  <a href={item.url} rel="noreferrer" target='_blank'>
                      {item.text}
                  </a>
              </li>
    })
  );
}

export const NAVDATA = (value) => {
  return (
    value?.map((item, index) => {
      return <Panel header={item.name} key={index}>
                  {RENDERCONT(item.children)}
              </Panel>
    })
  );
}
