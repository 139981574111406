import { keepAliveDropCache } from 'react-next-keep-alive'

import { message } from 'antd';
import { useRouter } from 'next/router';

import { LOGIN_BEFORE_PATHS } from '@constants/index'
import I18n from '@utils/i18n'
export const useLogout = ({ userStore, isJumpLogin = true }) => {
  const router = useRouter();

  const logout = async () => {
    const res = await userStore.logout()
    if (typeof res === 'string') {
      message.error(I18n.t(res));
      return
    }
    localStorage?.removeItem('userStore')
    localStorage?.removeItem('tradeStore')
    sessionStorage?.removeItem('financial_tab_key')
    sessionStorage?.removeItem('AIP_ACTIVE_KEY')
    isJumpLogin && router.push({
      pathname: LOGIN_BEFORE_PATHS.LOGIN
    })
    keepAliveDropCache('', true)
  }
  return {
    logout
  }
}
