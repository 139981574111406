import { startTransition, useState } from 'react'

import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect'

import useDebouce from './useDebouce'

const useClient = () => {
  const [isPc, setisPc] = useState('')
  const updateIsPc = () => {
    setisPc(document.body.clientWidth > 960)
  }
  const isPcfunc = useDebouce(updateIsPc, 500)
  useIsomorphicLayoutEffect(() => {
    startTransition(() => {
      updateIsPc()
    })

    window.addEventListener('resize', isPcfunc)
    return () => window.removeEventListener('resize', isPcfunc)
  }, [])
  return isPc
}

export default useClient
