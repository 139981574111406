import '../lib/scripts/wdyr'
import '../utils/firebase'
import '../utils/bugsnag'
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/en-au';
import 'dayjs/locale/zh-hk';

import * as React from 'react'
import { useEffect, useState } from 'react'
import { KeepAliveProvider } from 'react-next-keep-alive';

import { ConfigProvider } from 'antd';
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc';
import { Provider } from 'mobx-react'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { ParticleAuthCoreContextProvider, ParticleModalProvider } from 'particle-connect'

import { DARK_FOOTER_PAGE, INVITATION_FOOTER_PAGE, NOT_FOOTER_PAGE } from '@constants/index';
import { I18n as BitI18n } from '@kiki/f2e.utils.locale.i18n';
import { useIsomorphicLayoutEffect } from '@lib/hooks';
import pubsub from '@lib/scripts/pubsub'
import { useAccount, useParticleConnect } from '@particle-network/connectkit';
import { walletEntryPlugin } from '@particle-network/wallet'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { getPageHeader } from '@utils/getPageHeader';
import { en, hk, zh } from '@utils/language'
import { PageFooter } from '@widget/home/FooterNav';

import { useStore } from '../store'
import * as gtag from '../utils/gtag'
import I18n from '../utils/i18n'

// import Layout from '../components/Layout'
import 'antd/dist/antd.css';
import '../styles/common/app.css';

const H5DownLoad = dynamic(
  () => import('../components/H5DownLoad'),
  { ssr: false }
)

const DeviceRiskVerification = dynamic(
  () => import('../widget/user/safety/DeviceRiskVerification'),
  { ssr: false }
)

dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(relativeTime);

dayjs.extend(utc)
const queryClient = new QueryClient();

if (typeof window !== 'undefined') {
  // 临时处理xss攻击
  window.alert = () => {}
  window.prompt = () => {}
}

// const ROUTES_TO_RETAIN = ['/']

export function reportWebVitals ({ id, name, label, value }) {
  // console.log({ id, name, label, value })
  // Use `window.gtag` if you initialized Google Analytics as this example:
  // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js
  if (window.gtag) {
    // console.log({ id, name, label, value })
    window.gtag('event', name, {
      event_category:
        label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      event_label: id, // id unique to current page load
      non_interaction: true // avoids affecting bounce rate.
    })
  }
}

function WalletConnectComponent ({ children }) {
  const walletAccount = useAccount();
  const [provider, setProvider] = useState(null)
  const { cacheconnect, disconnect } = useParticleConnect()
  useIsomorphicLayoutEffect(() => {
    initCacheProvider()
  }, [])
  const initCacheProvider = async function () {
    const _provider = await cacheconnect()
    setProvider(_provider)
  }
  const disconnectWallet = () => {
    disconnect()
    localStorage.removeItem('walletUrl')
  }
  useIsomorphicLayoutEffect(() => {
    pubsub.subscribe('DISCONNECT_WALLET', disconnectWallet)
    return () => {
      pubsub.unSubscribe('DISCONNECT_WALLET', disconnectWallet);
    }
  }, [])

  useIsomorphicLayoutEffect(() => {
    pubsub.subscribe('CONNECT_WALLET', initCacheProvider)
    return () => {
      pubsub.unSubscribe('CONNECT_WALLET', initCacheProvider);
    }
  }, [])

  useIsomorphicLayoutEffect(() => {
    if (walletAccount && provider) {
      if (!localStorage.getItem('walletUrl')) {
        const url = walletEntryPlugin.getWalletUrl({ topMenuType: 'close' })
        localStorage.setItem('walletUrl', url)
      }
    }
  }, [walletAccount, provider])

  return (
    <div>
      {children}
    </div>
  )
}
export default function MyApp ({ Component, pageProps }) {
  const router = useRouter()

  if (router.locale === 'zh-TC') {
    dayjs.locale('zh-hk')
  }
  if (router.locale === 'zh-CN') {
    dayjs.locale('zh-cn')
  }
  if (!router.locale || router.locale === 'en') {
    dayjs.locale('en')
  }
  const newLocale = router.locale === 'zh-TC' ? 'hk' : (router.locale === 'zh-CN' ? 'zh' : 'en')
  I18n.mergeRemoteLocale(newLocale)
  I18n.updateLocale(router.locale)
  BitI18n.init({
    en,
    hk,
    zh
  })
  BitI18n.setLocale(newLocale)

  pageProps.locale = router.locale
  pageProps.locales = router.locales
  pageProps.route = router.route
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page)

  const store = useStore(pageProps.initialState)
  // useEffect(() => {
  //   const Adjust = require('@adjustcom/adjust-web-sdk')
  //   Adjust.initSdk({
  //     appToken: 'lsqn2ip3ddds',
  //     environment: 'production'
  //   });
  // }, [])

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  // Save scroll position - requires an up-to-date router.asPath
  // useEffect(() => {
  //   router.events.on('routeChangeStart', handleRouteChangeStart)
  //   return () => {
  //     router.events.off('routeChangeStart', handleRouteChangeStart)
  //   }
  // }, [router.asPath])

  // Scroll to the saved position when we load a retained component
  // useEffect(() => {
  //   if (isRetainableRoute) {
  //     window.scrollTo(0, retainedComponents.current[router.asPath].scrollPos)
  //   }
  // }, [Component, pageProps])

  return getLayout(
      <Provider store={store} locale={pageProps.locale}>

        {/* Global Site Tag (gtag.js) - Google Analytics */}
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
        />
        <Script
          id="gtag-init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${gtag.GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });
            `
          }}
        />
        <Script
          src="//o.alicdn.com/captcha-frontend/aliyunFP/fp.min.js"
          strategy="beforeInteractive"
          onReady={() => {
            // eslint-disable-next-line no-undef
            ALIYUN_FP.use('um', (state, um) => {
              if (state === 'loaded') {
                um.init({
                  appKey: '2443d5ee9cc1470ce225fdfd38385505',
                  appName: 'kiki-web',
                  endpoints: ['https://cloudauth-device.ap-southeast-1.aliyuncs.com']
                }, function (initState, result) {
                  if (initState === 'success') {
                    window.umModule = um;
                  }
                  console.log('initState', result)
                });
              }
            });
          }}
        />

        <Head>
          {getPageHeader({ pathname: router.route, locale: router.locale })}
        </Head>

        <ConfigProvider autoInsertSpaceInButton={false}>
          <KeepAliveProvider router={router}>
            <QueryClientProvider client={queryClient} contextSharing={true}>
              <ParticleAuthCoreContextProvider >
                <ParticleModalProvider>
                  <WalletConnectComponent>
                    <Component {...pageProps} />
                  </WalletConnectComponent>
                </ParticleModalProvider>
              </ParticleAuthCoreContextProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </KeepAliveProvider>
          {![...DARK_FOOTER_PAGE,
            ...NOT_FOOTER_PAGE,
            ...INVITATION_FOOTER_PAGE
          ].includes(router.route) && <PageFooter theme="grey" />}
          <H5DownLoad />
          <DeviceRiskVerification />
        </ConfigProvider>
      </Provider>

  )
}
