export const readonlyToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiYW5vbnltb3VzIn0.bdFfo5zO73GK4DMqJE8Kxro3KHyEQqZ5aw7puIiqS0U';

const JwtToken = {
  token: readonlyToken,
  setToken: function (newToken) {
    this.token = newToken
  },
  getToken: function () {
    return this.token
  },
  clearToken: () => {
    this.token = readonlyToken
  }
};

export default JwtToken;
