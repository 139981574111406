import config from '@utils/config';
import helper from '@utils/helper';
import request from '@utils/request';

import type {
  IInviteAwardsReq, IInviteAwardsRes,
  IInviteAwardsWithRoleReq, IInviteAwardsWithRoleRes,
  IInviteInfoRes,
  IRewardsInfo,
  RebateProportionRes,
  RewardListReq
} from './activity.type';

export const updateInviteCode = (params) => {
  const URL = config.api.host_newapi + config.api.activity.updateInviteCode +
    '?' +
    helper.mapToQueryString(params);
  return request.put.call({}, URL);
};

// 邀请记录列表
export const getInviteRecord = (params) => {
  const URL = config.api.host_newapi + config.api.activity.invitaRecord +
    '?' +
    helper.mapToQueryString(params);
  return request.get.call({}, URL);
}

/**
   * 获取邀请码,活动规则链接，活动描述,返佣比例等基础信息 —— 新接口
   * @param params
   * @returns {*}
   */
export const fetchInviteInfo = (params: { locale?: string }): Promise<APIResponse<IInviteInfoRes>> => {
  const URL = `${config.api.host_api_v1}${config.api.activity.inviteInfo}?${helper.mapToQueryString(params ?? {})}`
  return request.get.call({}, URL);
};

export const getInviteAwardsWithRole = (params: IInviteAwardsWithRoleReq): Promise<APIResponse<IInviteAwardsWithRoleRes>> => {
  const URL = `${config.api.host_api_v1}${config.api.activity.inviteAwardsWithRole.replace('{role}', params.role)}?${
    helper.mapToQueryString(params)
  }`;
  return request.get.call({}, URL);
};

export const getInviteAwards = (params: IInviteAwardsReq): Promise<APIResponse<IInviteAwardsRes>> => {
  const URL = `${config.api.host_api_v1}${config.api.activity.inviteAwards}?${
    helper.mapToQueryString(params)
  }`;
  return request.get.call({}, URL);
};

// 奖励记录列表
export const getRewardList = (params: RewardListReq): Promise<APIResponse<IRewardsInfo>> => {
  const URL = config.api.host_newapi + config.api.activity.rewardHistory +
    '?' +
    helper.mapToQueryString(params);
  return request.get.call({}, URL);
}

// 返佣比例
export const getRebateProportion = (): Promise<APIResponse<RebateProportionRes>> => {
  const URL = config.api.host_newapi + config.api.activity.rebateProportion
  return request.get.call({}, URL);
}
