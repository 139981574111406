import config from '@utils/config'

const RECAPTCHA = 'recaptcha';

const loadRecaptcha = (): Promise<{isRevalidate: boolean}| false> => {
  return new Promise((resolve) => {
    let iframe: HTMLIFrameElement = document.querySelector(`[name="${RECAPTCHA}"]`);
    if (iframe) return resolve({ isRevalidate: true });
    iframe = document.createElement('iframe');
    iframe.name = RECAPTCHA;
    iframe.style.display = 'none';
    document.body.append(iframe)
    iframe.src = `${config.api.kiki_website_url}captcha/google-v3.html?version=${Math.floor(Math.random() * 10000)}`;
    iframe.onload = (e) => {
      resolve({ isRevalidate: false });
    }
    iframe.onerror = (e) => {
      resolve(false)
    }
  })
}

const parseData = (data) => {
  try {
    return JSON.parse(data)
  } catch (error) {
    return {}
  }
}

/** 获取谷歌无痕验证分数 */
export const getExecute = () => {
  return new Promise((resolve) => {
    window.addEventListener('message', (e) => {
      const dataStr = e.data;
      if (typeof dataStr !== 'string') return;
      const data = parseData(dataStr);
      if (data.type !== 'google-v3') return;
      if (typeof data.payload !== 'string') return;
      const payload = parseData(data.payload)
      resolve(payload);
    })
    loadRecaptcha().then((res) => {
      if (!res) return resolve({});
      res.isRevalidate && window.frames[RECAPTCHA].postMessage({ type: 'revalidate' }, config.api.kiki_website_url)
    })
  })
}
