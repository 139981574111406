import { EN, ZHCN, ZHTC } from './base';

// 用户协议
export const USER_AGREEMENT = {
  [ZHTC]:
    'https://kikitrade.zendesk.com/hc/zh-tw/articles/4482075461519-Kikitrade-User-Service-Agreement',
  [ZHCN]:
    'https://kikitrade.zendesk.com/hc/zh-tw/articles/4482075461519-Kikitrade-User-Service-Agreement',
  [EN]: 'https://kikitrade.zendesk.com/hc/en-hk/articles/4482075461519-Kikitrade-User-Service-Agreement'
};

// 定投协议
export const AIP_AGREEMENT = {
  [ZHTC]: 'https://kikitrade.zendesk.com/hc/zh-tw/articles/4482075461519-Kikitrade-User-Service-Agreement',
  [ZHCN]: 'https://kikitrade.zendesk.com/hc/zh-tw/articles/4482075461519-Kikitrade-User-Service-Agreement',
  [EN]: 'https://kikitrade.zendesk.com/hc/en-hk/articles/4482075461519-Kikitrade-User-Service-Agreement'
};

// 定期协议
export const FIX_AGREEMENT = {
  [ZHTC]: 'https://kikitrade.zendesk.com/hc/zh-tw/articles/4482075461519-Kikitrade-User-Service-Agreement',
  [ZHCN]: 'https://kikitrade.zendesk.com/hc/zh-tw/articles/4482075461519-Kikitrade-User-Service-Agreement',
  [EN]: 'https://kikitrade.zendesk.com/hc/en-hk/articles/4482075461519-Kikitrade-User-Service-Agreement'
};
