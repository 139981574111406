
import { message } from 'antd'

import { getClient } from '@api/index'
import pubsub from '@lib/scripts/pubsub'
import { getLoginPath, I18n } from '@utils/index';
export const followFetch = async ({
  isLogin, router, item
}) => {
  const { isFollow, customerId } = item;
  if (!isLogin) {
    router.push(getLoginPath())
    return
  }
  const client = getClient()
  const feed = client.feed('user', client.userId);
  try {
    const res: any = await feed[isFollow ? 'unfollow' : 'follow']?.('user', customerId)
    console.log('11111222', res);
    if (res?.result) {
      message.success(I18n.t(isFollow ? 'profile_unfollowed' : 'profile_followed'))
      const newIsFollow = !isFollow
      // setIsFollow(newIsFollow)
      pubsub.publish('FOLLOW_CHANGE', {
        isFollow: newIsFollow,
        toCustomerId: customerId
      })
    } else {
      message.error(I18n.t('common_network_error'))
    }
  } catch (error) {
    message.error(I18n.t('common_network_error'))
  }
}
