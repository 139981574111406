import { BuyingMethodKey } from '@widget/trade/BuyAndSell/constants';

import { I18n } from '..';

export const buyingMethodList = () => {
  const buyingMethodMap = [
    // {
    //   icon: require('@images/icon/buyCoins.webp'),
    //   brief: I18n.t('aiQw5atL'),
    //   mes: I18n.t('J1esD405'),
    //   btnMes: I18n.t('hTNPyWd6'),
    //   key: BuyingMethodKey.Coins
    // },
    {
      icon: require('@images/icon/investMoney.webp'),
      brief: I18n.t('FlLGfYB0'),
      mes: I18n.t('h6M8WT2e'),
      btnMes: I18n.t('depositBtn'),
      key: BuyingMethodKey.Invest
    },
    {
      icon: require('@images/icon/transfer.webp'),
      brief: I18n.t('1V4VEg2t'),
      mes: I18n.t('rSQWCbTO'),
      btnMes: I18n.t('1V4VEg2t'),
      key: BuyingMethodKey.Transfer
    }
  ];
  return buyingMethodMap;
}
