import bugsnag from './bugsnag'
import dayjs from 'dayjs';
import { getAnalytics, logEvent } from 'firebase/analytics';
import _ from 'lodash'
import md5 from './md5';
import { message } from 'antd';
import I18n, { locales } from '@utils/i18n';
import { setCookies } from 'cookies-next';
import { formatPrettyValue } from './formatPrice';
// import { toJS } from 'mobx';

const striptags = require('striptags');
const html2json = require('html2json').html2json;

function toNonExponential (num) {
  try {
    const m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/) || [];
    const _len = (m[1] || '').length - (Number(m[2]))
    return num.toFixed(
      Math.max(0, _len > 20 ? 20 : _len)
    );
  } catch (e) {
    return num.toString()
  }
}

const helper = {
  md5: md5,
  trackEvent: function (event, params) {
    try {
      const analytics = getAnalytics();
      logEvent(analytics, event.replace(/\s/g, '_').replace(/[^\x00-\x7F]/g, ''), params ?? {});
      if (event === 'User Login') {
        logEvent(analytics, 'screen_view', {
          firebase_screen: 'login'
        });
      }
      if (event === 'User Register') {
        logEvent(analytics, 'screen_view', {
          firebase_screen: 'register'
        });
      }
      if (event.startsWith('Page Click') || event.startsWith('NewHome')) {
        logEvent(analytics, 'screen_view', {
          firebase_screen: event.replace(/\s/g, '_'),
          firebase_screen_class: event.replace(/\s/g, '_')
        });
      }
    } catch (e) {
      console.log(e);
      bugsnag.notify(new Error('[Helper]--[trackEvent]--error  : ' + e.toString()));
    }
  },
  /**
   * 每隔三位加逗号
   * @param num
   * @returns {string}
   */
  addCommaToThousands (num) {
    if (!num) {
      return num;
    }
    num = (num || 0);
    const arr = num.toString().split('.');
    num = arr[0];
    const end = arr[1] ? '.' + arr[1] : '';
    let result = '';
    const symbol = num.startsWith('-') ? '-' : '';
    if (num.startsWith('-')) {
      num = num.replace('-', '');
    }
    while (num.length > 3) {
      result = ',' + num.slice(-3) + result;
      num = num.slice(0, num.length - 3);
    }
    if (num) {
      result = num + result;
    }
    return symbol + result + end;
  },
  // 国际话语言切换
  languageSwitch: function (value) {
    // 对切换哪种语言进行了缓存，在浏览器删掉对应的语言后，重新刷新页面，依然打开的是删除之前的语言页面
    setCookies('NEXT_LOCALE', value);
    const pathArr = location.pathname.substr(1, location.pathname.length - 1).split('/')
    let path = location.pathname
    if (locales.indexOf(pathArr[0]) !== -1) {
      pathArr.shift()
      path = pathArr.join('/')
    }
    location.href = location.origin + '/' + value + '/' + path + location.search;
  },
  getLocaleKey: function (key = I18n.locale) {
    let _locale = ''
    switch (key) {
      case 'zh-CN':
        _locale = 'zh'
        break;
      case 'zh-TC':
        _locale = 'hk'
        break;
      case 'en':
      default:
        _locale = 'en'
        break;
    }
    return _locale
  },
  mapToQueryString: function (map) {
    const v = [];
    Object.keys(map).forEach((value, index, array) => {
      if (typeof map[value] !== 'undefined') {
        if (Array.isArray(map[value])) {
          // 兼容array类型参数only 使用后端为spring java的类型
          v.push(value + '=' + map[value].join(','));
        } else {
          const vv = map[value];
          v.push(value + '=' + (vv ?? ''));
        }
      }
    });
    return v.join('&');
  },
  extractMarketData: function (symbols, i) {
    return this.extractMarketDataItem(symbols[i]);
  },
  extractMarketDataItem: function (symbols) {
    symbols = symbols || {}
    return {
      coinCode: symbols.symbol || symbols.coinCode,
      priceLast: Number(symbols.priceLast).toFixed(Number(symbols.symbolPrecision ?? 2)),
      volume: Number(symbols.volume).toFixed(Number(symbols.convertToCoinPrecision ?? 2)),
      riseAndFall: Number(symbols.riseAndFall).toFixed(2),
      highPrice: Number(symbols.highPrice).toFixed(Number(symbols.symbolPrecision ?? 2)),
      lowPrice: Number(symbols.lowPrice).toFixed(Number(symbols.symbolPrecision ?? 2)),
      favorite: symbols.favorite,
      convertToCoinPrecision: symbols.convertToCoinPrecision,
      longPrice: symbols.longPrice,
      shortPrice: symbols.shortPrice,
      precision: symbols.symbolPrecision,
      volumePrecision: symbols.volumePrecision,
      marketPricePrecision: symbols.marketPricePrecision,
      tradePrecision: symbols.tradePrecision,
      tradeCalPrecision: symbols.tradeCalPrecision,
      tradeInputPrecision: symbols.tradeInputPrecision,
      tradeVolumePrecision: symbols.tradeVolumePrecision,
      orderValueMin: symbols.orderValueMin,
      orderValueMax: symbols.orderValueMax,
      orderAmtMin: symbols.orderAmtMin,
      orderAmtMax: symbols.orderAmtMax,
      orderBaseMax: symbols.orderBaseMax,
      orderBaseMin: symbols.orderBaseMin,
      orderQuoteMax: symbols.orderQuoteMax,
      orderQuoteMin: symbols.orderQuoteMin,
      ask: symbols.ask,
      bid: symbols.bid,
      symbol: symbols.symbol || symbols.coinCode,
      priceList: symbols.priceList,
      fiatHedgeLimit: symbols.fiatHedgeLimit,
      spotHedgeLimit: symbols.spotHedgeLimit,
      swapHedgeLimit: symbols.swapHedgeLimit,
      priceLastRise: symbols.priceLastRise
    };
  },
  extractMarketDataList: function (symbols) {
    const that = this;
    return symbols.map((item, index, arrays) => {
      return that.extractMarketData(arrays, index);
    })
  },
  formatFixNumString: function (numOrString, digital) {
    const numberValue = Number(numOrString || 0);
    let numberStr = toNonExponential(numberValue) ?? 0
    if (Number(digital) < 0) {
      digital = 0
    } else if (Number(digital) > 20) {
      digital = 20
    }
    try {
      if (Number(numberStr) === 0) {
        numberStr = Number(numberStr).toFixed(Math.abs(Number(digital ?? 0)))
      } else if (Number(numberStr) < 0.00001 && Number(numberStr) > 0) {
        const str1 = Number(numberStr).toFixed(Math.abs(Number(digital)) + 4)
        numberStr = str1.substr(0, Number(digital) + 2)
      } else if (Math.abs(Number(numberStr)) < 0.00001 && Number(numberStr) < 0) {
        console.log(numberStr)
        numberStr = numberStr + '0000'
        numberStr = numberStr.substr(0, Number(digital) + 3)
      } else {
        // numberStr = '0.' + (Number(numOrString).toString() + '00000000').substr(2, digital)
        const arr1 = Number(numberStr).toString().split('.')

        const splitPointStr = Number(digital) > 0 ? '.' : ''
        if (arr1.length === 1) {
          arr1[1] = '00000000'
        } else {
          arr1[1] += '00000000'
        }
        numberStr = arr1[0] + splitPointStr + arr1[1].substr(0, digital)
      }
    } catch (e) {
      // bugsnag.notify(new Error('[Helper]--[formatFixNumString]--error  : ' + e.toString()));
    }

    return numberStr;
  },
  /**
   * 按照精度修复用户输入的金额，用于输入框金额输入
   * @param numOrString
   * @param digital
   * @returns {string}
   */
  fixNumString: function (numOrString, digital) {
    numOrString = numOrString ?? ''
    const numStr = String(numOrString);
    if (numOrString === '.') {
      return '0.'
    }
    if (numStr.indexOf('.') > 0) {
      // 位数大于0
      if (digital > 0) {
        const re = RegExp('^(\\d+)\\.(\\d{1,' + digital + '}).*$', 'gim');
        // let str = numOrString.replace(/^(-)*(\d+)\.(\d{1,4}).*$/, '$1$2.$3');
        const str = numStr.replace(re, '$1.$2');
        const strArr = str.split('.');
        let ret = Number(strArr[0]) + '';
        if (strArr[0].indexOf('-') > -1 && Number(strArr[0]) === 0) {
          ret = '-' + ret;
        }
        if (strArr.length === 2) {
          ret = ret + '.' + strArr[1] ?? '0'; // 单纯输入10. 的时候， 补0
        }
        return ret;
      } else {
        // 只能输入整数时
        // 单纯输入"10." 的时候去掉小数点
        return numStr.substr(0, numStr.indexOf('.'))
      }
    } else {
      return numStr;
    }
  },
  mapPeriods: (index) => {
    const arr = [
      { title: I18n.t('daily'), desc: I18n.t('dailyAutoInvest') },
      { title: I18n.t('weekly'), desc: I18n.t('weeklyAutoInvest') },
      { title: I18n.t('biWeekly'), desc: I18n.t('biWeeklyAutoInvest') },
      { title: I18n.t('monthly'), desc: I18n.t('monthlyAutoInvest') }
    ];
    return arr[index] || { title: '', desc: '' };
  },
  formatPostData: function (item) {
    const that = this;
    try {
      if (item.videos && item.videos.length) {
        item.isVideo = true;
        item.videoThumbnail =
          (item.videoImages && item.videoImages[0]) ||
          (item.images && item.images[0]);
        item.videoSource = item.videos[0];
        item.width = parseInt(that.getImageWidth(item.videoThumbnail, item.videos ? item.videos[0] : ''));
        item.height = parseInt(that.getImageHeight(item.videoThumbnail, item.videos ? item.videos[0] : ''));
      }

      if (item.images && item.images.length === 1) {
        item.image_width = that.getImageWidth(item.images[0]);
        item.image_height = that.getImageHeight(item.images[0], '');
      }

      return item;
    } catch (e) {
      bugsnag.notify(new Error('[Helper]--[formatPostData]--error  : ' + e.toString()));
      console.log(e);
      return item;
    }
  },
  getImageWidth: function (imgurl, videoUrl) {
    try {
      const url = (imgurl ?? '').match(/w:\d+/) || videoUrl.match(/w:\d+/);
      return url ? Number(url[0].split(':')[1]) : 0;
    } catch (e) {
      return 0;
    }
  },
  getImageHeight: function (imgurl, videoUrl) {
    try {
      const url = (imgurl ?? '').match(/h:\d+/) || (videoUrl ?? '').match(/h:\d+/);
      return url ? Number(url[0].split(':')[1]) : 0;
    } catch (e) {
      bugsnag.notify(new Error('[Helper]--[getImageHeight]--error  : ' + e.toString()));
      return 0;
    }
  },

  isHtmlTag: function (text) {
    const reg = /<[^>]+>/g;
    return reg.test(text || '');
  },
  replaceHtmlEntity: function (str) {
    const s = str;
    const translateRe = /&(nbsp|amp|quot|lt|gt);/g;
    const translate = { nbsp: ' ', amp: '&', quot: '"', lt: '<', gt: '>' };
    return (s.replace(translateRe, function (match, entity) {
      return translate[entity];
    }));
  },
  identificationSwitch: (statusType) => {
    const type = `role${statusType}`;
    const status = {
      role2: require('../images/identification_kol.webp'),
      role3: require('../images/identification_media.webp'),
      role4: require('../images/identification_organization.webp')
    };
    return type ? status[type] : 0;
  },
  toLocaleTime: function (time, format, utcFormat) {
    format = format || 'YYYY-MM-DD HH:mm:ss';
    if (utcFormat) {
      return dayjs
        .utc(time.toString(), utcFormat)
        .local()
        .format(format);
    } else {
      return dayjs
        .utc(time)
        .local()
        .format(format);
    }
  },
  createUUID: function () {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },
  parseHTMLString: function (htmlString) {
    try {
      const htmlStringFromat = htmlString.replace(/<\/span><div>|<\/div><div>|<div>/g, '\n'); // 处理块级元素带来的换行问题
      // let stripHTMLs = striptags(htmlStringFromat, ['img', 'br', 'source', 'video', 'mark', 'a'])
      let stripHTMLs = striptags(htmlStringFromat, ['img', 'br', 'source', 'video'])
      stripHTMLs = stripHTMLs.replace(/<br[^>]*>/g, '\n')
      stripHTMLs = stripHTMLs.replace(/^\n/, '')
      // 为了避免html解析异常，除了'img', 'br', 'source', 'video'这四种标签的'<'不做处理，其余的'<'全部替换成'&lt;'
      stripHTMLs = stripHTMLs.replace(/<(?!img)(?!br)(?!source)(?!video)(?!\/img)(?!\/br)(?!\/source)(?!\/video)/g, '&lt;')
      return html2json(stripHTMLs)
    } catch (e) {
      bugsnag.notify(new Error('[Helper] -- [parseHTMLString] -- [error]: ' + e.toString()));
      return { child: [] };
    }
  },
  setTitleAndText: function (feeds) {
    feeds.forEach((itemData, index) => {
      const title = itemData.title ?? ''
      let { images, text } = itemData;

      const imgLen = images && images.length;
      const REGEX = /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/g;
      const url = text && text.match(REGEX) && text.match(REGEX)[0];
      if (itemData?.subVerb === 12 || this.isHtmlTag(text)) {
        try {
          // const itemDataObj = JSON.parse(itemData.text)
          let striphtmls = striptags(itemData.text, ['img', 'br', 'source', 'video'])
          striphtmls = striphtmls.replace(/<br[^>]*>/g, '\n')
          striphtmls = striphtmls.replace(/<(?!img)(?!br)(?!source)(?!video)/g, '&lt;')
          const itemDataObj = html2json(striphtmls)
          const textArr = _.filter(itemDataObj?.child || [], function (item) { return item.node === 'text' && ((item.text || '').replace(/\n/g, '')).trim() !== '' })
          text = textArr[0]?.text || ''
          // title = itemData.title ?? ''
          text = this.replaceHtmlEntity(text);

          // NUM_OF_LINES = 4
        } catch (e) {
          bugsnag.notify(new Error('[components-CellListOne] -- [headerItem] -- [error]: ' + e.toString()));
        }
      }

      // TODO
      if (!imgLen && url && (url.indexOf('youtube.com') > 0 || url.indexOf('youtu.be') > 0)) {
        // showLinkPreview = true;
        text = text.replace(REGEX, '');
        text = text.trim();
      }

      feeds[index].text = text
      feeds[index].title = title
      feeds[index] = this.formatPostData(feeds[index])
    })

    return feeds
  },

  subStrNum: function (str, len) {
    str = str ?? ''
    const strLen = str.length;
    let strCut = '';
    let strLength = 0;
    for (let i = 0; i < strLen; i++) {
      let charStr = str.charAt(i); // 使用charAt获取单个字符；
      strLength++
      try {
        if (encodeURI(charStr).length > 4) { // 使用encodeURI获取编码长度
          strLength++;
        }
      } catch (e) {
        bugsnag.notify(new Error('[Helper]--[subStrNum]--error  : ' + e.toString()));

        charStr = ''
      }

      strCut = strCut.concat(charStr);// 单个字符进行合并
      if (strLength > len) {
        strCut = strCut.concat('...') // 大于指定长度后合并'...'并返回此字符串
        return strCut;
      }
    }
    if (strLength < len) {
      return str
    }
    // default return raw string
    return str;
  },

  hiddenMobileText: function (mobile, telephoneCode) {
    const text = mobile;
    if (!mobile) {
      return mobile;
    }

    return telephoneCode + text.substring(0, 3) + '****' + text.substring(7);
  },

  hiddenEmailText: function (email) {
    let text = email;
    if (!email) {
      return text;
    }
    const emailArray = email.split('@');
    const len = emailArray.length;
    const prefix = emailArray[0];
    if (prefix.length > 4) {
      text =
        prefix.substring(0, 2) +
        '****' +
        '@';
      for (let i = 1; i < len; i++) {
        text += emailArray[i];
      }
    }
    return text;
  },

  isNormalContactUs: function (countryCode) {
    // 常规的联系中文客服，而非英文表单
    //     CHINA("CN", "ZH", "HK"),
    //     HONGKONG("HK", "HK", "HK"),
    //     TAIWAN("TW", "HK", "HK"),
    //     MACAO("MO", "HK", "HK"),
    //     OTHER("US", "EN", "EN"),MALAYSIA("MY", "EN", "EN"),,
    const normalContactUsCode = ['CN', 'ZH', 'HK', 'TW', 'MO', 'zh_CN'].map((item) => item.toUpperCase());
    return normalContactUsCode.includes((countryCode || '').toUpperCase());
  },

  handleErrorMsg: function (msgKey) {
    const msg = msgKey || 'system.error'
    message.error(I18n.t(msg), 2);
  },
  formatKycStatus: function (status) {
    let text = '';
    let iconName = ''
    const kycStatus = status + 1;
    let color = '#696F7F';
    let bgColor = 'rgba(105, 111, 127, 0.1)';

    switch (kycStatus) {
      case 1:
        text = I18n.t('kyc_unverified');
        color = '#696F7F';
        bgColor = 'rgba(105, 111, 127, 0.1)';
        iconName = 'kycUnverified';
        break;
      case 2:
        text = I18n.t('kyc_verifying');
        color = '#FFC241';
        bgColor = 'rgba(255, 194, 65, 0.1)';
        iconName = 'kycVerifying';
        break;
      case 3:
        text = I18n.t('kyc_verified');
        color = '#1EAE45';
        bgColor = 'rgba(30, 174, 69, 0.1)';
        iconName = 'kycVerified';
        break;
      case 4:
        text = I18n.t('kyc_rejected');
        color = '#ED220D';
        bgColor = 'rgba(237, 34, 13, 0.1)';
        iconName = 'kycRejected';
        break;
    }

    const data = {
      text,
      color,
      bgColor,
      iconName
    };

    return data;
  },
  formatLongText: function (text, start = 5, end = -5) {
    let res = text;
    if (text?.length > 10) {
      res = text.substring(0, start) + '...' + text.substr(end)
    }
    return res;
  },
  getBusinessNameByType: function (
    businessType,
    isKol = false,
    operateType = 0
  ) {
    let name = I18n.t('common_unknown');
    switch (businessType) {
      case 1:
        name = I18n.t('asset_withdrawal');
        break;
      case 2:
        name = I18n.t('asset_deposit');
        break;
      case 3:
        name = I18n.t('asset_exchange');
        break;
      case 5:
        name = I18n.t('asset_reward');
        break;
      case 8:
        if (operateType === 6) {
          name = I18n.t('asset_transfer_in');
        } else if (operateType === 5) {
          name = I18n.t('asset_transfer_out');
        } else {
          name = I18n.t('asset_transfer');
        }
        break;
      case 10:
        name = I18n.t('flexEarning_transferIn');
        break;
      case 12:
        name = I18n.t('asset_interest_type');
        break;
      case 14:
        name = I18n.t('asset_trial_bonus_interest');
        break;
      case 15:
        name = I18n.t('flexEarning_transferOut');
        break;

      case 16:
        name = I18n.t('asset_open_position');
        break;
      case 18:
        name = I18n.t('asset_cancel_order');
        break;
      case 19:
        name = I18n.t('asset_adjust_margin');
        break;
      case 20:
        name = I18n.t('asset_close_position');
        break;
      case 21:
        name = I18n.t('asset_start_follow');
        break;
      case 22:
        name = isKol === true
          ? I18n.t('asset_performance_share')
          : I18n.t('asset_close_follow');
        break;
      case 23:
        name = I18n.t('member_buy');
        break;
      case 24:
        name = I18n.t('asset_spot_trade');
        break;
      case 25:
        name = I18n.t('depositFiat');
        break;
      case 26:
        name = I18n.t('withdrawFiat');
        break;
      case 27:
        name = I18n.t('asset_reward');
        break;
      case 30:
        name = I18n.t('activity_purchase_card');
        break;
      case 32:
        name = I18n.t('marginTrading');
        break;
      case 33:
        name = I18n.t('loanInterest');
        break;
      case 35:
        name = I18n.t('activity_gift_exchange');
        break;
      case 37:
        name = I18n.t('fixed_investment_dispense');
        break;
      case 38:
        name = I18n.t('earnings_locked_record_subscribe');
        break;
      case 39:
        name = I18n.t('earnings_locked_record_cancel');
        break;
      case 41:
        name = I18n.t('earnings_locked_record_redeem');
        break;
      case 93:
        name = I18n.t('c2c_order_fee');
        break;
      case 94:
        name = I18n.t('c2c_transfer_in');
        break;
      case 95:
        name = I18n.t('c2c_transfer_out');
        break;
      case 100:
        name = I18n.t('airdrop_name');
        break;
    }
    return name;
  },
  isIncomingByOperateType: function (operateType) {
    let inComing = true;
    switch (operateType) {
      case 1: // 冻结
        inComing = false;
        break;
      case 2: // 解冻
        inComing = true;
        break;
      case 3: // 冻结并扣减
        inComing = false;
        break;
      case 4: // 加钱
        inComing = true;
        break;
      case 5: // 转出
        inComing = false;
        break;
      case 6: // 转入
        inComing = true;
        break;
    }
    return inComing;
  },
  getTransactionStatusDesc1: function (status) {
    let desc = I18n.t('common_unknown');
    switch (status) {
      case 'WITHDRAW_APPLY':
        desc = I18n.t('asset_withdrawal_status_apply');
        break;
      case 'WITHDRAW_CANCELED':
        desc = I18n.t('asset_withdrawal_status_cancel');
        break;
      case 'WITHDRAW_APPROVED':
        desc = I18n.t('asset_withdrawal_status_approval');
        break;
      case 'WITHDRAW_REJECTED':
        desc = I18n.t('asset_withdrawal_status_unapproval');
        break;
      case 'WITHDRAW_COMPLETED':
        desc = I18n.t('asset_complete');
        break;
      case 'WITHDRAW_FAILED':
        desc = I18n.t('asset_failed');
        break;
      case 'DEPOSIT_INITIAL':
      case 'DEPOSIT_APPLY':
        desc = I18n.t('asset_pending');
        break;
      case 'DEPOSIT_FINISHED':
        desc = I18n.t('asset_complete');
        break;
      case 'DEPOSIT_FAILED':
        desc = I18n.t('asset_failed');
        break;
      default:
        break;
    }
    return desc;
  },
  mergeAccount: function (accountList, assetAccountList) {
    const itemList = [];
    if (accountList && accountList.length > 0) {
      // TODO if (account.category === this.props.type){
      accountList.forEach((account) => {
        itemList.push(account);
      });
    }
    if (assetAccountList && assetAccountList.length > 0) {
      assetAccountList.forEach((account) => {
        itemList.push(account);
      });
    }
    // sort
    itemList.sort(function (a, b) {
      if (a.sort < b.sort) {
        return -1;
      } else if (a.sort > b.sort) {
        return 1;
      } else {
        return a.coinCode.localeCompare(b.coinCode);
      }
    });
    return itemList;
  },

  stopPropagation: function stopPropagation (e) {
    e = e || window.event;
    if (e.stopPropagation) { // W3C阻止冒泡方法
      e.stopPropagation();
    } else {
      e.cancelBubble = true; // IE阻止冒泡方法
    }
  },
  getCoinDetail: function (coinCode, marketItems) {
    const detail = marketItems.find((item, index, array) => {
      if (item.coinCode === coinCode) {
        return true
      }
      return false;
    });
    return detail;
  },

  formatVolume (num, format, fixedPrecision = 2) {
    if (!num) {
      return num;
    }
    num = (num || 0);
    const arr = num.toString().split('.');
    num = arr[0];
    let end = arr[1] ? '.' + arr[1] : '';
    let result = '';
    const symbol = num.startsWith('-') ? '-' : '';
    if (num.startsWith('-')) {
      num = num.replace('-', '');
    }
    if (Number(num) < 1000) {
      while (num.length > 3) {
        result = ',' + num.slice(-3) + result;
        num = num.slice(0, num.length - 3);
      }
      if (num) {
        result = num + result;
      }
    } else if (Number(num) < 1000000) {
      result = (Number(num) / 1000).toFixed(fixedPrecision)
      end = 'K'
    } else if (Number(num) < 1000000000) {
      result = (Number(num) / 1000000).toFixed(fixedPrecision)
      end = 'M'
    } else {
      result = (Number(num) / 1000000000).toFixed(fixedPrecision)
      end = 'B'
    }

    return symbol + (format ? formatPrettyValue(result) : result) + end;
  },

  makeid (length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  },

  dataURLtoBlob (dataurl, blobName) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const blob = new Blob([u8arr], { type: mime })
    blob.name = blobName
    let fileUrl = '';
    window.URL.revokeObjectURL(fileUrl);
    fileUrl = window.URL.createObjectURL(blob);
    return fileUrl;
  },

  getCoinPic (marketCoins, coinCode) {
    return marketCoins.find((i) => i.coinCode === coinCode)?.picturePath;
  },

  // 倒计时
  countDown (endTimeStamp) {
    const nowTimeStamp = new Date().getTime();
    let time = { day: 0, hour: 0, minute: 0 };
    const daySecond = 1000 * 60 * 60 * 24;
    const hourSecond = 1000 * 60 * 60;
    const minuteSecond = 1000 * 60;
    if (endTimeStamp > nowTimeStamp) {
      const mss = endTimeStamp - nowTimeStamp;
      const days = parseInt(mss / daySecond);
      const hours = parseInt((mss % daySecond) / hourSecond);
      const minutes = parseInt((mss % hourSecond) / minuteSecond);
      const seconds = parseInt((mss % minuteSecond) / 1000);
      time = {
        day: days < 10 ? '0' + days : days,
        hour: hours < 10 ? '0' + hours : hours,
        minute: minutes < 10 ? '0' + minutes : minutes,
        second: seconds < 10 ? '0' + seconds : seconds
        // mss: mss
      };
    } else {
      time = {
        day: '00',
        hour: '00',
        minute: '00',
        second: '00'
        // mss: '00'
      };
    }
    return time;
  },
  getNextInvestmentTime: function (period) {
    let str = '';
    switch (parseInt(period)) {
      case 0:
        str = dayjs().add(1, 'day').format('YYYY-MM-DD');
        break;
      case 1:
        str = dayjs().startOf('isoWeek').add(1, 'week').format('YYYY-MM-DD');
        break;
      case 2:
        if (new Date().getDate() < 15) {
          str = dayjs().startOf('month').add(14, 'day').format('YYYY-MM-DD');
        } else {
          str = dayjs().startOf('month').add(1, 'month').format('YYYY-MM-DD');
        }
        break;
      case 3:
        str = dayjs().startOf('month').add(1, 'month').format('YYYY-MM-DD');
        break;
    }
    return str;
  },
  getOperateType: function (key, type) {
    let name = '';
    if (type === 'flex') {
      // 1=申购,2=申购费,5=赎回,6=赎回费,7=撤回，8=计息,
      switch (key) {
        case 1:
          name = I18n.t('purchase');
          break;
        case 2:
          name = I18n.t('purchaseFee1');
          break;
        case 5:
          name = I18n.t('transferOut');
          break;
        case 6:
          name = I18n.t('transferOutFee');
          break;
        case 7:
          name = I18n.t('withdraw1');
          break;
        case 8:
          name = I18n.t('interestDistribution');
          break;
      }
    } else {
      // 0=认购，即扣钱到代理户,1=分发，即扣钱到代理户
      switch (key) {
        case 0:
          name = I18n.t('regularInvestment');
          break;
        case 1:
          name = I18n.t('0ZSVRkhD');
          break;
      }
    }

    return name;
  },
  mapingEarningsStatus: (key) => {
    const arr = {
      status1: I18n.t('start'),
      status2: I18n.t('processing'),
      status3: I18n.t('success'),
      status11: I18n.t('asset_failed')
    };
    return arr[key];
  },
  wapperSessionStorage: (key) => {
    return {
      setItem: (value) => {
        sessionStorage.setItem(key, value)
      },
      removeItem: () => {
        sessionStorage.removeItem(key)
      },
      getItem: () => {
        return sessionStorage.getItem(key)
      }
    }
  }

};

module.exports = helper;

export default helper;
