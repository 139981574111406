// import { EN } from '@constants/base';
import config from '../utils/config';
import { getLanguageZone } from '../utils/getLanguageZone';
import helper from '../utils/helper';
import request from '../utils/request';
// let jwtKey;
// todo
// subscribe('HomeReducer', (state: StateType) => {
//     const { HomeReducer } = state;
//     jwtKey = HomeReducer.userInfo?.jwtKey ?? '';
// });

// class definition
class SocialApi {
  appendParamZone (params) {
    if (params && params.zone) {
      return params;
    }
    return {
      ...params,
      zone: getLanguageZone()
    }
  };

  getHotTopic (params) {
    const URL = config.api.host + config.api.social.hotTopic +
      '?' +
      helper.mapToQueryString(params ?? {});
    return request.get.call({}, URL);
  };

  gettopicCategory (params) {
    const URL = `${config.api.host}${config.api.social.topicCategory}?${helper.mapToQueryString(params ?? {})}`;
    return request.get.call({}, URL);
  };

  // 获取所有社区推荐
  getTopicAll (params) {
    const URL =
      config.api.host +
      config.api.social.topicAll +
      '?' +
      helper.mapToQueryString(params ?? {});
    return request.get.call({}, URL);
  };

  // 获取热门人物
  getHotUsers (params, opts) {
    const URL = config.api.host + config.api.social.hotUsers +
      '?' +
      helper.mapToQueryString(params);
    return request.get.call({}, URL, opts);
  };

  // 获取热门话题详情
  getHotTopicDetial (params, opts) {
    console.log('line56', params);
    const URL = `${config.api.host}${config.api.social.topicDetail.replace('{topicId}', params.topicId)}?${helper.mapToQueryString(params)}`;
    return request.get.call({}, URL, opts);
  };

  // 获取话题相关feed
  topicPostFeed (params, opts) {
    // type: hot| newest
    const URL = `${config.api.host}${config.api.social.topicPostFeed.replace('{topicId}', params.topicId)}/${params.type}?${helper.mapToQueryString(params)}`;
    return request.get.call({}, URL, opts);
  };

  getRecommendStory (params) {
    const URL = `${config.api.host}${config.api.social.storyRecommend}?${helper.mapToQueryString(params)}`;
    return request.get.call({}, URL);
  };

  getSquareStory (params, opts) {
    const URL = `${config.api.host}${config.api.social.storySquare}?${helper.mapToQueryString(params)}`
    return request.get.call({}, URL, opts);
  };

  getNextPost (params, opts) {
    const URL = `${config.api.host}${config.api.social.nextPost}?${helper.mapToQueryString(params)}`
    return request.get.call({}, URL, opts);
  };

  /** 切换中英文社区 */
  updateCountryLanguageZone (params) {
    const URL = `${config.api.host}${config.api.social.updateLanguageZone.replace('{id}', params.id)}?${helper.mapToQueryString(params)}`;
    return request.post.call({}, URL);
  };
}

const SocialAPI = new SocialApi();
export { SocialAPI };
