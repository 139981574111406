import * as d3 from 'd3';
export const buildSvgPath = (priceData, svgSize) => {
  const { width, height } = svgSize;

  const scaleLinear = d3.scaleLinear;
  priceData = priceData || []
  const scaleY = scaleLinear()
    .domain([Math.min(...priceData) * 0.95, Math.max(...priceData) * 1.05])
    .range([height, 0]);
  const scaleX = scaleLinear()
    .domain([0, priceData.length - 1])
    .range([0, width]);
  const formattedValues = priceData.map((price, index) => [index, price]);
  const pathline = d3
    .line()
    .x(([x]) => scaleX(x))
    .y(([, y]) => scaleY(y))
    .curve(d3.curveNatural)(formattedValues) || '';
  const patharea = d3
    .area()
    .x(([x]) => scaleX(x))
    .y0(height)
    .y1(([, y]) => scaleY(y))
    .curve(d3.curveNatural)(formattedValues) || '';
  return {
    pathline,
    patharea
  }
}
