
import config from '../utils/config';
import helper from '../utils/helper';
import request from '../utils/request';

/**
 * 显示自选盘口的价格等信息
 * 不需要入参，但是需要用户先登录
 */
export const favoriteList = () => {
  const URL = `${config.api.host_api_v1}${config.api.favoriteCustom.favoriteList}`;
  return request.get.call({}, URL);
};

/**
 * 用户单个/批量删除已加入自选的盘口
 * @param {*} params.favorite_type string 取值为：symbol - 交易对, zone -
 * @param {*} params.items string param=symbol时：传入待删除的盘口, 多个的话用英文逗号隔开；param=zone时：传入待删除的板块的categoryKey，多个的话用英文逗号隔开；
 * @returns
 */
export const favoriteDelete = (params) => {
  const URL = `${config.api.host_api_v1}${
    config.api.favoriteCustom.favoriteDelete
  }?${helper.mapToQueryString(params)}`;
  return request.delete.call({}, URL);
};

/**
 * 用户添加自选盘口，支持一次性添加一个或多个
 * @param {*} params.favorite_type string 取值为：symbol - 交易对, zone -
 * @param {*} params.items string param=symbol时：传入待删除的盘口, 多个的话用英文逗号隔开；param=zone时：传入待删除的板块的categoryKey，多个的话用英文逗号隔开；
 * @returns
 */
export const favoriteAdd = (params) => {
  const URL = `${config.api.host_api_v1}${
    config.api.favoriteCustom.favoriteAdd
  }?${helper.mapToQueryString(params)}`;
  return request.post.call(params, URL);
};

export const updateFavorite = (params) => {
  const URL =
    config.api.host_api_v1 +
    config.api.favoriteCustom.updateFavorite +
    '?' +
    helper.mapToQueryString(params);
  return request.post.call(params, URL);
};

export const favoriteSort = (params) => {
  const URL =
    config.api.host_api_v1 +
    config.api.favoriteCustom.favoriteSort +
    '?' +
    helper.mapToQueryString(params);
  return request.post.call(params, URL);
};
