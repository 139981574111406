import { useState } from 'react';

import { useIsomorphicLayoutEffect } from '@lib/hooks';
import { getRemoteConfigValue } from '@utils/firebase';

export function useRemoteConfigValue<T> (remoteKey: string): T[] {
  const [remoteSet, setRemoteSet] = useState<T[]>([]);
  useIsomorphicLayoutEffect(() => {
    if (remoteKey) {
      getRemoteConfigValue(remoteKey).then((res) => {
        try {
          setRemoteSet(JSON.parse(res.asString()));
        } catch (error) {}
      });
    }
  }, []);

  return remoteSet;
}
