import dayjs from 'dayjs';

import helper from '@utils/helper'

import { I18n } from '..';
const getLocaleKey = helper.getLocaleKey

export const xLabelFormat = (value) => {
  const localkey = getLocaleKey();
  if (localkey === 'en') {
    return dayjs(value).locale('en').format('DD MMM')
  }
  return dayjs(value).format('MM-DD')
}

export const formatterLabel = (value) => {
  return I18n.t(`web3_legend_${value}`)
}

export const tooltipTitleFormat = (value) => {
  const localkey = getLocaleKey();
  if (localkey === 'en') {
    return dayjs(value).locale('en').format('DD MMM YYYY')
  }
  return dayjs(value).format('YYYY-MM-DD')
}
