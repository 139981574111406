import { I18n } from '@utils/index';

type GetGenderRes = {
  /* 0 女 1 男 2 保密 */
  [key in '1' | '2' | '0']: {
    name: string
  }
}

export const getGender = (): GetGenderRes => {
  return {
    0: {
      name: I18n.t('user_female')
    },
    1: {
      name: I18n.t('user_male')
    },
    2: {
      name: I18n.t('profileInfo_gender_unknow')
    }
  }
}
