import { Node as Logtail } from '@logtail/js';

const env = process.env.NEXT_PUBLIC_API_ENV;

const logtail = new Logtail('osTEPTVorGPmWByYzdSQLfmC');

// export const logger = ['local', 'dev'].includes(env) ? {} : logtail;
export const logger = {
  error: (type, data) => {
    if (['local', 'dev'].includes(env)) return
    logtail.error(type, data)
  },
  info: (type, data) => {
    if (['local', 'dev'].includes(env)) return
    let userId = ''
    try {
      userId = JSON.parse(localStorage.getItem('userStore')).userInfo.id || ''
    } catch (e) {
    }
    logtail.info(type + userId, { ...data, userId })
  }
};
