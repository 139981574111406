import config from '../utils/config';
import request from '../utils/request';
// import helper from '../utils/helper';

class ExploreDataApi {
  getLongShortList (params) {
    const URL =
      config.api.host +
      config.api.explore.getLongShort + '?symbol=' + params.symbol + '&interval=' + params.interval + '&exchange=' + params.exchange
    // console.log(params)
    // console.log(URL)
    return request.get.call(params, URL);
  };

  getDataList () {
    const URL =
      config.api.host +
      config.api.explore.getList
    return request.get.call({}, URL);
  };

  getLiquidation () {
    const URL =
      config.api.host +
      config.api.explore.getLiquidation
    return request.get.call({}, URL);
  };
}

const ExploreDataAPI = new ExploreDataApi();
export { ExploreDataAPI };
