import { WALLET_PATHS } from '@constants/paths';
import pathnameEum from '@utils/pathname'

import { I18n } from '..';

export const actions = () => {
  const actionBtns = [
    {
      desc: I18n.t('deposit'),
      pathname: pathnameEum.deposit,
      type: 'normal'
    },
    {
      desc: I18n.t('withdrawal'),
      pathname: pathnameEum.withdrawal,
      type: 'normal'
    },
    {
      desc: I18n.t('1V4VEg2t'),
      pathname: WALLET_PATHS.TRANSFER.replace('[coin]', 'USDT'),
      type: 'main'
    }
  ]
  return actionBtns
}
