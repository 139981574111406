import { getRemoteConfigValue } from '@utils/firebase'

const usdtHkdRate = async () => {
  const remoteConfigValue = await getRemoteConfigValue('rate_settings')
  try {
    const groupValue = remoteConfigValue.asString()
    const config = JSON.parse(groupValue)
    return config?.USDT_HKD ?? 1
  } catch (e) {
    console.log(e.toString())
    return 1
  }
}
export default usdtHkdRate
