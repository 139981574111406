
import { AuthCoreContextProvider, AuthCoreModalOptions } from '@particle-network/auth-core-modal';
import { env }  from '../constants/env'
const authCoreOptions: AuthCoreModalOptions = {
  projectId: env.REACT_APP_PROJECT_ID,
  clientKey: env.REACT_APP_CLIENT_KEY,
  appId: env.REACT_APP_APP_ID,
  themeType: 'light',
  language: 'en',
  promptSettingConfig: {
    promptPaymentPasswordSettingWhenSign: 2,
    promptMasterPasswordSettingWhenLogin: 2
  },
  customStyle: {
    fontFamily: '"SF-Pro", "PingFang SC", "Helvetica Neue", "Helvetica", "Arial", sans-serif'
  },
  wallet: {
    visible: process.env.NODE_ENV === 'development',
    preload: true
  }
}
export function ParticleAuthCoreContextProvider({children}) {
  return (
    
      <AuthCoreContextProvider options={authCoreOptions}>
        {children}
      </AuthCoreContextProvider>
    )
}