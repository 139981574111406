import { Ethereum, BNBChain, zkSyncEra, Linea, Mantle, Polygon, BNBChainTestnet, EthereumGoerli } from '@particle-network/chains';
import {
    ParticleConnect,
    Provider,
    isEVMProvider,
    metaMask,
    walletconnect,
} from '@particle-network/connect';

import { chainids } from '../constants/chainids'
import { hexer }  from '../util/hexer'
import { env }  from '../constants/env'
import { SupportChains as supportChains }  from '../constants/supportChains'



export class ParticleService<T> {
  private connectKit: ParticleConnect | undefined;
  private connectProvider: Provider | undefined;

  constructor(private name: string) {
  }

  public async init( options: T ) {
    this.connectKit =  new ParticleConnect({
        projectId: env.REACT_APP_PROJECT_ID as string,
        clientKey: env.REACT_APP_CLIENT_KEY as string,
        appId: env.REACT_APP_APP_ID as string,
        chains: supportChains,
        particleWalletEntry: {    //optional: particle wallet config
            displayWalletEntry: false, //display wallet button when connect particle success.
            supportChains,
            customStyle: {}, //optional: custom wallet style
        },

        wallets: [
            metaMask({ projectId: env.REACT_APP_WALLETCONNECT_PROJECT_ID }),
            walletconnect({ projectId: env.REACT_APP_WALLETCONNECT_PROJECT_ID }),
        ]
    });

    await this.initCacheProvider()

  }

  private async initCacheProvider() {
    const id = this.connectKit?.cachedProviderId();
    if (id) {
      this.connectProvider=  await this.connectKit?.connectToCachedProvider()
    } else {
      
      this.connectProvider = undefined
    }
  }

  public setAuthTheme( options: T ) {
    this.connectKit?.particle.setAuthTheme({
      uiMode: "light",
      displayCloseButton: true,
      displayWallet: true, // display wallet entrance when send transaction.
      modalBorderRadius: 10, // auth & wallet modal border radius. default 10.
    });
  }

  public setLanguage( locale: string = 'en') {
    this.connectKit?.particle.setLanguage(locale);
  }

  public async connectWallet(id: string, options?: any) {
    try{
      if(this.connectProvider){
        await this.disconnectWallet()
      }
      if(id === 'metamask' && window.ethereum && window.ethereum._metamask){
        const isUnLocked = await window.ethereum._metamask.isUnlocked()
        if(!isUnLocked){

          await   window.ethereum.request({ method: "wallet_requestPermissions",params: [{ eth_accounts: {} }] });
        }
      }
      const connectProvider = await this.connectKit?.connect(id, options);
      this.connectProvider = connectProvider 
    }catch(e){
      console.error(e)
      return {
        error: e.message === "[object Object]" ? 'Please Open your metamask extention to approve permission!' : e.message
      }
    }
  }
  public async disconnectWallet ()  {
    try {
        await this.connectKit?.disconnect({ hideLoading: true });
    } catch (error) {
        console.error(error);
    }
    this.connectProvider = undefined
  }

  public async getWalletAddress() {
    if(this.connectProvider && isEVMProvider(this.connectProvider)) {
      const address = await this.connectProvider?.request({ method: 'eth_accounts' });
      return address[0]
    } else {
      const account = await this.connectKit?.particle.evm.getAddress()
      return account
    }

  }

  public async getWalletChainName() {
    if(this.connectProvider && isEVMProvider(this.connectProvider)) {
      const hex = await this.connectProvider?.request({ method: 'eth_chainId' });
      const decimal = parseInt(hex, 16)
      const name = chainids[decimal] || 'evm'
      return name
    } else {
      return 'evm'
    }


  }

  public async getWalletSignture(params, address) {
    // console.error('this.connectProvider', this.connectProvider)
    if(this.connectProvider && isEVMProvider(this.connectProvider)) {
      const challenge = hexer(params)
      const sign = await this.connectProvider?.request({ method: 'personal_sign', params: [challenge, address] });
      return sign
    } 

  }

  public getWalletUrl() {
    //open wallet in custom iframe.
    const url = this.connectKit?.particle.buildWalletUrl({
        //optional: left top menu style, close or fullscreen
        //"fullscreen": wallet will be fullscreen when user click.
        //"close": developer need handle click event
        topMenuType: "close"   
    });
    return url
  }
}
