
const api = {
  host: 'https://webprod.kikitrade.com/pro_api/',
  host_newapi: 'https://webprod.kikitrade.com/pro_newapi/',
  host_api_v1: 'https://webprod.kikitrade.com/pro_newapi/',
  supportUrl: 'https://cschat.antcloud.com.cn/index.htm?tntInstId=1Ab_9Aku&scene=SCE00941738',
  kiki_website_url: 'https://webprod.kikitrade.com/new/locale/',
  share: 'https://share.kikitrade.com/',
  upload_api: 'https://upload.kikitrade.com/',
  get_short_link_url: 'https://kiki.trade'
};

export default api;
