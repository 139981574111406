import config from '@utils/config';
import { helper } from '@utils/index';
import request from '@utils/request';

import {
  MerchantConfigType,
  OrderListItemType,
  OrderListParamsItemType, P2POrderListParamsItemType,
  P2PTransferListParamsItemType,
  TransferAccountParamsType,
  TransferListItemType
} from './c2c.type'

export const merchantConfig = (): Promise<APIResponse<MerchantConfigType>> => {
  // 查询买卖币种信息
  const URL = config.api.host_newapi + config.api.c2c.merchantConfig;
  return request.get.call({}, URL);
};

// eslint-disable-next-line no-undef
export const p2pOrderList = (params: P2POrderListParamsItemType): Promise<APIResponse<TableType<OrderListItemType>>> => {
  // C2C账户资产详情页面订单列表
  console.log('1111')
  const URL = config.api.host_newapi + config.api.c2c.p2pOrderList + '?' + helper.mapToQueryString(params);
  return request.get.call({}, URL);
};

export const orderList = (params: OrderListParamsItemType): Promise<APIResponse<OrderListItemType[]>> => {
  // 商户查看广告关联的订单/订单历史
  const URL = config.api.host_newapi + config.api.c2c.orderList + '?' + helper.mapToQueryString(params);
  return request.get.call({}, URL);
};

// eslint-disable-next-line no-undef
export const p2pTransferList = (params: P2PTransferListParamsItemType): Promise<APIResponse<TableType<TransferListItemType>>> => {
  // C2C账户资产详情页面划转记录
  const URL = config.api.host_newapi + config.api.c2c.p2pTransferList + '?' + helper.mapToQueryString(params);
  return request.get.call({}, URL);
};

export const transfer = (params: TransferAccountParamsType): Promise<APIResponse> => {
  // 交易账户和C2C相互划转
  const URL = config.api.host_newapi + config.api.c2c.transfer + '?' + helper.mapToQueryString(params);
  return request.post.call(params, URL);
};
