import React, { useRef, useState } from 'react';

import cn from 'classnames';

import { THEME } from '@constants/index';
import { useIsomorphicLayoutEffect } from '@lib/hooks';
import I18n from '@utils/i18n';

import styles from './index.module.less';

const COUNTDOWN = 60;

interface CountDownProps {
  initTime?: number;
  onFinish?: () => void;
  type?: string;
  countdown?: boolean;
  theme?: Theme;
}

export const CountDown: React.FC<CountDownProps> = ({
  initTime,
  onFinish,
  type = '1',
  countdown,
  theme
}) => {
  const [countDown, setCountDown] = useState(initTime ?? COUNTDOWN);
  const countRef = useRef(initTime ?? COUNTDOWN);

  const ref = useRef(null);

  useIsomorphicLayoutEffect(() => {
    ref.current = setInterval(() => {
      if (countRef.current <= 1) {
        onFinish?.();
        setCountDown(initTime ?? COUNTDOWN);
        clearInterval(ref.current);
      } else {
        const count = countRef.current - 1;
        countRef.current = count;
        setCountDown(count);
      }
    }, 1000);
    return () => {
      clearInterval(ref?.current);
    };
  }, []);
  if (type !== '1') return <span>{countDown}</span>;
  return (
    <span
      className={cn(styles.countDown, {
        [styles.countDownIng]: countdown,
        [styles.darkCountDown]: theme === THEME.DARK
      })}
    >
      {I18n.t('register_resend')}({countDown}s)
    </span>
  );
};
export default CountDown;
