
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { addData } from './db';
const env = process.env.NEXT_PUBLIC_API_ENV

let vapidKey
switch (env) {
  case 'local':
  case 'dev':
    vapidKey = 'BNsyCTXxt0lzG-LXf0FSu8kg6uyTHHgtRq7mZiHacmmc6YCyC4KP80E-Myk0Ovk9ZNXt9QxnN6qByxKIbPKKn4s'
    break;
  case 'preview':
  case 'beta':
  case 'beta1':
  case 'beta2':
    vapidKey = 'BGd9jPnq1cNMR5j82Z0SxvUDx7-LLMuLcr7X0QEOs4qOckJ9MFc-Ld_F5ITfpMfLfgJVpItPOFSCxXNTfzvTZBU'
    break;
  case 'prodgreen':
  case 'pro':
  case 'pre1':
  case 'pre':
  case 'prod':
  default:
    vapidKey = 'BPA5OLfoGHjPGauQIatvbhWtwcBn5DjJuMLR9ZiX5hmDutB5rfvFeF5re3wEwingvbztwASB7Qb8wYcHw4KorL0'
    break;
}

// only browser client
if (typeof window !== 'undefined') {
  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  const messaging = getMessaging();
  getToken(messaging, { vapidKey: vapidKey }).then((currentToken) => {
    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      // ...
      console.log('fcmToken: ' + currentToken)
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      // ...
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
  });
  console.log(333)
  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // create a notification object
    const greeting = new Notification(payload.notification.title, {
      body: payload.notification.body,
      icon: '/logo.png'
    });

    greeting.addEventListener('click', function () {
      let id = ''
      try {
        if (payload.data.route === 'TopicDetail') {
          id = JSON.parse(payload.data.routeParams).item.id
          window.open('https://webbeta.kikitrade.com/zh-TC/post/' + id);
        }
      } catch (e) {
        window.open('https://webbeta.kikitrade.com')
      }
      greeting.close()
    });
    addData({
      id: payload.collapseKey,
      title: payload.notification.title,
      body: payload.notification.body,
      createTime: payload.data['google.c.a.ts'],
      isRead: false
    })
  });
}
