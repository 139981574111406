import { LOGIN_BEFORE_PATHS } from '@constants/index'
export const getLoginPath = (referer) => {
  if (typeof window === 'undefined' && !referer) {
    return LOGIN_BEFORE_PATHS.LOGIN
  }
  if (location?.href.indexOf(LOGIN_BEFORE_PATHS.LOGIN) === -1) {
    return `${LOGIN_BEFORE_PATHS.LOGIN}?referrer=${referer || location?.href || ''}`
  } else {
    return LOGIN_BEFORE_PATHS.LOGIN
  }
}
