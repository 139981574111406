import React from 'react';
import Icon from '@ant-design/icons';

const modulesFiles = require.context('@images/svgIcon', true, /.svg$/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const paths = modulePath.split('/');
  const moduleName = paths[paths.length - 1].split('.')[0]
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

const WapperIcon = (props) => {
  return <Icon component={modules[props.name]} {...props}/>
}

export default WapperIcon;
