import { env }  from '../constants/env'
import { SupportChains as supportChains }  from '../constants/supportChains'
import { ModalProvider,  } from '@particle-network/connectkit';
import { evmWallets, solanaWallets } from '@particle-network/connectors';


export function ParticleModalProvider ({children}) {
  return (
      <ModalProvider
          options={{
            projectId: env.REACT_APP_PROJECT_ID,
            clientKey: env.REACT_APP_CLIENT_KEY,
            appId: env.REACT_APP_APP_ID,
            chains: supportChains,
            connectors: [
              ...evmWallets({ projectId: env.REACT_APP_WALLETCONNECT_PROJECT_ID, showQrModal: true }),
              ...solanaWallets()
            ],
            wallet: {
              visible: process.env.NODE_ENV === 'development',
              customStyle: {
                supportChains: supportChains
              }
            }
          }}
          theme={'light'}
      >
        {children}
      </ModalProvider>


    )

}