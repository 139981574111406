import some from 'lodash/some'
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect'

export const useValidCoinCode = ({ marketStore }) => {
  const { marketSymbols, fetchMarketSymbol } = marketStore;

  const isValidCoinCode = (coinCodePair) => {
    return some(marketSymbols, { coinCode: coinCodePair });
  }

  useIsomorphicLayoutEffect(() => {
    fetchMarketSymbol()
  }, [])

  return {
    isValidCoinCode
  }
}
