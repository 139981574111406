import dayjs from 'dayjs';

const getTotalRevenues = ({ totalRevenues, marketSymbols, priceLast }) => {
  let _totalRevenues = 0;
  Object.keys(totalRevenues).forEach((item) => {
    const _marketSymbols = marketSymbols;
    const fiatItem = _marketSymbols.find(
      (data, index: number, array): boolean => {
        return data.coinCode === item + '_USDT';
      }
    );
    if (fiatItem && fiatItem.priceLast) {
      _totalRevenues +=
        parseFloat(fiatItem.priceLast) *
        parseFloat(totalRevenues[item]) *
        parseFloat(priceLast);
    } else if (item === 'USDT') {
      _totalRevenues += parseFloat(totalRevenues[item]) * parseFloat(priceLast);
    } else if (item === 'HKD') {
      const fiatItem = _marketSymbols.find(
        (data, index: number, array): boolean => {
          return data.coinCode === 'USDT_HKD';
        }
      );
      if (fiatItem && fiatItem.priceLast) {
        _totalRevenues +=
          (1 / parseFloat(fiatItem.priceLast)) *
          parseFloat(totalRevenues[item]) *
          parseFloat(priceLast);
      }
    } else if (item === 'USDC') {
      _totalRevenues += parseFloat(totalRevenues[item]);
    }
  });
  return _totalRevenues;
};

const getYesterdayRevenues = ({
  yesterdayRevenues,
  marketSymbols,
  priceLast
}) => {
  let _yesterdayRevenues = 0;
  Object.keys(yesterdayRevenues).forEach((item) => {
    const _marketSymbols = marketSymbols;
    const fiatItem = _marketSymbols.find(
      (data, index: number, array): boolean => {
        return data.coinCode === item + '_USDT';
      }
    );
    if (fiatItem && fiatItem.priceLast) {
      _yesterdayRevenues +=
        parseFloat(fiatItem.priceLast) *
        parseFloat(yesterdayRevenues[item]) *
        parseFloat(priceLast);
    } else if (item === 'USDT') {
      _yesterdayRevenues +=
        parseFloat(yesterdayRevenues[item]) * parseFloat(priceLast);
    } else if (item === 'HKD') {
      const fiatItem = _marketSymbols.find(
        (data, index: number, array): boolean => {
          return data.coinCode === 'USDT_HKD';
        }
      );
      if (fiatItem && fiatItem.priceLast) {
        _yesterdayRevenues +=
          (1 / parseFloat(fiatItem.priceLast)) *
          parseFloat(yesterdayRevenues[item]) *
          parseFloat(priceLast);
      }
    } else if (item === 'USDC') {
      _yesterdayRevenues += parseFloat(yesterdayRevenues[item]);
    }
  });
  return _yesterdayRevenues;
};

const getFinancingPositions = ({
  financingPositions,
  marketSymbols,
  priceLast
}) => {
  let _financingPositions = 0;
  Object.keys(financingPositions).forEach((item) => {
    const _marketSymbols = marketSymbols;
    const fiatItem = _marketSymbols.find(
      (data, index: number, array): boolean => {
        return data.coinCode === item + '_USDT';
      }
    );
    if (fiatItem && fiatItem.priceLast) {
      _financingPositions +=
        parseFloat(fiatItem.priceLast) *
        parseFloat(financingPositions[item]) *
        parseFloat(priceLast);
    } else if (item === 'USDT') {
      _financingPositions +=
        parseFloat(financingPositions[item]) * parseFloat(priceLast);
    }
  });
  return _financingPositions;
};

const getFixedDepositPositions = ({
  fixedDepositPositions,
  marketSymbols,
  priceLast
}) => {
  let _fixedDepositPositions = 0;
  Object.keys(fixedDepositPositions).forEach((item) => {
    const _marketSymbols = marketSymbols;
    const fiatItem = _marketSymbols.find(
      (data, index: number, array): boolean => {
        return data.coinCode === item + '_USDT';
      }
    );
    if (fiatItem && fiatItem.priceLast) {
      _fixedDepositPositions +=
        parseFloat(fiatItem.priceLast) *
        parseFloat(fixedDepositPositions[item]) *
        parseFloat(priceLast);
    } else if (item === 'USDT') {
      _fixedDepositPositions +=
        parseFloat(fixedDepositPositions[item]) * parseFloat(priceLast);
    }
  });
  return _fixedDepositPositions;
};

const getInvestmentAmounts = ({
  investmentAmounts,
  marketSymbols,
  priceLast
}) => {
  let _investmentAmounts = 0;
  Object.keys(investmentAmounts).forEach((item) => {
    const _marketSymbols = marketSymbols;
    const fiatItem = _marketSymbols.find(
      (data, index: number, array): boolean => {
        return data.coinCode === item + '_USDT';
      }
    );
    if (fiatItem && fiatItem.priceLast) {
      _investmentAmounts +=
        parseFloat(fiatItem.priceLast) *
        parseFloat(investmentAmounts[item]) *
        parseFloat(priceLast);
    } else if (item === 'USDT') {
      _investmentAmounts +=
        parseFloat(investmentAmounts[item]) * parseFloat(priceLast);
    } else if (item === 'HKD') {
      const fiatItem = _marketSymbols.find(
        (data, index: number, array): boolean => {
          return data.coinCode === 'USDT_HKD';
        }
      );
      if (fiatItem && fiatItem.priceLast) {
        _investmentAmounts +=
          (1 / parseFloat(fiatItem.priceLast)) *
          parseFloat(investmentAmounts[item]) *
          parseFloat(priceLast);
      }
    } else if (item === 'USDC') {
      _investmentAmounts += parseFloat(investmentAmounts[item]);
    }
  });
  return _investmentAmounts;
};

export const getMaxAvailable = function (
  maxAvailable: number,
  available: number
): number {
  if (!maxAvailable || !available) {
    return 0;
  }
  return Math.max(Math.min(maxAvailable, available), 0);
};

export const formatAccount = (
  accountObj,
  otherRateDetail,
  coinDetail,
  rateType,
  marketSymbols
) => {
  const financingPositions = accountObj ? accountObj.financingPositions : {};
  const fixedDepositPositions = accountObj
    ? accountObj.fixedDepositPositions
    : {};
  const investmentAmounts = accountObj ? accountObj.investmentAmounts : {};
  const totalRevenues = accountObj ? accountObj.totalRevenues : {};
  const yesterdayRevenues = accountObj ? accountObj.yesterdayRevenues : {};
  const _coinDetail =
    otherRateDetail?.coinCode === 'USDT_' + rateType
      ? otherRateDetail
      : coinDetail;
  const priceLast = _coinDetail?.priceLast || 0;

  const _totalRevenues = getTotalRevenues({
    totalRevenues,
    marketSymbols,
    priceLast
  });
  const _yesterdayRevenues = getYesterdayRevenues({
    yesterdayRevenues,
    marketSymbols,
    priceLast
  });
  const _financingPositions = getFinancingPositions({
    financingPositions,
    marketSymbols,
    priceLast
  });
  const _fixedDepositPositions = getFixedDepositPositions({
    fixedDepositPositions,
    marketSymbols,
    priceLast
  });
  const _investmentAmounts = getInvestmentAmounts({
    investmentAmounts,
    marketSymbols,
    priceLast
  });

  return {
    totalRevenues: _totalRevenues,
    yesterdayRevenues: _yesterdayRevenues,
    fixedDepositPositions: _fixedDepositPositions,
    financingPositions: _financingPositions,
    investmentAmounts: _investmentAmounts
  };
};

export const getBuyEndTime = (lockStartTime?: number): string => {
  if (lockStartTime === null || lockStartTime === undefined) {
    lockStartTime = 0;
  } else {
    lockStartTime -= 1000;
  }
  const time = dayjs(lockStartTime).format('YYYY-MM-DD HH:mm:ss');
  return time;
};

export const getLockStartTime = (lockStartTime?: number): string => {
  const time = dayjs(lockStartTime ?? '').format('YYYY-MM-DD HH:mm:ss');
  return time;
};

export const getLockEndTime = (
  lockStartTime?: number,
  lockPeriod?: number
): string => {
  // 定期的持仓此字段会为0
  if (lockPeriod === 0) {
    return '--'
  }
  if (lockStartTime === null || lockPeriod === undefined) {
    lockStartTime = 0;
  } else {
    lockStartTime -= 1000;
  }
  const time = dayjs(lockStartTime)
    .add(Number(lockPeriod), 'day')
    .format('YYYY-MM-DD HH:mm:ss');
  return time;
};

export const isValidNumber = (input: string): boolean => {
  return !!`${input}`?.match(/^[0-9]*$/);
};

export const getDigitalLength = (incrementAmount: number): number => {
  if (incrementAmount > 0) {
    const numbers = incrementAmount.toString().split('.');
    if (numbers.length > 1) {
      return numbers[1].length;
    }
  }
  return 0;
};
