import React from 'react';
import { Input } from './index';
import I18n from '../../../utils/i18n';
import cn from 'classnames';
import Icon from '@components/Icon';

// export interface IInputPasswordProps extends IInputProps {
// }

export default function InputSearch (props) {
  const { placeholder, className } = props;

  const prefix = <Icon name='search' />

  return (
    <Input
      {...props}
      className={cn('kiki-input', 'input-search', className)}
      placeholder={placeholder || I18n.t('search')}
      prefix={prefix}
    />
  )
}
