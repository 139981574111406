// @ts-nocheck

import { Ethereum, EthereumGoerli } from '@particle-network/chains';
import {
    ParticleConnect,
    Provider,
    coinbase,
    isEVMProvider,
    isSolanaProvider,
    metaMask,
    rainbow,
    walletconnect,
} from '@particle-network/connect';
import Image from 'next/legacy/image';
import { Button, Divider, Modal, message, notification } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './App.scss';
const env = {
    REACT_APP_PROJECT_ID:"12931990-d9e2-4805-ae9d-ae53240c8e78",
    REACT_APP_CLIENT_KEY:"cBeYPGmZHGrP21IpVsdm7QaOlW04yGI4x58SqpTB",
    REACT_APP_APP_ID:"3e449da0-bd57-43bb-8ed5-a105c9836e18",
    REACT_APP_WALLETCONNECT_PROJECT_ID:"df9499f30b7bb076d4d26ca414514ff5"
}
export function ParticleCustomConnect() {
    const [provider, setProvider] = useState<Provider>();

    const [connectModal, setConnectModal] = useState(false);
    const [address, setAddress] = useState<string>();

    const connectKit = useMemo(() => {
        return new ParticleConnect({

            projectId: env.REACT_APP_PROJECT_ID as string,
            clientKey: env.REACT_APP_CLIENT_KEY as string,
            appId: env.REACT_APP_APP_ID as string,
            chains: [Ethereum, EthereumGoerli],
            particleWalletEntry: {    //optional: particle wallet config
                displayWalletEntry: true, //display wallet button when connect particle success.
                supportChains:[
                    Ethereum,
                    EthereumGoerli
                ],
                customStyle: {}, //optional: custom wallet style
            },

            wallets: [
                metaMask({ projectId: env.REACT_APP_WALLETCONNECT_PROJECT_ID }),
                walletconnect({ projectId: env.REACT_APP_WALLETCONNECT_PROJECT_ID }),
            ],
        });
    }, []);

    const connectWallet = async (id: string, options?: any) => {
        console.log('connectWallet', id, options);
        setConnectModal(false);
        try {
            const connectProvider = await connectKit.connect(id, options);
            setProvider(connectProvider);

        } catch (error) {
            console.error('connectWallet', error);
            message.error(error.message ?? error);
        }
    };

    const openWallet = async () => {
        console.log()
        // To set target and features for custom window style, same as window.open().
        connectKit.particle.setAuthTheme({
          uiMode: "light",
          displayCloseButton: true,
          displayWallet: true, // display wallet entrance when send transaction.
          modalBorderRadius: 10, // auth & wallet modal border radius. default 10.
        });
        connectKit.particle.setLanguage('zh-TW');
        //open wallet in custom iframe.
        const url = connectKit.particle.buildWalletUrl({
            //optional: left top menu style, close or fullscreen
            //"fullscreen": wallet will be fullscreen when user click.
            //"close": developer need handle click event
            topMenuType: "fullscreen"   
        });
        connectKit.particle.openWallet();

        // const iframe = document.createElement("iframe");
        // iframe.src = url;
        console.log('url', url)
        // //if topMenuType is "close"
        window.addEventListener("message", (event) => {
            // console.log('event',event)
            if (event.data === "PARTICLE_WALLET_CLOSE_IFRAME") {
                //close click event
            }
        })
    }

    const disconnectWallet = async () => {
        try {
            await connectKit.disconnect({ hideLoading: true });
        } catch (error) {
            console.error(error);
        }
        setProvider(null);
    };

    useEffect(() => {
        const id = connectKit.cachedProviderId();
        if (id) {
            connectKit
                .connectToCachedProvider()
                .then((provider) => {
                    setProvider(provider);
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            setProvider(null);
        }

        const onDisconnect = () => {
            setProvider(null);
        };

        connectKit.on('disconnect', onDisconnect);

        return () => {
            connectKit.removeListener('disconnect', onDisconnect);
        };
    }, [connectKit]);

    useEffect(() => {
        if (provider) {
            getAddress();
        } else {
            setAddress(undefined);
        }
    }, [provider]);

    const personalSign = useCallback(async () => {
        if (address && provider && isEVMProvider(provider)) {
            try {
                const result = await provider.request({
                    method: 'personal_sign',
                    params: ['0x48656c6c6f20576f726c64', address],
                });
                notification.success({
                    message: 'Personal Sign',
                    description: result,
                });
            } catch (error) {
                console.log('personal_sign', error);
            }
        }
    }, [provider, address]);

    const getAddress = async () => {
        if (isEVMProvider(provider)) {
            const addresses = await provider.request({ method: 'eth_accounts' });
            setAddress(addresses[0]);
        } else if (isSolanaProvider(provider)) {
            const address = provider.publicKey.toBase58();
            setAddress(address);
        }
    };

    return (
        <div className="app">
            <div className="app-title">Custom ConnectKit</div>
            {provider !== undefined &&
                (provider ? (
                    <>
                        <Button className="btn-action" size="large" type="primary" onClick={disconnectWallet}>
                            Disconnect
                        </Button>
                        <Button className="btn-action" size="large" type="primary" onClick={openWallet}>
                            openWallet
                        </Button>


                    </>

                ) : (
                    <Button className="btn-action" size="large" type="primary" onClick={() => setConnectModal(true)}>
                        Connect
                    </Button>
                ))}

            {address && (
                <>
                    <div className="address">{address}</div>
                    <Button className="btn-action" size="large" type="primary" onClick={personalSign}>
                        Personal Sign
                    </Button>
                </>
            )}

            <Modal
                className="connect-modal"
                title="Connect Wallet"
                open={connectModal}
                onCancel={() => setConnectModal(false)}
                footer
                centered
            >
                <div className="wallet-items">
                    <div onClick={() => connectWallet('metamask')}>
                        MetaMask
                        <Image
                            src={require('./assets/metamask_icon.png')}
                            alt=""
                            width={40}
                            height={40}
                        />

                    </div>
                    <div onClick={() => connectWallet('walletconnect_v2')}>
                        WalletConnect 
                        <Image
                            src={require('./assets/walletconnect_icon.png')}
                            alt=""
                            width={40}
                            height={40}
                        />

                    </div>
                </div>

                <Divider>Or connect with Particle Wallet</Divider>

                <div className="particle-methods">

                    <Image
                        onClick={() => connectWallet('particle', { preferredAuthType: 'google' })}
                        src={require('./assets/google_icon.png')}
                        alt=""
                        width={60}
                        height={60}
                    />
                    <Image
                        onClick={() => connectWallet('particle', { preferredAuthType: 'apple' })}
                        src={require('./assets/apple_icon.png')}
                        alt=""
                        width={60}
                        height={60}
                    />
                    <Image
                        onClick={() => connectWallet('particle', { preferredAuthType: 'facebook' })}
                        src={require('./assets/facebook_icon.png')}
                        alt=""
                        width={60}
                        height={60}
                    />

                </div>
            </Modal>
        </div>
    );
}

