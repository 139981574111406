import { Ethereum, BNBChain, zkSyncEra, Linea, Mantle, Polygon, BNBChainTestnet, EthereumGoerli } from '@particle-network/chains';

let supportChains = [
  Ethereum,
  BNBChain,
  zkSyncEra,
  Linea,
  Mantle,
  Polygon
]

const testChains = [
  EthereumGoerli,
  BNBChainTestnet
]

const NEXT_PUBLIC_API_ENV = process.env.NEXT_PUBLIC_API_ENV
if(NEXT_PUBLIC_API_ENV !== 'prod') {
  supportChains = supportChains.concat(testChains)
}
export const SupportChains  = supportChains

