/* eslint-disable max-lines */
import config from '../utils/config';
import helper from '../utils/helper';
import request from '../utils/request';
import {
  FinancingProducts, FixedDepositProductDetail,
  FixedDepositProducts, IFinancingAccountRes, IFinancingContractsHistoryReq,
  IFinancingContractsHistoryRes, IFinancingContractsRes,
  IFinancingReq,
  IFixedDepositContractDetail,
  IFixedDepositContractsReq, IFixedDepositContractsRes,
  IInvestmentContractsHistoryRes,
  IInvestmentContractsRes, InvestFinancingContracts,
  InvestmentContractsHistory, InvestmentContractsLogDetail,
  InvestmentProducts
} from './earnings.type';

export const financingAccount = (): Promise<APIResponse<IFinancingAccountRes>> => {
  const URL = `${config.api.host_api_v1}${
    config.api.earnings.financingAccount
  }?${helper.mapToQueryString({})}`;
  return request.get.call({}, URL);
};
/* 获取活期产品列表 */
export const financingProducts = (params: IFinancingReq, options = {}): Promise<APIResponse<FinancingProducts>> => {
  params = {
    ...params,
    nextToken: encodeURIComponent(params.nextToken)
  };
  const URL =
    config.api.host_newapi +
    config.api.earnings.financingProducts +
    '?' +
    helper.mapToQueryString(params ?? {});
  return request.get.call({}, URL, options);
};

/**
 * 获取定期产品列表
 * @param params { nextToken: string, limit: number, status?: string }
 * @returns Promise<APIResponse<InvestFinancingContracts>>
 */
export const fixedDepositProducts = (params: { nextToken: string, limit: number, status?: string })
  : Promise<APIResponse<FixedDepositProducts>> => {
  params = {
    ...params,
    nextToken: encodeURIComponent(params.nextToken)
  };
  const URL =
    config.api.host_newapi +
    config.api.earningsV1.fixedDepositProducts +
    '?' +
    helper.mapToQueryString(params ?? {});
  return request.get.call({}, URL);
};
/* 获取自动申购产品列表 */
export const financingProductsAuto = (params?: IFinancingReq):Promise<APIResponse<string[]>> => {
  const URL =
    config.api.host_newapi +
    config.api.earnings.financingProductsAuto +
    '?' + helper.mapToQueryString(params ?? {});
  return request.get.call({}, URL);
};
/**
 * 获取定投产品列表
 * @param {*} params { nextToken: string; limit: number; name: string; }
 * @returns response
 */
export const getInvestmentProducts = (params, opts) => {
  const URL = `${config.api.host_api_v1}${
    config.api.earnings.investmentProducts
  }?${helper.mapToQueryString(params ?? {})}`;
  return request.get.call({}, URL, opts);
};
// 获取活期列表
export const getFinancingContracts = (params: IFinancingReq): Promise<APIResponse<IFinancingContractsRes>> => {
  const URL = `${config.api.host_api_v1}${
    config.api.earnings.financingContracts
  }?${helper.mapToQueryString(params ?? {})}`;
  return request.get.call({}, URL);
};
// 开启关闭自动申购
// params { autoSubscribe: 'on' | 'off', productId: string }
export const financingAutoSubscribe = (params) => {
  const URL = `${config.api.host_api_v1}${
    config.api.earnings.financingAutoSubscribe
  }?${helper.mapToQueryString(params ?? {})}`;
  return request.post.call({}, URL);
};
// 获取定期列表
export const getFixedDepositContracts = (params: IFixedDepositContractsReq): Promise<APIResponse<IFixedDepositContractsRes>> => {
  const URL = `${config.api.host_api_v1}${
    config.api.earnings.fixedDepositContracts
  }?${helper.mapToQueryString(params ?? {})}`;
  return request.get.call({}, URL);
};
// 获取定期详情
export const getFixedDepositContractDetail = (params: { id: string }): Promise<APIResponse<IFixedDepositContractDetail>> => {
  const URL = `${
    config.api.host_api_v1
  }${config.api.earnings.fixedDepositContractDetail.replace(
    /{id}/,
    params.id
  )}?${helper.mapToQueryString(params ?? {})}`;
  return request.get.call({}, URL);
};
// 获取我的定投列表
export const getInvestmentContracts = (params: IFinancingReq): Promise<APIResponse<IInvestmentContractsRes>> => {
  const URL = `${config.api.host_api_v1}${
    config.api.earnings.investmentContracts
  }?${helper.mapToQueryString(params ?? {})}`;
  return request.get.call({}, URL);
};
// 理财历史 - 活期
export const getFinancingContractsHistory = (params: IFinancingContractsHistoryReq): (
  Promise<APIResponse<IFinancingContractsHistoryRes>>
) => {
  const URL = `${config.api.host_api_v1}${
    config.api.earnings.financingContractsHistory
  }?${helper.mapToQueryString(params ?? {})}`;
  return request.get.call({}, URL);
};
// ！！理财历史 - 定期分为 申购历史和持仓历史两个接口
// 定期详情 - 申购历史
export const getFixedDepositLogs = (params: IFixedDepositContractsReq): Promise<APIResponse<IFixedDepositContractsRes>> => {
  const URL = `${config.api.host_api_v1}${
    config.api.earnings.fixedDepositLogs
  }?${helper.mapToQueryString(params ?? {})}`;
  return request.get.call({}, URL);
};
// 定期详情 - 申购历史 - 撤销请求
export const fixedDepositRevoke = (params: { id: string }): Promise<APIResponse<{}>> => {
  const URL = `${
    config.api.host_api_v1
  }${config.api.earnings.fixedDepositRevoke.replace(
    /{id}/,
    params?.id
  )}?${helper.mapToQueryString(params ?? {})}`;
  return request.post.call({}, URL);
};
// 理财历史 - 定投
export const getInvestmentContractsHistory = (params: IFinancingContractsHistoryReq): (
  Promise<APIResponse<IInvestmentContractsHistoryRes>>
) => {
  const URL = `${config.api.host_api_v1}${
    config.api.earnings.investmentContractsHistory
  }?${helper.mapToQueryString(params ?? {})}`;
  return request.get.call({}, URL);
};
// 申购赎回
export const financingProductsRedeemDetail = (params) => {
  const URL =
    config.api.host_newapi +
    config.api.earnings.financingProductsRedeemDetail.replace(
      /{productId}/,
      params.productId
    ) +
    '?' +
    helper.mapToQueryString(params ?? {});
  return request.post.call({}, URL);
};
export const financingProductsDetail = (params) => {
  // params.saas_id = 'kiki';
  const URL =
    config.api.host_newapi +
    config.api.earnings.financingProductsDetail.replace(
      /{productId}/,
      params.productId
    ) +
    '?' +
    helper.mapToQueryString(params ?? {});
  return request.get.call({}, URL);
};
export const financingProductsSubscribeDetail = (params) => {
  // params.saas_id = 'kiki';
  const URL =
    config.api.host_newapi +
    config.api.earnings.financingProductsSubscribeDetail.replace(
      /{productId}/,
      params.productId
    ) +
    '?' +
    helper.mapToQueryString(params ?? {});
  return request.post.call({}, URL);
};
export const fixedDepositProductsDetail = (params: {
  id: string;
}): Promise<APIResponse<FixedDepositProductDetail>> => {
  const URL =
    config.api.host_newapi +
    config.api.earningsV1.fixedDepositProductDetail.replace(/{id}/, params.id) +
    '?' +
    helper.mapToQueryString(params ?? {});
  return request.get.call({}, URL);
};
export const fixedDepositSubscribe = (params: {
  id: string;
  autoFinancing: boolean;
  quantity: number;
}) => {
  const URL =
    config.api.host_newapi +
    config.api.earningsV1.fixedDepositProductSubscribe.replace(
      /{id}/,
      params.id
    ) +
    '?' +
    helper.mapToQueryString(params ?? {});
  return request.post.call({}, URL);
};
/**
 * 参与定投
 * @param params
 * @returns
 */
export const investmentProductsSubscribeDetail = (params: {
  productId: string;
  period: number;
  amount: number;
  proportionId: string;
  payOptionId: string;
  autoFinancing: 'on' | 'off';
}): Promise<APIResponse<InvestmentProducts>> => {
  const URL =
    config.api.host_newapi +
    config.api.earnings.investmentProductsSubscribeDetail.replace(
      /{productId}/,
      params.productId
    ) +
    '?' +
    helper.mapToQueryString(params ?? {});
  return request.post.call({}, URL);
};
/**
 * 定投自动转入活期理财
 * @param params { autoFinancing: 'on' | 'off'; contractId: string; }
 * @returns Promise<APIResponse<InvestFinancingContracts>>
 */
export const investmentContractsAutoFinancingDetail = (params: {
  autoFinancing: 'on' | 'off';
  contractId: string;
}): Promise<APIResponse<InvestFinancingContracts>> => {
  const URL =
    config.api.host_newapi +
    config.api.earnings.investmentContractsAutoFinancingDetail.replace(
      /{contractId}/,
      params.contractId
    ) +
    '?' +
    helper.mapToQueryString(params ?? {});
  return request.post.call({}, URL);
};
/**
 * 暂停定投
 * @param params { contractId: string; }
 * @returns Promise<APIResponse<InvestFinancingContracts>>
 */
export const investmentContractsPauseDetail = (params: {
  contractId: string;
}): Promise<APIResponse<InvestFinancingContracts>> => {
  const URL =
    config.api.host_newapi +
    config.api.earnings.investmentContractsPauseDetail.replace(
      /{contractId}/,
      params.contractId
    ) +
    '?' +
    helper.mapToQueryString(params ?? {});
  return request.post.call({}, URL);
};
/**
 * 恢复定投
 * @param params { contractId: string; }
 * @returns Promise<APIResponse<InvestFinancingContracts>>
 */
export const investmentContractsResumeDetail = (params: {
  contractId: string;
}): Promise<APIResponse<InvestFinancingContracts>> => {
  const URL =
    config.api.host_newapi +
    config.api.earnings.investmentContractsResumeDetail.replace(
      /{contractId}/,
      params.contractId
    ) +
    '?' +
    helper.mapToQueryString(params ?? {});
  return request.post.call({}, URL);
};
/**
 * 终止并删除定投
 * @param params { contractId: string; }
 * @returns Promise<APIResponse<InvestFinancingContracts>>
 */
export const investmentContractsStopDetail = (params: {
  contractId: string;
}): Promise<APIResponse<InvestFinancingContracts>> => {
  const URL =
    config.api.host_newapi +
    config.api.earnings.investmentContractsStopDetail.replace(
      /{contractId}/,
      params.contractId
    ) +
    '?' +
    helper.mapToQueryString(params ?? {});
  return request.post.call({}, URL);
};
/**
 * 更改我的定投订单号
 * @param params { contractId: string; name: string; }
 * @returns Promise<APIResponse<InvestFinancingContracts>>
 */
export const investmentContractsRenameDetail = (params: {
  contractId: string;
  name: string;
}): Promise<APIResponse<InvestFinancingContracts>> => {
  const URL =
    config.api.host_newapi +
    config.api.earnings.investmentContractsRenameDetail.replace(
      /{contractId}/,
      params.contractId
    ) +
    '?' +
    helper.mapToQueryString(params ?? {});
  return request.post.call({}, URL);
};
/* 定投历史 */
export const investmentContractsHistory = (params: {
  start?: number;
  end?: number;
  contractId: string;
  nextToken: string;
  limit: number;
}): Promise<APIResponse<InvestmentContractsHistory>> => {
  const URL =
    config.api.host_newapi +
    config.api.earnings.investmentContractsHistory +
    '?' +
    helper.mapToQueryString(params ?? {});
  return request.get.call({}, URL);
};
/**
 * 定投历史详情
 * @param params { operateId: string; }
 * @returns Promise<APIResponse<InvestmentContractsLogDetail>>
 */
export const investmentContractsLogDetail = (params: {
  operateId: string;
}): Promise<APIResponse<InvestmentContractsLogDetail>> => {
  const URL =
    config.api.host_newapi +
    config.api.earnings.investmentContractsLogDetail.replace(
      /{operateId}/,
      params.operateId
    ) +
    '?' +
    helper.mapToQueryString(params ?? {});
  return request.get.call({}, URL);
};
