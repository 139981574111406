import config from '../utils/config';
import request from '../utils/request';
import helper from '../utils/helper';

// class definition
class GoogleApi {
  /**
   * generate a google key ,
   * @returns Promise<string> secret of google
   */
  key () {
    // const params = { saas_id: 'kiki' };
    const URL =
      config.api.host_newapi +
      config.api.google.key;
    return request.post.call({}, URL);
  };

  binding (codes, savedSecret) {
    const params = {
      codes,
      savedSecret
    };
    const URL =
      config.api.host_newapi +
      config.api.google.binding +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };

  remove (tokenId) {
    const params = { tokenId };
    const URL =
      config.api.host_newapi +
      config.api.google.remove +
      '?' +
      helper.mapToQueryString(params);
    return request.post.call({}, URL);
  };
}

const GoogleAPI = new GoogleApi();
export { GoogleAPI };
