import { filter, find, map, maxBy, sortBy } from 'lodash';

const defaultList = [];

export const getValueByField = (list, getValueByField) => {
  return (list || defaultList).map((item) => item?.[getValueByField])
}

export const formatChartList = (list) => {
  // 重新匹配数据，匹配是线还是柱状图
  const lineChartType1 = filter(list, { chartType: 1 }) // 折线
  const columnChartType0 = filter(list, { chartType: 0 }) // 柱子图
  const allList = [...lineChartType1, ...columnChartType0];
  const categoryMap = []
  const chartLineList = map(allList, (listItem) => {
    const { klines, category } = listItem;
    categoryMap.push(category)
    return klines;
  });
  return [chartLineList, categoryMap]
}

export const equalList = (list, categoryMap) => {
  const maxLengthList = maxBy(list, 'length');
  const saveList = new Array(list.length).fill(null).map(() => ([]));
  map(sortBy((maxLengthList), ['timestamp']), (maxKlineItem) => {
    const { timestamp } = maxKlineItem;
    map(list, (lineItem, index) => {
      const currentItem = find(lineItem, { timestamp });
      const category = currentItem?.category || categoryMap?.[index];
      saveList[index] = [
        ...saveList[index],
        {
          ...currentItem,
          timestamp,
          category: category,
          close: currentItem?.close ?? 0
        }]
    })
  })
  return [saveList, maxLengthList];
}

export const getValueList = (list) => {
  return (list || defaultList).map(listItem => getValueByField(listItem, 'close'))
}
