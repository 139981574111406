import { COMMUNITY_PATHS, DEPOSIT, HOME_PATH, INVITATION, LOGIN_BEFORE_PATHS, MARKET_PATHS, NEWS_PATHS, USER_PATHS, WALLET, WALLET_PATHS } from '@constants/index';

const zhCN = 'zh-CN';
const zhTC = 'zh-TC';
const en = 'en';

const HEADER = {
  [HOME_PATH]: {
    [zhCN]: [
    <title key="1">Kikitrade | 加密货币社交投资平台 | 投资买币不孤单</title>,
    <meta property="og:title" key="title" name="description" content="Kikitrade 作为加密货币社交平台，拥有百名以上区块链大师，及数以十万计用户活跃于讨论超过二百多款已上线币种，录得日均三万或以上分享经验交流心得的互动量，令你资产、知识，同步增值。"/>
    ],
    [zhTC]: [
    <title key="1">Kikitrade | 加密貨幣社交投資平台 | 投資買幣不孤單</title>,
    <meta property="og:title" key="title" name="description" content="Kikitrade 作為加密貨幣社交平台，擁有百名以上區塊鏈大師，及數以十萬計用戶活躍於討論超過二百多款已上線幣種，錄得日均三萬或以上分享經驗交流心得的互動量，令你資產、知識，同步增值。"/>
    ],
    [en]: [
    <title key="1">Kikitrade | First Crypto SocialFi in Hong Kong</title>,
    <meta property="og:title" key="title" name="description" content="Chime in the average 30,000+ daily interactions of the crypto community, formed with 200k+ active users
and backed by 100+ blockchain masters, benefit from perspectives all while browsing for 240+ cryptocurrencies and more, literally gain by growing on Kikitrade."/>
    ]
  },
  [COMMUNITY_PATHS.COMMUNITY]: {
    [zhCN]: [
    <title key="1">Kiki 社群｜Kikitrade | 加密货币社交型态交易平台</title>,
    <meta property="og:title" key="title" name="description" content="KIKI社群是Kikitrade的加密货币投资平台。超过20万用戶投资、交易、理財、分享技术分析，促进CEO、KOL、各用戶之间的交流，分享投资策略，立即了解最新加密货币話題！"/>
    ],
    [zhTC]: [
    <title key="1">Kiki 社群｜Kikitrade | 加密貨幣社交型態交易平台</title>,
    <meta property="og:title" key="title" name="description" content="KIKI社群是Kikitrade的加密貨幣投資平台。超過20萬用戶投資、交易、理財、分享技術分析，促進CEO、KOL、各用戶之間的交流，分享投資策略，立即了解最新加密貨幣話題！"/>
    ],
    [en]: [
    <title key="1">KIKI Community | Kikitrade | Crypto SocialFi</title>,
    <meta property="og:title" key="title" name="description" content="KIKI Community is a platform for investment information exchanges among over 200k+ users, be it managing strategies or monitoring trades, or discussing tactics and developing exciting new insights, any and all interactions between CEO, KOL and users are surely encouraged."/>
    ]
  },
  [DEPOSIT]: {
    [zhCN]: [
    <title key="1">入金｜Kikitrade | 加密貨幣社交型态交易平台</title>,
    <meta property="og:title" key="title" name="description" content="随时随地使用各种入金方式，请你轻松买卖 比特币 BTC、以太坊 ETH、USDT 及 其他心仪加密货币﹗"/>
    ],
    [zhTC]: [
    <title key="1">入金｜Kikitrade | 加密货币社交型态交易平台</title>,
    <meta property="og:title" key="title" name="description" content="隨時隨地使用各種入金方式，讓你輕鬆買賣 比特幣 BTC、以太坊 ETH、USDT 及 其他心儀加密貨幣﹗"/>
    ],
    [en]: [
    <title key="1">Deposit | Kikitrade | Crypto SocialFi</title>,
    <meta property="og:title" key="title" name="description" content="Kikitrade provides various deposit channels, allowing you to easily buy and sell BTC, ETH, USDT or other cryptocurrencies with ease!"/>
    ]
  },
  [MARKET_PATHS.MARKET]: {
    [zhCN]: [
    <title key="1">加密货币市场｜Kikitrade | 加密货币社交投资平台</title>,
    <meta property="og:title" key="title" name="description" content="Kikitrade 社交形态交易平台 - 了解比特币和加密货币的最新价格、代币市值、代币价格图表以及 24 小时变化的最簡单方法。"/>
    ],
    [zhTC]: [
    <title key="1">加密貨幣市場｜Kikitrade | 加密貨幣社交投資平台</title>,
    <meta property="og:title" key="title" name="description" content="Kikitrade 社交型態交易平台 - 了解比特幣和加密貨幣的最新價格、代幣市值、代幣價格圖表以及 24 小時變化的最簡單方法。"/>
    ],
    [en]: [
    <title key="1">{'Market & Messages | Kikitrade | Crypto SocialFi'}</title>,
    <meta property="og:title" key="title" name="description" content="Your one-stop social platform to easily view trends on Bitcoin and more crypocurrencies, stay close to coinmarket prices, values and all charts for 24-hour movements."/>
    ]
  },
  [LOGIN_BEFORE_PATHS.LOGIN]: {
    [zhCN]: [
    <title key="1">登录｜Kikitrade |加密货币社交投资平台</title>,
    <meta property="og:title" key="title" name="description" content="用戶可以选择绑定 Apple ID 或 Gmail 或 Kikitrade 账户登陆使用，存取各项记录及使用多种功能。"/>
    ],
    [zhTC]: [
    <title key="1">登錄｜Kikitrade |加密貨幣社交投資平台台</title>,
    <meta property="og:title" key="title" name="description" content="用戶可以選擇綁定 Apple ID 或 Gmail 或 Kikitrade 帳戶登錄使用，存取各項紀錄及使用多種功能。"/>
    ],
    [en]: [
    <title key="1">{'Log In | Kikitrade | Crypto SocialFi'}</title>,
    <meta property="og:title" key="title" name="description" content="Log in with Apple ID or Gmail or Kikitrade login, to access all features and all functions you need to trade on Kikitrade. "/>
    ]
  },
  [LOGIN_BEFORE_PATHS.REGISTER]: {
    [zhCN]: [
    <title key="1">注册｜Kikitrade |加密货币社交投资平台</title>,
    <meta property="og:title" key="title" name="description" content="首次注册用戶可以选择綁定 Apple ID 或 Gmail 或 Kikitrade 胀戶开始使用，存取各项记录及使用多种功能。"/>
    ],
    [zhTC]: [
    <title key="1">註冊｜Kikitrade |加密貨幣社交投資平台台</title>,
    <meta property="og:title" key="title" name="description" content="首次註冊用戶可以選擇綁定 Apple ID 或 Gmail 或 Kikitrade 帳戶開始使用，存取各項紀錄及使用多種功能。"/>
    ],
    [en]: [
    <title key="1">{'Sign Up | Kikitrade | Crypto SocialFi'}</title>,
    <meta property="og:title" key="title" name="description" content="Sign up if you haven't yet, register with Apple ID or Gmail or Kikitrade login, to access all features and all functions you need to trade on Kikitrade. "/>
    ]
  },
  [WALLET]: {
    [zhCN]: [
    <title key="1">交易账户 - 錢包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content="用戶的交易账户可以在开通后进行网上交易，一站完成整个交易步骤，令你随时随地，隨心投资买币。"/>
    ],
    [zhTC]: [
    <title key="1">交易賬戶 - 錢包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content="用戶的交易賬戶可以在開通後進行網上交易，一站完成整個交易步驟，令你隨時隨地，隨心投資買幣。"/>
    ],
    [en]: [
    <title key="1">{'Trading Account | Kikitrade | Crypto SocialFi'}</title>,
    <meta property="og:title" key="title" name="description" content="Trading account makes trading with Kikitrade possible and even easier to manage your funds.Try it out for yourself, you'll see."/>
    ]
  },
  [WALLET_PATHS.COIN_LIST]: {
    [zhCN]: [
    <title key="1">币种列表 - 钱包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ],
    [zhTC]: [
    <title key="1">幣種列表 - 錢包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ],
    [en]: [
    <title key="1">{'List of currencies - Wallet | Kikitrade'}</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ]
  },
  [WALLET_PATHS.CRYPTO]: {
    [zhCN]: [
    <title key="1">详情 - 钱包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ],
    [zhTC]: [
    <title key="1">詳情 - 錢包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ],
    [en]: [
    <title key="1">{'Details - Wallet | Kikitradee'}</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ]
  },
  [WALLET_PATHS.DEPOSIT]: {
    [zhCN]: [
    <title key="1">充币 - 钱包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ],
    [zhTC]: [
    <title key="1">充幣 - 錢包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ],
    [en]: [
    <title key="1">{'Deposit - Wallet | Kikitrade'}</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ]
  },
  [WALLET_PATHS.WITHDRAWAL]: {
    [zhCN]: [
    <title key="1">提币 - 錢包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ],
    [zhTC]: [
    <title key="1">提幣 - 錢包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ],
    [en]: [
    <title key="1">{'Withdrawal - Wallet | Kikitrade'}</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ]
  },
  [WALLET_PATHS.HISTORY_DEPOSIT]: {
    [zhCN]: [
    <title key="1">充币历史 - 钱包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content="入金记录一览无遗，小额尝试还是大量投入，交易行踪让你参考以往数据，分析个人及资产成长。"/>
    ],
    [zhTC]: [
    <title key="1">充幣歷史 - 錢包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content="入金記錄一覽無遺，小額嘗試還是大量投入，交易行蹤讓你參考以往數據，分析個人及資產成長。"/>
    ],
    [en]: [
    <title key="1">{'Deposit History - Wallet | Kikitrade'}</title>,
    <meta property="og:title" key="title" name="description" content="A summary of your trading history, all your buying and selling, and what you bought and what you sold, pick up all your past actions here."/>
    ]
  },
  [WALLET_PATHS.HISTORY_WITHDRAWAL]: {
    [zhCN]: [
    <title key="1">提币历史 - 钱包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ],
    [zhTC]: [
    <title key="1">提幣歷史 - 錢包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ],
    [en]: [
    <title key="1">{'Withdrawal History - Wallet | Kikitrade'}</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ]
  },
  [WALLET_PATHS.HISTORY_INTEREST]: {
    [zhCN]: [
    <title key="1">利息收取 - 钱包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content="交易纪录显示包括买入卖出，以及买入什么卖出什么，交易一目了然，让你随时追溯资产动向。"/>
    ],
    [zhTC]: [
    <title key="1">利息收取 - 錢包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content="交易紀錄顯示包括買入賣出，以及買入什麼賣出什麼，交易一目了然，讓你隨時追溯資產動向。"/>
    ],
    [en]: [
    <title key="1">{'Interest - Wallet | Kikitrade'}</title>,
    <meta property="og:title" key="title" name="description" content="A track of your your deposit is listed out for you, every small steps and every largers leaps, so you are able to see where your funds go, should your personal finances manager ever requires."/>
    ]
  },
  [WALLET_PATHS.HISTORY_LIQUIDATION]: {
    [zhCN]: [
    <title key="1">清算记录 - 钱包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ],
    [zhTC]: [
    <title key="1">清算紀錄 - 錢包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ],
    [en]: [
    <title key="1">{'Interest - Wallet | Kikitrade'}</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ]
  },
  [WALLET_PATHS.HISTORY_REPAYMENT]: {
    [zhCN]: [
    <title key="1">退款记录 - 钱包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ],
    [zhTC]: [
    <title key="1">還款記錄 - 錢包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ],
    [en]: [
    <title key="1">{'Repayments - Wallet | Kikitrade'}</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ]
  },
  [WALLET_PATHS.HISTORY_REWARD]: {
    [zhCN]: [
    <title key="1">奖励 - 钱包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ],
    [zhTC]: [
    <title key="1">獎勵 - 錢包｜Kikitrade</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ],
    [en]: [
    <title key="1">{'Reward - Wallet | Kikitrade'}</title>,
    <meta property="og:title" key="title" name="description" content=""/>
    ]
  },
  [USER_PATHS.USER_OVERVIEW]: {
    [zhCN]: [
    <title key="1">账户总览｜Kikitrade | 加密货币社交投资平台</title>,
    <meta property="og:title" key="title" name="description" content="总览账户所有用戶有关资料，如果你发现有任何不相符或者错误咨询，请立即于我們联络确认。"/>
    ],
    [zhTC]: [
    <title key="1">賬戶總覽｜Kikitrade | 加密貨幣社交投資平台</title>,
    <meta property="og:title" key="title" name="description" content="總覽賬戶所有用戶有關資料，如果你發現有任何不相符或者錯誤資訊，請立即與我們聯絡確認。"/>
    ],
    [en]: [
    <title key="1">{'Acount Overview | Kikitrade | Crypto SocialFi'}</title>,
    <meta property="og:title" key="title" name="description" content="Account overview includes any and all thing related to your Kikitrade account, if you ever find anything unmatched to the information you provided, please let us know so we can work it out together."/>
    ]
  },
  [USER_PATHS.SAFETY]: {
    [zhCN]: [
    <title key="1">安全相关｜Kikitrade | 加密货币社交投资平台</title>,
    <meta property="og:title" key="title" name="description" content="资产安全对投资信心和力度有直接关系，请于投入资金前阅览我们的条款及细则，当你有任何与安全相关的查询，请立即与我们联络确认。"/>
    ],
    [zhTC]: [
    <title key="1">安全相關｜Kikitrade | 加密貨幣社交投資平台</title>,
    <meta property="og:title" key="title" name="description" content="資產安全對投資信心和力度有直接關係，請於投入資金前閱覽我們的條款及細則，當你有任何與安全相關的查詢，請立即與我們聯絡確認。"/>
    ],
    [en]: [
    <title key="1">{'About Security | Kikitrade | Crypto SocialFi'}</title>,
    <meta property="og:title" key="title" name="description" content="Security is of the utmost importance in trading and managing your finances. You are advised to read through our terms and if you encountered any concerns regarding security issues, please alert us at once."/>
    ]
  },
  [USER_PATHS.OTHER_SETTING]: {
    [zhCN]: [
    <title key="1">其他设置｜Kikitrade | 加密货币社交投资平台</title>,
    <meta property="og:title" key="title" name="description" content="除了个人概况，所有设定选项均可以在【其他设置】进行改动。如果你有其他希望推出的功能，也可以跟我们联络提出提议。"/>
    ],
    [zhTC]: [
    <title key="1">其他設置｜Kikitrade | 加密貨幣社交投資平台</title>,
    <meta property="og:title" key="title" name="description" content="除了個人概況，所有設定選項均可以在【其他設置】進行改動。如果你有其他希望推出的功能，也可以跟我們聯絡提出提議。"/>
    ],
    [en]: [
    <title key="1">{'Other Settings | Kikitrade | Crypto SocialFi'}</title>,
    <meta property="og:title" key="title" name="description" content="Other settings besides your profile and transactions can be found here. We also understand that you might wish for certain functions made available to improve your experience with Kikitrade, please let us know."/>
    ]
  },
  [USER_PATHS.TRADE]: {
    [zhCN]: [
    <title key="1">我的交易｜Kikitrade | 加密货币社交投资平台</title>,
    <meta property="og:title" key="title" name="description" content="查阅用户交易，清楚知道自身和市场情况，以便在最好时机作出最好选择。"/>
    ],
    [zhTC]: [
    <title key="1">我的下单｜Kikitrade | 加密貨幣社交投資平台</title>,
    <meta property="og:title" key="title" name="description" content="查閱用戶交易，清楚知道自身和市場情況，以便在最好時機作出最好選擇。"/>
    ],
    [en]: [
    <title key="1">{'My Trades | Kikitrade | Crypto SocialFi'}</title>,
    <meta property="og:title" key="title" name="description" content="Look for your trades here to have a better understanding of where you stand in the current crypto market. After all, you make the best decisions with the most information you acquired."/>
    ]
  },
  [INVITATION]: {
    [zhCN]: [
    <title key="1">邀请好友｜Kikitrade | 加密貨幣社交型態交易平台</title>,
    <meta property="og:title" key="title" name="description" content="Kikitrade 推荐好友计划，立即分享你的专属邀请码，和亲朋好友一起共享丰富回赠奖励。每个成功推荐均可获相应回赠，不限人数，奖励无上限！好友越多，奖赏越高！"/>
    ],
    [zhTC]: [
    <title key="1">邀請好友｜Kikitrade | 加密貨幣社交型態交易平台</title>,
    <meta property="og:title" key="title" name="description" content="Kikitrade 推薦好友計劃，立即分享你的專屬邀請碼，和親朋好友一起共享豐富回贈獎勵。每個成功推薦均可獲相應回贈，不限人數，獎勵無上限！好友越多，獎賞越高！"/>
    ],
    [en]: [
    <title key="1">{'Referral | Kikitrade | Crypto SocialFi'}</title>,
    <meta property="og:title" key="title" name="description" content="The more the merrier, in this case, the more referrals you send our way using your referral code, the more rebate you share with said friends."/>
    ]
  },
  [NEWS_PATHS.INDEX]: {
    [zhCN]: [
    <title key="1">快讯｜Kikitrade 资讯 | 加密货币社交型态交易平台</title>,
    <meta property="og:title" key="title" name="description" content="Kikitrade 加密货币最新快讯，涵盖最全面的国际及本地区块链及币圈消息及资讯，由 NFT、比特币及以太币走势到 GameFi 等热门话题，助你第一时间紧贴最新、最快的币圈大小事。立即进入 Kikitrade 网页版，在线追踪即时新闻资讯。"/>
    ],
    [zhTC]: [
    <title key="1">快訊｜Kikitrade 資訊 | 加密貨幣社交型態交易平台</title>,
    <meta property="og:title" key="title" name="description" content="Kikitrade 加密貨幣最新快訊，涵蓋最全面的國際及本地區塊鏈及幣圈消息及資訊，由 NFT、比特幣及以太幣走勢到 GameFi 等熱門話題，助你第一時間緊貼最新、最快的幣圈大小事。立即進入 Kikitrade 網頁版，在線追蹤即時新聞資訊。"/>
    ],
    [en]: [
    <title key="1">{'Notice | Kikitrade | Crypto SocialFi'}</title>,
    <meta property="og:title" key="title" name="description" content="Kikitrade notices your needs in knowing the hottest crypto-related news on NFT, Bitcoin, Ethereum, GameFi, and more, comprehensive yet crucial information locally and globally, at once and all-in-one. Visit Kikitrade website, where you have everything in the palm of your hand."/>
    ]
  },
  [NEWS_PATHS.HIGHLIGHTS]: {
    [zhCN]: [
    <title key="1">要闻｜Kikitrade 资讯 | 加密货币社交型态交易平台</title>,
    <meta property="og:title" key="title" name="description" content="Kikitrade 推荐好友计划，立即分享你的专属邀请码，和亲朋好友一起共享丰富回赠奖励。每个成功推荐均可获相应回赠，不限人数，奖励无上限！好友越多，奖赏越高！"/>
    ],
    [zhTC]: [
    <title key="1">要聞｜Kikitrade 資訊 | 加密貨幣社交型態交易平台</title>,
    <meta property="og:title" key="title" name="description" content="Kikitrade 推薦好友計劃，立即分享你的專屬邀請碼，和親朋好友一起共享豐富回贈獎勵。每個成功推薦均可獲相應回贈，不限人數，獎勵無上限！好友越多，獎賞越高！"/>
    ],
    [en]: [
    <title key="1">{'Highlights | Kikitrade | Crypto SocialFi'}</title>,
    <meta property="og:title" key="title" name="description" content="Kikitrade highlights the most importatnt crypto-related news on NFT, Bitcoin, Ethereum, GameFi, and more, so you get comprehensive yet crucial information locally and globally, at once and all-in-one. Visit Kikitrade website, where you have everything in the palm of your hand."/>
    ]
  }
}

export const getPageHeader = ({ pathname, locale }) => {
  return HEADER[pathname]?.[locale] || HEADER[HOME_PATH][locale]
}
