import {
  DeviceEventEmitter
} from 'react-native';
import { getRemoteConfig, fetchAndActivate, getValue } from 'firebase/remote-config';

let remoteConfig
// Initialize Firebase
if (typeof window !== 'undefined') {
  remoteConfig = getRemoteConfig();
  remoteConfig.settings.minimumFetchIntervalMillis = 36;
  window.remoteConfig = remoteConfig
  fetchAndActivate(remoteConfig)
    .then((data) => {
      window.firebaseWebReady = true
      DeviceEventEmitter.emit('firebaseWebReady')
    })
    .catch((err) => {
      // ...
    });
}

export async function getRemoteConfigValue (remoteConfigKey) {
  return new Promise((resolve, reject) => {
    if (window && window.firebaseWebReady) {
      if (typeof remoteConfigKey === 'string') {
        const val = getValue(remoteConfig, remoteConfigKey);
        resolve(val)
      } else {
        const arr = []
        remoteConfigKey.forEach((key) => {
          arr.push(getValue(remoteConfig, key))
        })
        resolve(arr)
      }
    } else {
      DeviceEventEmitter.addListener('firebaseWebReady', function () {
        if (typeof remoteConfigKey === 'string') {
          const val = getValue(remoteConfig, remoteConfigKey);
          resolve(val)
        } else {
          const arr = []
          remoteConfigKey.forEach((key) => {
            arr.push(getValue(remoteConfig, key))
          })
          resolve(arr)
        }
      })
    }
  })
}
