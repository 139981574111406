import { message } from 'antd';

import I18n from '@utils/i18n';

type fixAndFlexHKErrorType = {
  msgkey: string,
  type: '1' | '2', // 1定期活期 2定投
  callBack: ()=> void
}

const fixorFlexErrorKey = ['financing.kyc.unverified', 'financing.subscribe.region.not.support']
const autoErrorKey = 'investment.auto.financing.region.not.support'

export const finanicalHKError = ({ msgkey, type, callBack }: fixAndFlexHKErrorType) => {
  if ((type === '1' && fixorFlexErrorKey.some(i => i === msgkey)) ||
    (type === '2' && autoErrorKey === msgkey)
  ) {
    message.warning(I18n.t(msgkey))
    return
  }
  callBack()
}
