import React from 'react';

import cn from 'classnames';

import { Input } from '@element/Input';

import type { SearchProps } from './select.type';

export const Search: React.FC<SearchProps> = ({ search, onSearch, onChange, className, theme }) => {
  if (!search) {
    return null
  }
  const { placeholder, className: searchClass, isClear } = search;
  return (
    <div className={cn('search-container', className, searchClass)}>
      <Input
        prefix={<span className={cn('icon', 'icon-search')} />}
        placeholder={placeholder}
        className={cn('select-search')}
        showClear={isClear}
        theme={theme}
        onChange={e => {
          onChange?.(e.target.value)
        }}
        onPressEnter={e => {
          onSearch?.(e.target.value)
        }}
      />
    </div>
  )
}
