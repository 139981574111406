export type ChainIDType = {[key: string]: string}
export const chainids: ChainIDType = {
  "1": "evm",
  "8": "ubiq",
  "10": "optimism",
  "19": "songbird",
  "20": "elastos",
  "24": "kardiachain",
  "25": "cronos",
  "30": "rsk",
  "40": "telos",
  "50": "xdc",
  "52": "csc",
  "55": "zyx",
  "56": "bsc",
  "57": "syscoin",
  "60": "gochain",
  "61": "ethereumclassic",
  "66": "okexchain",
  "70": "hoo",
  "82": "meter",
  "87": "nova network",
  "88": "tomochain",
  "100": "xdai",
  "106": "velas",
  "108": "thundercore",
  "122": "fuse",
  "128": "heco",
  "137": "polygon",
  "200": "xdaiarb",
  "246": "energyweb",
  "250": "fantom",
  "269": "hpb",
  "288": "boba",
  "321": "kucoin",
  "324": "zksync_era",
  "336": "shiden",
  "361": "theta",
  "416": "sx",
  "534": "candle",
  "592": "astar",
  "820": "callisto",
  "888": "wanchain",
  "1088": "metis",
  "1231": "ultron",
  "1234": "step",
  "1284": "moonbeam",
  "1285": "moonriver",
  "2000": "dogechain",
  "2222": "kava",
  "4689": "iotex",
  "5000": "mantle",
  "5050": "xlc",
  "5551": "nahmii",
  "6969": "tombchain",
  "7700": "canto",
  "8217": "klaytn",
  "9001": "evmos",
  "10000": "smartbch",
  "32520": "bitgert",
  "32659": "fusion",
  "39815": "oho",
  "42161": "arbitrum",
  "42170": "arb-nova",
  "42220": "celo",
  "42262": "oasis",
  "43114": "avalanche",
  "47805": "rei",
  "55555": "reichain",
  "59144": "linea",
  "71402": "godwoken",
  "333999": "polis",
  "420420": "kekchain",
  "888888": "vision",
  "1313161554": "aurora",
  "1666600000": "harmony",
  "11297108109": "palm",
  "836542336838601": "curio"
}