import React, { useState } from 'react'
import cn from 'classnames';
import { NavChild } from '../NavChild'
import { RelaxUs } from '../RelaxUs'
import { Layout } from 'antd';
import I18n from '@utils/i18n';
import styles from './index.module.css'
import themeStyle from '../../theme.module.css';
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect'

const { Footer } = Layout
export const FooterPC = ({ newFooterData = [], theme }) => {
  const [footerData, setFooterData] = useState({})
  useIsomorphicLayoutEffect(() => {
    if (newFooterData.length === 0) return;
    const col1 = [];
    const col2 = [];
    const col3 = [];
    const col4 = [];

    newFooterData.forEach((item, index) => {
      switch (item.order % 4) {
        case 1:
          col1.push(item)
          break;
        case 2:
          col2.push(item)
          break;
        case 3:
          col3.push(item)
          break;
        case 0:
          col4.push(item)
          break;
      }
    })
    setFooterData({
      col1,
      col2,
      col3,
      col4
    })
  }, [newFooterData])

  const renderCol = () => {
    const resDom = Object.keys(footerData).map(col => {
      return (
        <div className={styles.col} key={col}>
          {
            footerData[col].map(item => (
              <div className={styles.navCont} key={item.id}>
                <div className={cn(styles.navHeader, 'Bold', themeStyle[`${theme}-navHeader`])} data-name='kiki-web-footer-linkTitle'>{item.name}</div>
                <NavChild clildData={item.children} key={item.id}/>
              </div>
            ))
          }
        </div>
      )
    })
    return resDom
  }

  return <div className={cn(styles.navLink, styles.webNavLink, themeStyle[`${theme}-navLink`])} data-name='kiki-web-footer'>
            <div className={styles.navLinkMenu}>
                <div className={styles.navLinkMenuLeft}>
                  {renderCol()}
                </div>
                <div className={styles.navLinkMenuRight}>
                    <RelaxUs theme={theme} />
                </div>
            </div>
            <div className={cn(styles.footerData, themeStyle[`${theme}-footerData`])}>
                <Footer className='DINPro'>{I18n.t('layout_footer')}</Footer>
            </div>
        </div>
}
