import React, { useState } from 'react';

import cn from 'classnames';

import { CountDown } from '@components/CountDown';
import { THEME } from '@constants/index';
import I18n from '@utils/i18n';

import { Input } from './index';
// export interface IInputPasswordProps extends IInputProps {
// }

export default function ResendInput (props) {
  const {
    placeholder,
    setCountDown,
    countdown,
    sendTitle = I18n.t('register_send'),
    onSend,
    disabled,
    theme
  } = props;
  const [suffixText, setSuffixText] = useState(sendTitle);

  const onSendVerifyCode = () => {
    if (disabled) {
      return
    }
    onSend && onSend();
  }
  const onFinish = () => {
    setCountDown(false);
    setSuffixText(I18n.t('register_resend'));
  }

  const DefaultSuffix = (props) => {
    return (
      countdown
        ? <CountDown
          onFinish={onFinish}
          countdown={countdown}
          theme={theme}
        />
        : <span
          className={cn('suffix', {
            dark_suffix: theme === THEME.DARK,
            disabled_suffix: disabled
          })}
          onClick={onSendVerifyCode}
        >{suffixText}
        </span>
    )
  };
  return (
    <Input
      maxLength={6}
      {...props}
      theme={theme}
      className='kiki-input'
      placeholder={placeholder || I18n.t('pleaseInput')}
      suffix={DefaultSuffix(props)}
    />
  )
}
