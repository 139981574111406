import Input, { InputTextArea } from './Input';
import InputPassword from './InputPassword';
import InputResend from './InputResend';
import InputSearch from './InputSearch';

export {
  Input,
  InputPassword,
  InputResend,
  InputSearch,
  InputTextArea
}
