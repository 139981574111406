import React from 'react';

import { Input as AntdInput } from 'antd';
import cn from 'classnames';
import Image from 'next/legacy/image';

import { THEME } from '@constants/base';

// import { InputProps } from 'antd/lib/input';
import I18n from '../../../utils/i18n';
import { renderClear } from './Input';

// export interface IInputPasswordProps extends InputProps {
//   showClear?: boolean;
//   forgetPwd?: Boolean;
// }

function defaultPwdIconRender (visible) {
  if (visible) {
    return (
      <span>
        <Image
          src={require('../../../images/icon/eyeTwoTone.webp')}
          width={24}
          height={24}
          layout='fixed'
          alt={'kikitrade'}
        />
      </span>
    )
  } else {
    return (
      <span>
        <Image
          src={require('../../../images/icon/eyeInvisible.webp')}
          width={24}
          height={24}
          layout='fixed'
          alt={'kikitrade'}
        />
      </span>
    )
  }
}

export default function InputPassword (props) {
  const { placeholder, showClear = false, forgetPwd = false, iconRender, loginType = 'mobile', className, theme } = props;
  if (forgetPwd) {
    return (<div className='forget-pwd-input'>
      <span className='forget-text'><a href={`/${I18n.locale}/forgetPass?loginType=${loginType}`}>{I18n.t('forgetPass_step_one_forget')}</a></span>
      <AntdInput.Password
        {...props}
        className={cn('kiki-input', className, {
          'dark_kiki-input': theme === THEME.DARK
        })}
        placeholder={placeholder || I18n.t('pleaseInput')}
        iconRender={iconRender || defaultPwdIconRender}
        allowClear={renderClear(showClear)}
      />
    </div>)
  } else {
    return (<AntdInput.Password
      {...props}
      className={cn('kiki-input', className, {
        'dark_kiki-input': theme === THEME.DARK
      })}
      placeholder={placeholder || I18n.t('pleaseInput')}
      iconRender={iconRender || defaultPwdIconRender}
      allowClear={renderClear(showClear)}
    />)
  }
}
