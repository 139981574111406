import { connect } from 'getstream';
import config from '../utils/config';
import jwtToken, { readonlyToken } from '../utils/jwtToken';
import bugsnag from '../utils/bugsnag'

const defaultOptions = {
  urlOverride: {
    api: config.api.host
    // api: 'http://localhost:3000/dev_api/'
  },
  timeout: 10000,
  browser: true,
  version: 'api/social'
};

const getClient = (token) => {
  try {
    const client = connect(
      'key',
      token || jwtToken.getToken() || readonlyToken,
      'app_id',
      defaultOptions
    );

    if (typeof window !== 'undefined') {
      try {
        window.userId = JSON.parse(localStorage.getItem('userStore')).userInfo.id || ''
        window.deviceId = localStorage.getItem('deviceId')
      } catch (e) {
      }
    }

    return client
  } catch (e) {
    bugsnag.notify(new Error('[SocialAPI]--[getClient]--error  : ' + e.toString()));
  }
};

const getUserInfo = (userId, token) => {
  const client = getClient(token);
  const user = userId || '';

  return new Promise((resolve, reject) => {
    client
      .user(user)
      .get({})
      .then((body) => {
        const result = body.full && body.full.result;
        resolve(result);
      })
      .catch((err) => {
        bugsnag.notify(new Error('[SocialAPI]--[getClientUser]--error  : ' + err.toString()));
        reject(err);
      });
  });
};

export { getClient, getUserInfo }
