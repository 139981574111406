import { action, observable, makeObservable, runInAction } from 'mobx';
import { enableStaticRendering } from 'mobx-react';
import { makePersistable } from 'mobx-persist-store';
import { PRECISION, ZERO, PRECISION8 } from '@constants/index';
import I18n from '@utils/i18n';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import concat from 'lodash/concat';
import pick from 'lodash/pick';

import helper from '@utils/helper';
import { message } from 'antd';
import {
  financingAccount,
  getAccountInfo,
  getMerchantInfo,
  accountAssets,
  assetOrderList,
  withdrawAllConfig,
  payCurrenciesInfo,
  getMarginAssets,
  favoriteList,
  getRepayPreview,
  repay,
  getRepayStatus,
  favoriteSort,
  favoriteAdd,
  getTodaysPNL,
  fetchKycResult,
  kycPayQuota
} from '@api/index';

// eslint-disable-next-line react-hooks/rules-of-hooks
enableStaticRendering(typeof window === 'undefined');

let store;

class Store {
  constructor (rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      fetchTodaysPNL: action,
      fetchFinancingAccount: action,
      fetchMerchantInfo: action,
      fetchKycResult: action,
      fetchKycPayQuota: action,
      fetchAccountInfo: action,
      fetchC2cAccountInfo: action,
      fetchMain: action,
      getFinancingPositions: action,
      getAllAccountValue: action,
      updatePropertyDate: action,
      fetchAccountAssets: action,
      handleAccountAssets: action,
      getselectAsset: action,
      getRepayPreview: action,
      repay: action,
      getRepayStatus: action,
      updateUhowSelectAssetModal: action,
      getTableDataByDataSource: action,
      fetchAssetsMain: action,
      fetchAssetsMain2: action,
      fetchDepositHistory: action,
      fetchPayCurrenciesInfo: action,
      fetchAssetCoinList: action,
      fetchDepositMain: action,
      handleCryptoItemList: action,
      fetchMarginData: action,
      fetchFavoriteList: action,
      fetchFavoriteSort: action,
      fetchFavoriteAdd: action,
      firstShowCustomChoicesChange: action,
      setTradeHisTab: action,
      setFinancingHisTab: action,
      todayPnlData: observable,
      tradingInfo: observable,
      financingInfo: observable,
      kycInfo: observable,
      kycPayQuotaInfo: observable,
      accountInfo: observable,
      c2cAccountInfo: observable,
      assetAccountMap: observable,
      allAccountValue: observable,
      riskRate: observable,
      netAssetValue: observable,
      todayPnl: observable,
      todayPnlPercent: observable,
      initialMargin: observable,
      maintenanceMargin: observable,
      assetsList: observable,
      marginAssets: observable,
      favoriteObject: observable,
      firstShowCustomChoices: observable,
      selectAsset: observable,
      repayPreviewAsset: observable,
      stayBackAsset: observable,
      showSelectAssetModal: observable,
      reMsg: observable,
      earningsObj: observable
    });

    if (typeof window !== 'undefined') {
      makePersistable(this, {
        name: 'tradeStore',
        properties: [
          'tradingInfo',
          'accountInfo',
          'c2cAccountInfo',
          'financingInfo',
          'merchantInfo',
          'kycInfo',
          'financingHisTab',
          'tradeHisTab'
        ],
        storage: window.localStorage
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  todayPnlData = {};
  tradingInfo = {};
  financingInfo = {};
  merchantInfo = {};
  kycInfo = {};
  kycPayQuotaInfo = {};
  accountInfo = {};
  c2cAccountInfo = {};
  assetCoinList = null;
  dataSource = [];
  accountMap = {}; // 0-币币账户 2-法币账户 3-理财账户 4-余额宝账户 6-CFD逐仓合约账户 9 跟单账户 13 全仓合约账户
  assetAccountMap = {}; // 0-币币账户 2-法币账户 3-理财账户 4-余额宝账户 6-CFD逐仓合约账户 9 跟单账户 13 全仓合约账户
  allAccountValue = ZERO;
  riskRate = ZERO;
  netAssetValue = ZERO;
  todayPnl = ZERO;
  todayPnlPercent = ZERO;
  initialMargin = ZERO;
  maintenanceMargin = ZERO;
  assetsList = [];
  marginAssets = [];
  favoriteObject = {};
  firstShowCustomChoices = true;
  selectAsset = '';
  repayPreviewAsset = [];
  stayBackAsset = {};
  showSelectAssetModal = false;
  reMsg = {};
  //  assetsList = [];
  //  firstShowCustomChoices = true
  //  assetsList = [];
  //  marginAssets = [];
  earningsObj = {};
  financingHisTab = {};
  tradeHisTab = {};

  webToken = helper.createUUID();

  fetchTodaysPNL = async (isTip = true) => {
    console.log('fetch........');
    const res = await getTodaysPNL();
    if (res.success !== true && isTip) {
      message.error(I18n.t(res.msgKey));
      return;
    }
    const newResObj = res.obj
    newResObj.currency = res.obj.currency
    runInAction(() => {
      this.todayPnlData = newResObj;
    });
    return Promise.resolve(newResObj);
  };

  fetchFinancingAccount = async (isTip = true) => {
    const res = await financingAccount();
    if (res.success !== true && isTip) {
      message.error(I18n.t(res.msgKey));
      return;
    }
    runInAction(() => {
      const newObj = {};
      Object.keys(res.obj).forEach((a) => {
        const USD = res.obj[a].USD
        delete res.obj[a].USD
        newObj[a] = Object.assign(res.obj[a], USD ? { USDC: USD } : null)
      });
      this.financingInfo = newObj;
    });
    return Promise.resolve();
  };

  fetchMerchantInfo = async (isTip = true) => {
    const res = await getMerchantInfo();
    if (res.success !== true && isTip) {
      message.error(I18n.t(res.msgKey));
      return;
    }
    runInAction(() => {
      this.merchantInfo = res.obj;
    });
    return Promise.resolve();
  };

  fetchKycResult = async (isTip = true) => {
    const res = await fetchKycResult();
    if (res.success !== true && isTip) {
      message.error(I18n.t(res.msgKey));
      return;
    }
    runInAction(() => {
      this.kycInfo = res.obj;
    });
    return Promise.resolve();
  };

  fetchKycPayQuota = async (isTip = true) => {
    const res = await kycPayQuota();
    if (res.success !== true && isTip) {
      message.error(I18n.t(res.msgKey));
      return;
    }
    runInAction(() => {
      this.kycPayQuotaInfo = res.obj;
    });
    return Promise.resolve();
  };

  fetchAccountInfo = async (isTip = true) => {
    const res = await getAccountInfo({ accountCategory: '0' });
    if (res.success !== true && isTip) {
      message.error(I18n.t(res.msgKey));
      return;
    }
    runInAction(() => {
      this.accountInfo = res.obj;
    });
    return Promise.resolve();
  };

  fetchC2cAccountInfo = async (isTip = true) => {
    const res = await getAccountInfo({ accountCategory: '2' });
    if (res.success !== true && isTip) {
      message.error(I18n.t(res.msgKey));
      return;
    }
    runInAction(() => {
      this.c2cAccountInfo = res.obj;
    });
    return Promise.resolve();
  };

  // 并发 理财账户 杠杆信息
  fetchMain = async (params) => {
    console.log('ooooooooooooo');
    await Promise.allSettled([
      // 理财账户今日收益
      this.fetchKycResult(false),
      this.fetchAccountInfo(false),
      this.fetchAccountAssets('0', false)
    ]);
    return Promise.resolve();
  };

  // coinDetail 为 getAllAccountValue 方法计算的 coinDetail
  // marketSymbols 为 所有盘口信息
  getFinancingPositions = ({ coinDetail, marketSymbols }) => {
    const priceLast = coinDetail?.priceLast;
    let _totalRevenues = ZERO;
    let _yesterdayRevenues = ZERO;
    let _financingPositions = ZERO;
    let _fixedDepositPositions = ZERO;
    let _investmentAmounts = ZERO;
    // 活期
    const financingPositions = this.financingInfo?.financingPositions || {};
    // 定期
    const fixedDepositPositions =
      this.financingInfo?.fixedDepositPositions || {};
    // 定投
    const investmentAmounts = this.financingInfo?.investmentAmounts || {};
    const totalRevenues = this.financingInfo?.totalRevenues || {};
    const yesterdayRevenues = this.financingInfo?.yesterdayRevenues || {};

    Object.keys(financingPositions).forEach((item) => {
      const fiatItem = marketSymbols?.find((data) => {
        return data.coinCode === `${item}_USDT`;
      });
      if (fiatItem?.priceLast) {
        _financingPositions +=
          parseFloat(fiatItem?.priceLast) *
          parseFloat(financingPositions[item]) *
          parseFloat(priceLast);
      } else if (item === 'USDT') {
        _financingPositions +=
          parseFloat(financingPositions[item]) * parseFloat(priceLast);
      }
    });
    Object.keys(fixedDepositPositions).forEach((item) => {
      const fiatItem = marketSymbols.find((data) => {
        return data.coinCode === item + '_USDT';
      });
      if (fiatItem && fiatItem.priceLast) {
        _fixedDepositPositions +=
          parseFloat(fiatItem.priceLast) *
          parseFloat(fixedDepositPositions[item]) *
          parseFloat(priceLast);
      } else if (item === 'USDT') {
        _fixedDepositPositions +=
          parseFloat(fixedDepositPositions[item]) * parseFloat(priceLast);
      }
    });

    Object.keys(investmentAmounts).forEach((item) => {
      const fiatItem = marketSymbols.find((data) => {
        return data.coinCode === item + '_USDT';
      });
      if (fiatItem && fiatItem.priceLast) {
        _investmentAmounts +=
          parseFloat(fiatItem.priceLast) *
          parseFloat(investmentAmounts[item]) *
          parseFloat(priceLast);
      } else if (item === 'USDT') {
        _investmentAmounts +=
          parseFloat(investmentAmounts[item]) * parseFloat(priceLast);
      } else if (item === 'HKD') {
        const fiatItem = marketSymbols.find((data) => {
          return data.coinCode === 'USDT_HKD';
        });
        if (fiatItem && fiatItem.priceLast) {
          _investmentAmounts +=
            (1 / parseFloat(fiatItem.priceLast)) *
            parseFloat(investmentAmounts[item]) *
            parseFloat(priceLast);
        }
      } else if (item === 'USDC') {
        _investmentAmounts += parseFloat(investmentAmounts[item]);
      }
    });

    Object.keys(yesterdayRevenues).forEach((item) => {
      const fiatItem = marketSymbols.find((data) => {
        return data.coinCode === item + '_USDT';
      });
      if (fiatItem && fiatItem.priceLast) {
        _yesterdayRevenues +=
          parseFloat(fiatItem.priceLast) *
          parseFloat(yesterdayRevenues[item]) *
          parseFloat(priceLast);
      } else if (item === 'USDT') {
        _yesterdayRevenues +=
          parseFloat(yesterdayRevenues[item]) * parseFloat(priceLast);
      } else if (item === 'HKD') {
        const fiatItem = marketSymbols.find((data) => {
          return data.coinCode === 'USDT_HKD';
        });
        if (fiatItem && fiatItem.priceLast) {
          _yesterdayRevenues +=
            (1 / parseFloat(fiatItem.priceLast)) *
            parseFloat(yesterdayRevenues[item]) *
            parseFloat(priceLast);
        }
      } else if (item === 'USDC') {
        _yesterdayRevenues += parseFloat(yesterdayRevenues[item]);
      }
    });
    Object.keys(totalRevenues).forEach((item) => {
      const fiatItem = marketSymbols.find((data) => {
        return data.coinCode === item + '_USDT';
      });
      if (fiatItem && fiatItem.priceLast) {
        _totalRevenues +=
          parseFloat(fiatItem.priceLast) *
          parseFloat(totalRevenues[item]) *
          parseFloat(priceLast);
      } else if (item === 'USDT') {
        _totalRevenues +=
          parseFloat(totalRevenues[item]) * parseFloat(priceLast);
      } else if (item === 'HKD') {
        const fiatItem = marketSymbols.find((data) => {
          return data.coinCode === 'USDT_HKD';
        });
        if (fiatItem && fiatItem.priceLast) {
          _totalRevenues +=
            (1 / parseFloat(fiatItem.priceLast)) *
            parseFloat(totalRevenues[item]) *
            parseFloat(priceLast);
        }
      } else if (item === 'USDC') {
        _totalRevenues += parseFloat(totalRevenues[item]);
      }
    });
    return {
      _financingPositions,
      _fixedDepositPositions,
      _investmentAmounts,
      _totalRevenues,
      _yesterdayRevenues
    };
  };

  // rate 等于 USDC 或 HKD
  // marketSymbols 为所有盘口信息

  getAllAccountValue = ({ rate = 'USDC', marketSymbols, coinDetail }) => {
    const { marginOption = false, nav = 0, im = 0, mm = 0 } = this?.tradingInfo || {}; // 杠杆是否开启

    const totalUSDT = helper.formatFixNumString(
      this.accountInfo?.sumMoney ?? ZERO,
      PRECISION
    );
    const c2cTotalUSDT = helper.formatFixNumString(
      this.c2cAccountInfo?.sumMoney ?? ZERO,
      PRECISION
    );

    const earningsObj = this.getFinancingPositions({
      coinDetail,
      marketSymbols
    });
    this.earningsObj = earningsObj;
    const allAccountValue =
      Number(marginOption ? nav : totalUSDT) * Number(coinDetail?.priceLast) +
      Number(c2cTotalUSDT) * Number(coinDetail?.priceLast) +
      Number(earningsObj._financingPositions) +
      Number(earningsObj._fixedDepositPositions);

    const pnl =
      Number(marginOption ? nav : totalUSDT) - this.todayPnlData.netTransferIn - this.todayPnlData.baseAsset;
    const todayPnl = helper.formatFixNumString(
      Number(pnl) * Number(coinDetail?.priceLast),
      PRECISION
    );
    const todayPnlPercent = this.todayPnlData.baseAsset
      ? helper.formatFixNumString(
        (pnl / Math.abs(this.todayPnlData.baseAsset)) * 100,
        2
      ) + '%' : '--';

    const netAssetValue = marginOption
      ? helper.formatFixNumString(
        (nav ?? ZERO) * Number(coinDetail?.priceLast),
        PRECISION
      )
      : helper.formatFixNumString(
        parseFloat(totalUSDT) * Number(coinDetail?.priceLast),
        PRECISION
      );

    const initialMargin = helper.formatFixNumString(
      (im ?? ZERO) * Number(coinDetail?.priceLast),
      PRECISION
    );

    const maintenanceMargin = helper.formatFixNumString(
      (mm ?? ZERO) * Number(coinDetail?.priceLast),
      PRECISION
    );

    const riskRate = Number(mm) !== 0 ? Number(nav) / Number(mm) : 0;

    return {
      riskRate,
      allAccountValue: helper.formatFixNumString(allAccountValue, PRECISION),
      netAssetValue,
      initialMargin,
      maintenanceMargin,
      todayPnl,
      todayPnlPercent
    };
  };

  updatePropertyDate = ({ rate = 'USDC', marketSymbols, coinDetail = {} }) => {
    const {
      allAccountValue,
      riskRate,
      netAssetValue,
      initialMargin,
      maintenanceMargin,
      todayPnl,
      todayPnlPercent
    } = this.getAllAccountValue({ rate, marketSymbols, coinDetail });
    this.allAccountValue = allAccountValue;
    this.netAssetValue = netAssetValue;
    this.initialMargin = initialMargin;
    this.maintenanceMargin = maintenanceMargin;
    this.riskRate = riskRate;
    this.todayPnl = todayPnl;
    this.todayPnlPercent = todayPnlPercent;
  };

  /** **************************************** 用户所拥有的各币种相关内容 *******************************************/
  fetchAccountAssets = async (accountCategory, isTip = true) => {
    const res = await accountAssets({ accountCategory });

    if (res.success !== true && isTip) {
      message.error(I18n.t(res.msgKey));
      return;
    }
    const coinAccountList = res.obj.coinAccountList
    const coinAccountsWithAssets = res.obj.coinAccountsWithAssets

    runInAction(() => {
      this.accountMap[accountCategory] = coinAccountList
      this.assetAccountMap[accountCategory] = coinAccountsWithAssets
    });
    return Promise.resolve([
      coinAccountList,
      coinAccountsWithAssets
    ]);
  };

  handleAccountAssets = () => {
    let itemList = helper.mergeAccount(
      this.accountMap[0],
      this.assetAccountMap[0]
    );
    let arr1 = filter(itemList, (item) => {
      return parseFloat(item.usdtMoney) > 0;
    });
    let arr2 = filter(itemList, (item) => {
      return parseFloat(item.usdtMoney) < 0;
    });

    const arr3 = filter(itemList, (item) => {
      return parseFloat(item.usdtMoney) === 0 || !item.usdtMoney;
    });

    arr1 = sortBy(arr1, (item) => {
      return parseFloat(item.usdtMoney);
    }).reverse();

    arr2 = sortBy(arr2, (item) => {
      return parseFloat(item.usdtMoney);
    });
    itemList = concat(arr1, arr2.reverse(), arr3);
    this.itemList = itemList;
    return itemList;
  };

  getselectAsset = () => {
    runInAction(() => {
      this.selectAsset = this.assetsList[0];
      this.selectData = this.assetsList.map((item) => ({
        ...item,
        value: item.coinCode,
        amount: Number(item.hotMoney) + Number(item.coldMoney),
        label: (
          <div
            style={{
              fontFamily: 'DINPro-Medium',
              fontWeight: '500',
              fontSize: '14px'
            }}
          >
            {Number(item.hotMoney) + Number(item.coldMoney)}&nbsp;&nbsp;
            {item.coinCode}
          </div>
        )
      }));
    });
  };

  getRepayPreview = async (params) => {
    const res = await getRepayPreview(params);
    if (res.success !== true) {
      message.error(I18n.t(res.msgKey));
      return;
    }
    runInAction(() => {
      this.repayPreviewAsset = res.obj;
    });
  };

  setStayBackAsset = async (params) => {
    runInAction(() => {
      this.stayBackAsset = params;
    });
  };

  repay = async (params) => {
    const res = await repay(params);
    if (res.success !== true) {
      message.error(I18n.t(res.msgKey));
      return false;
    }
    return res.obj?.id;
    // runInAction(() => {
    //   this.getRepayStatus(res.obj?.id || '')
    // })
  };

  getRepayStatus = async (repaymentId) => {
    // 0异常，1还款成功有余额，2还款成功无余额
    const res = await getRepayStatus({ repayment_id: repaymentId });
    if (res.success !== true) {
      message.error(I18n.t(res.msgKey));
      return 0;
    }
    if (res.obj?.status === 'success') {
      const { stayBackAsset } = this;
      const marginAssets = await this.fetchMarginData();
      const reItem = marginAssets.find(
        (item) => item.asset === stayBackAsset.asset
      );
      // const remoney = Number(amount || '') - Number(res.obj?.assetRepayQty || '')
      console.log(reItem, 'remoney');
      if (reItem) {
        this.getRepayPreview({
          cost_currency: this.selectAsset.coinCode,
          asset: stayBackAsset.asset
        });
        // 重新查余额
        await this.fetchAssetsMain2();
        // 更新下拉框数据
        await this.getselectAsset();
        // 展示相关内容
        this.updateUhowSelectAssetModal(true);
        return 1;
      }
      return 2;
    }
  };

  updateUhowSelectAssetModal = (showSelectAssetModal) => {
    this.showSelectAssetModal = showSelectAssetModal;
  };

  getTableDataByDataSource = ({
    dataSource,
    rateType,
    marketSymbols,
    assetCoinList = {},
    coinDetail = {}
  }) => {
    if (!dataSource?.length || !rateType || !marketSymbols.length) {
      return [];
    }
    const getPrice = (item, rateType, coinDetail) => {
      const prefix = rateType === 'HKD' ? '≈HK$' : '≈$';
      let linker = '';
      if (Number(item?.hotMoney) + Number(item?.coldMoney) < 0) {
        linker = '-';
      }
      if (
        (rateType === 'HKD' && item.coinCode === 'HKD') ||
        (rateType === 'USDC' && item.coinCode === 'USDC')
      ) {
        return `${prefix}${linker}${helper.formatFixNumString(
          Math.abs(Number(item?.hotMoney) + Number(item?.coldMoney)),
          PRECISION
        )}`;
      }
      if (coinDetail && coinDetail.priceLast) {
        return `${prefix}${linker}${helper.formatFixNumString(
          Math.abs(item.usdtMoney) * Number(coinDetail.priceLast),
          PRECISION
        )}`;
      }
      return `${prefix}${linker}--`;
    };

    const canDepositWithdraw = (coinCode) => {
      const item = (assetCoinList && assetCoinList[coinCode]) ?? [];
      let deposit = false;
      let withdraw = false;
      if (coinCode && coinCode !== 'HKD') {
        const assetsListDeposit = item?.assets?.filter(item => item.enableDeposit === true) ?? [];
        const assetsListWithdraw = item?.assets?.filter(item => item.enableWithdraw === true) ?? [];
        if (assetsListDeposit?.length > 0 && !(assetsListDeposit?.length === 1 && assetsListDeposit[0].chainType === 'FIAT')) {
          deposit = true;
        }
        if (assetsListWithdraw?.length > 0 && !(assetsListWithdraw?.length === 1 && assetsListWithdraw[0].chainType === 'FIAT')) {
          withdraw = true;
        }
      }
      return {
        deposit,
        withdraw
      };
    };

    const res = dataSource?.map((item) => {
      const enable = canDepositWithdraw(item?.coinCode);
      const enableDeposit = enable.deposit || false;
      const enableWithdraw = enable.withdraw || false;
      return {
        ...pick(item, ['picturePath', 'coinCode', 'coldMoney', 'hotMoney']),
        totalAssets: helper.formatFixNumString(
          Number(item?.hotMoney) + Number(item?.coldMoney),
          PRECISION8
        ),
        price: getPrice(item, rateType, coinDetail),
        enableDeposit: enableDeposit,
        enableWithdraw: enableWithdraw
      };
    });
    return res;
  };

  fetchAssetsMain = async () => {
    await Promise.allSettled([
      this.fetchAccountAssets('0', false),
      this.fetchAccountAssets('2', false)
    ]);
    this.dataSource = await this.handleAccountAssets();
  };

  /** **************************************** 市场的资产余额 *******************************************/
  fetchAssetsMain2 = async () => {
    await Promise.allSettled([this.fetchAccountAssets('0', false)]);
    const itemList = await this.handleAccountAssets();
    this.assetsList = itemList.filter(
      (item) => Number(item.hotMoney) + Number(item.coldMoney) > 0
    );
  };

  /** **************************************** 充提币 *******************************************/
  // 获取充提币历史
  fetchDepositHistory = async (params, isFetchAssetCoinList) => {
    // params: { offset, limit, currency, status, type }
    if (isFetchAssetCoinList) {
      await this.fetchAssetCoinList();
    }
    const res = await assetOrderList(params);
    if (res.success !== true) {
      message.error(I18n.t(res.msgKey));
      return;
    }
    const rows = res.obj?.rows || [];
    rows.map((item, index) => {
      const assetList = this.assetCoinList && this.assetCoinList[item.currency];
      let assetData = assetList?.assets ?? [];
      assetData = filter(assetData, function (listItem) {
        return listItem.asset === item.asset;
      });
      item.url = assetData[0]?.url || '';
      return item;
    });
    return Promise.resolve(res.obj);
  };

  // 单个币种出入金
  fetchPayCurrenciesInfo = async (coinCode, isTip = true) => {
    const res = await payCurrenciesInfo({ coinCode });
    if (res.success !== true && isTip) {
      message.error(I18n.t(res.msgKey));
    }
  };

  fetchAssetCoinList = async (isTip = true) => {
    try {
      const res = await withdrawAllConfig();
      if (res.success !== true && isTip) {
        message.error(I18n.t(res.msgKey));
        return;
      }
      let obj = res.obj;
      if (Array.isArray(res.obj)) {
        obj = res.obj.reduce((prev, curr) => {
          prev[curr.currency] = curr;
          return prev;
        }, {});
      }
      runInAction(() => {
        this.assetCoinList = obj;
      });
      return Promise.resolve();
    } catch (error) {
      console.log('error___', error);
    }
  };

  // type deposit
  fetchDepositMain = async ({ type, isCache }) => {
    if (!isCache) {
      await Promise.all([
        this.fetchAccountAssets('0', false),
        this.fetchAssetCoinList(false)
      ]);
    }
    if (!this.assetCoinList) {
      await this.fetchAssetCoinList(false);
    }
    if (!this.accountMap?.[0]?.length || !this.assetAccountMap?.[0]?.length) {
      await this.fetchAccountAssets('0', false);
    }
    const itemList = helper.mergeAccount(
      this.accountMap[0],
      this.assetAccountMap[0]
    );
    const fiatItemListArr = filter(itemList, (item) => {
      const product = this.assetCoinList[item.coinCode];
      let enable = false;
      if (type === 'deposit') {
        enable = product?.enableDeposit === true;
      } else {
        enable = product?.enableWithdraw === true;
      }
      return (item.coinCode === 'HKD' || item.coinCode === 'USDC') && enable;
    });
    const cryptoItemListArr = filter(itemList, (item) => {
      const product = this.assetCoinList[item.coinCode];
      let enable = false;
      if (type === 'deposit') {
        enable = product?.enableDeposit === true;
      } else {
        enable = product?.enableWithdraw === true;
      }
      return item.coinCode !== 'HKD' && enable;
    });
    return {
      fiatItemList: fiatItemListArr,
      cryptoItemList: cryptoItemListArr
    };
  };

  // 将主链类型相关内容注入 CryptoItemList
  handleCryptoItemList = (cryptoItemList) => {
    return cryptoItemList.map((item) => {
      const product = this.assetCoinList[item.coinCode];
      return {
        ...item,
        chainTypes: product.assets.filter((v) => !!v.enableDeposit)
      };
    });
  };

  fetchMarginData = async (cryptoItemList) => {
    const response = await getMarginAssets();
    if (response.success) {
      const list = response?.obj || [];
      this.marginAssets = list;
      return list;
    } else {
      message.error(I18n.t(response.msgKey));
    }
  };

  // 显示自选盘口的价格等信息 不需要入参，但是需要用户先登录
  fetchFavoriteList = async ({ isTip = true } = {}) => {
    const res = await favoriteList();
    if (res.success !== true && !isTip) {
      message.error(I18n.t(res.msgKey));
      return;
    }
    runInAction(() => {
      const dataObj = res.obj;
      this.favoriteObject = dataObj;
    });
    return Promise.resolve();
  };

  // 自选排序、删除
  fetchFavoriteSort = async (params = {}) => {
    const res = await favoriteSort(params);
    res.success && message.success(I18n.t('kp3B4Jbi'));
  };

  // 一键添加
  fetchFavoriteAdd = async (params = {}) => {
    const res = await favoriteAdd(params);
    res.success && message.success(I18n.t('2XI3GD6M'));
  };

  firstShowCustomChoicesChange = (value) => {
    runInAction(() => {
      this.firstShowCustomChoices = value;
    });
  };

  setTradeHisTab = (value) => {
    runInAction(() => {
      this.tradeHisTab = value;
    });
  };

  setFinancingHisTab = (value) => {
    runInAction(() => {
      this.financingHisTab = value;
    });
  };

  //   getMarginAssets().then((response) => {
  //     if (response.success) {
  //       this.marginAssets = response.obj
  //     } else {
  //       message.error({ text: I18n.t(response.msgKey), position: 'center' });
  //     }
  //   }).catch((error: Error) => {
  //     message.error({ text: I18n.t('system.error'), position: 'center' });
  //   })
  // }
}

function initializeStore (rootStore, initialData = null) {
  const _store = store ?? new Store(rootStore);

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here, check `pages/ssg.js` and `pages/ssr.js` for more details
  if (initialData) {
    _store.hydrate(initialData);
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
}

export function getTradeStore (rootStore, initialState) {
  const store = initializeStore(rootStore, initialState);
  return store;
}
