import config from '@utils/config';
import request from '@utils/request';

import helper from '../utils/helper';

export const homeKols = (params) => {
  const URL = `${config.api.host}${config.api.kol.kolsByType.replace('{type}', 'home')}`;
  return request.get.call({}, URL);
}

// 获取Popular Projects列表   /api/social/users/kol/popularProject
export const popularProject = (params) => {
  const URL = `${config.api.host}${config.api.kol.kolsByType.replace('{type}', 'popularProject')}?${helper.mapToQueryString(params)}`;
  return request.get.call({}, URL);
}

// 获取Recommend Projects列表  api/social/users/kol/recommendProject
export const recommendProject = (params) => {
  const URL = `${config.api.host}${config.api.kol.kolsByType.replace('{type}', 'recommendProject')}?${helper.mapToQueryString(params)}`;
  return request.get.call({}, URL);
}

// 获取follow Projects列表  api/social/users/kol/followProject
export const followProject = (params) => {
  const URL = `${config.api.host}${config.api.kol.kolsByType.replace('{type}', 'followProject')}?${helper.mapToQueryString(params)}`;
  return request.get.call({}, URL);
}

// 获取projects banner
export const projectBanner = (params) => {
  const URL = `${config.api.host}${config.api.kol.kolBanner.replace('{type}', 'Kol_Project')}?${helper.mapToQueryString(params)}`;
  return request.get.call({}, URL);
}

// 获取项目方详情
export const projectDetail = (params) => {
  const URL = `${config.api.host}${config.api.kol.kolDetail.replace('{id}', params?.id)}?${helper.mapToQueryString(params)}`;
  return request.get.call({}, URL);
}
